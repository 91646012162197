<template>
    <div id="competencies-track-dashboard" v-if="competencies.length" class="grid-view">
      <k-panel-competency-card v-for="comp in competencies" :key="comp.id"
        :competency="comp"
        @save-competency="saveCompetency(comp.id, $event)"
      />
    </div>
    <empty-placeholder v-else
      title="No Competencies"
      info="This programme has no competencies defined."
      fa-icon="fas fa-list-check">
    </empty-placeholder>
</template>

<style>
#competencies-track-dashboard .card-label {
  min-width: 85px;
}

#competencies-track-dashboard .card-progress-container {
  display: flex;
  flex-direction: column-reverse;
  gap: 15px;
  text-align: right;
}

#competencies-track-dashboard .panel-card-body {
  display: flex;
  flex-direction: column-reverse;
  gap: 10px;
}

#k-panel-competency-card .panel-body,
#panel-card .panel-body {
  position: relative;
  width: 100%;
}

#k-panel-competency-card .panel-card-container,
#panel-card .panel-card-container,
#panel-card .panel-card-actions {
  display: flex;
  justify-content: space-between;
}

#k-panel-competency-card .panel-card-container.row,
#panel-card .panel-card-container.row {
  margin-right: unset;
  margin-left: unset;
  flex-wrap: wrap;
  align-items: center;
  flex-direction: row;
}

#k-panel-competency-card .panel-card-container.row .card-header,
#panel-card .panel-card-container.row .card-header {
  flex: 1 1 100%;
}

#k-panel-competency-card .card-header,
#panel-card .card-header {
  display: flex;
  justify-content: space-between;
  gap: 15px;
  flex-wrap: wrap;
  width: 100%;
}

#k-panel-competency-card .module-details,
#panel-card .module-details {
  padding: 0 0 5px;
}

#k-panel-competency-card .card-label,
#panel-card .card-label {
  display: flex;
  align-items: center;
  background-color: rgb(92 255 243 / 0.5);
  justify-content: center;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  min-width: 100px;
  padding: 0 5px;
}

#k-panel-competency-card .card-label span,
#panel-card .card-label span {
  color: var(--kate-mono-0);
  font-size: 2.5em;
  font-weight: bold;
  font-family: "apercu_monoregular", monospace;
}
</style>

<script>
import TimeMixin from '@mixins/time-mixins';
import EmptyPlaceholder from '@base-components/empty-placeholder.vue';
import KPanelCompetencyCard from '../../../programmes/components/k-panel-competency-card.vue';

export default {
  mixins: [TimeMixin],
  components: {
    EmptyPlaceholder,
    KPanelCompetencyCard,
  },
  emits: ['save-competency'],
  props: {
    competencies: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      now: undefined,
    };
  },
  mounted() {
    this.now = new Date();
  },
  methods: {
    saveCompetency(competencyId, score) {
      this.$emit('save-competency', competencyId, score);
    },
  },

};
</script>

<template>
  <div class="k-date-picker">
    <v-date-picker
      v-model="selectedDate"
      :mode="type"
      :masks="masks"
      :min-date="minDate"
      :popover="popover"
      model-config="modelConfig"
      :timezone="timezone"
      :minute-increment="minuteIncrement"
      is24hr
      is-inline>
      <template #default="{ inputValue, inputEvents }">
        <label v-if="label" :for="inputId">{{ label }}<span v-if="required" class="form-required"> *</span></label>
        <div class="form-entry input date-input">
          <i class="fa-duotone fa-calendar input-icon"/>
          <i class="fas fa-times reset-icon" @click="resetDate" title="Reset date"/>
          <input class="form-control"
                :id="inputId"
                :placeholder="inputPlaceholder"
                :value="inputValue"
                v-on="inputEvents"
          >
        </div>
      </template>
    </v-date-picker>
  </div>
</template>

<style>
.form-entry .k-date-picker .date-input i.input-icon {
  left: 8px;
  right: 0;
  top: 50%;
  padding: 0;
  transform: translateY(-50%);
}

.mx-datepicker-popup,
.mx-calendar-time {
  border-radius: 10px;
  font-family: hk-grotesk-light, sans-serif;
  font-weight: bold;
  z-index: 9999;
}

.k-search-dropdown-menu.timezone-dropdown {
  margin: 15px 0;
}
</style>

<style scoped>
.date-input {
  position: relative;
}

.date-input .form-control {
  padding-left: 30px;
  padding-right: 20px;
  text-overflow: ellipsis;
}
</style>

<script>
import TimeMixin from '@mixins/time-mixins';
import 'v-calendar/style.css';

export default {
  mixins: [TimeMixin],
  props: {
    modelValue: {
      type: [Date, String],
    },
    popover: {
      type: Object,
    },
    inputPlaceholder: {
      type: String,
      required: false,
      default: 'Select date...',
    },
    minDate: {
      type: Date,
      required: false,
      default: undefined,
    },
    mask: {
      type: String,
      required: false,
      default: 'DD-MM-YYYY',
    },
    modelConfig: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    type: {
      type: String,
      required: false,
      default: 'date',
    },
    minuteIncrement: {
      type: Number,
      required: false,
      default: 5,
    },
    timezone: {
      type: String,
      default: 'Europe/London',
    },
    inputId: {
      type: String,
      default: 'date-picker-input',
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedDate: this.sanitise(this.modelValue),
    };
  },

  watch: {
    modelValue() {
      this.selectedDate = this.modelValue;
    },
    selectedDate() {
      this.$emit('update:modelValue', this.selectedDate);
    },
  },

  computed: {
    masks() {
      return {
        input: this.mask,
      };
    },
  },

  methods: {
    resetDate() {
      this.selectedDate = undefined;
      this.$emit('reset');
    },
    sanitise(val) {
      if (!val) {
        return undefined;
      }
      if (val instanceof Date) {
        return val;
      }
      return this.getDate(val);
    },
  },
};
</script>

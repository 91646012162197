<template>
  <div>
    <k-panel title="Programme Competencies" :hasContentToggle="false">
      <template #body>
        <div v-if="competenciesReady">
          <div v-if="programmeCompetencies.length">
            <div class="competencies-container">
                <k-editable-table
                    v-model="programmeCompetencies"
                    :headers="headers"
                    :max="20"
                    @clicked="tableCallback"
                >
                </k-editable-table>
            </div>
          </div>
          <empty-placeholder v-else
            title="No Competencies"
            info="This programme has no competencies defined."
            fa-icon="fas fa-list-check">
          </empty-placeholder>
        </div>
      </template>
    </k-panel>
    <update-programme-competency
      v-if="showEditModal"
      :showEditModal="showEditModal"
      :selectedCompetency="selectedCompetency"
      :isBlueprint="isBlueprint"
      @closeEditModal="closeEditModal"
      @update="updateGetCompetencies"
    />
  </div>
</template>

<style scoped>
.competencies-container {
  overflow-x: auto;
}
</style>

<script>
import KPanel from '@base-components/k-panel.vue';
import EmptyPlaceholder from '@base-components/empty-placeholder.vue';
import KEditableTable from '@base-components/k-editable-table.vue';
import ErrorMixin from '@mixins/error-mixins';
import UpdateProgrammeCompetency from './update-programme-competency.vue';

export default {
  mixins: [ErrorMixin],
  components: {
    KPanel,
    EmptyPlaceholder,
    KEditableTable,
    UpdateProgrammeCompetency,
  },
  props: {
    programme: {
      type: Object,
    },
    programmeReady: {
      type: Boolean,
    },
    isBlueprint: {
      type: Boolean,
      default: false,
    },
    competencies: {
      type: Array,
      default: () => [],
    },
    competenciesReady: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      programmeCompetencies: [],
      showEditModal: false,
      selectedCompetency: {
        id: null,
        category: '',
        label: '',
        description: '',
      },
    };
  },
  watch: {
    competencies: {
      handler(newCompetencies) {
        this.programmeCompetencies = newCompetencies.map(competency => ({
          id: competency.id,
          category: competency.category,
          label: competency.label,
          description: competency.description,
        }));
      },
      immediate: true,
    },
  },
  emits: ['update'],
  computed: {
    headers() {
      return [
        {
          key: 'category',
          title: 'Category',
          type: 'string',
          filterable: true,
          sortable: true,
        },
        {
          key: 'label',
          title: 'Label',
          type: 'string',
          sortable: true,
          filterable: true,
        },
        {
          key: 'description',
          title: 'Description',
          type: 'string',
          sortable: true,
        },
        {
          key: 'edit',
          title: 'Edit',
          type: 'action',
          sortable: false,
          callback: this.editCompetency,
          html: '<button class="btn btn-primary" aria-label="Edit" title="Edit"><i class="fas fa-pencil-alt"></i></button>',
        },
        {
          key: 'delete',
          title: 'Delete',
          type: 'action',
          sortable: false,
          callback: this.confirmDeletion,
          html: '<button class="btn btn-danger" aria-label="Delete" title="Delete"><i class="fas fa-trash"></i></button>',
        },
      ];
    },
    apiEndpoint() {
      return `/api/curriculum/${this.isBlueprint ? 'blueprints/' : ''}programmes/${this.entityId}/competencies`;
    },
    entityName() {
      return this.isBlueprint ? 'Programme blueprint' : 'Programme';
    },
    entityId() {
      return Number(this.isBlueprint ? this.$route.params.programmeBlueprintId : this.$route.params.programmeId);
    },
  },
  methods: {
    closeEditModal() {
      this.showEditModal = false;
      this.selectedCompetency = {
        id: null,
        category: '',
        label: '',
        description: '',
      };
    },
    editCompetency(row) {
      this.selectedCompetency = { ...row };
      this.showEditModal = true;
    },
    tableCallback(key, index, row, col) {
      if (col === 'edit') {
        this.editCompetency(row);
      } else if (col === 'delete') {
        this.confirmDeletion(row);
      }
    },
    confirmDeletion(competency) {
      this.$ktoast.confirmToast(
        'Are you sure you want to delete this competency?',
        'warning',
        () => this.deleteCompetency(competency),
      );
    },
    deleteCompetency(competency) {
      this.$logger.info('Deleting competency', { id: competency.id });
      this.$Loading.start();
      this.$http.delete(`${this.apiEndpoint}/${competency.id}`)
        .then(() => {
          this.$logger.info(`Successfully deleted ${this.entityName} competency`);
          this.$ktoast.success(`${this.entityName} competency deleted.`);
          this.updateGetCompetencies();
        })
        .catch(err => {
          this.$logger.error(`Could not delete ${this.entityName} competency`, err);
          this.showError(err);
        })
        .finally(() => {
          this.$Loading.finish();
        });
    },
    updateGetCompetencies() {
      this.$emit('update');
    },
  },
};
</script>

<template>
  <div>
    <h1>Learning Unit Overview</h1>
    <asset-overview v-if="lusReady"
      asset-icon="fa-graduation-cap"
      :assets="lus"
      :kable-headers="kableHeaders"
      :kable-rows="kableRows"
      :allow-asset-creation="false"
      :show-archived-assets="showArchived"
      @tablecallback="tableCallback"
      @archive-toggle="updateArchiveToggle"
    ></asset-overview>
  </div>
</template>

<script>
import ErrorMixin from '@mixins/error-mixins';
import ArchiveToastConfirmMixin from '../archive-toast-confirm-mixin';
// import getIcon from '../../../../modules/kate-table-icons';
import AssetOverview from '../asset-overview.vue';

export default {
  components: {
    AssetOverview,
  },

  mixins: [ErrorMixin, ArchiveToastConfirmMixin],

  data() {
    return {
      lusReady: false,
      lus: [],
      showArchived: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getLearningUnits();
  },

  watch: {
    lusReady() {
      if (this.lusReady) {
        this.$Loading.finish();
      } else {
        this.$Loading.start();
      }
    },
  },

  computed: {
    kableHeaders() {
      let headers = {
        name: {
          name: 'Title',
          filterable: true,
          type: 'url',
        },
        description: {
          name: 'Description',
        },
        repository: {
          name: 'Repository URL',
        },
        last_deployment: {
          name: 'Last Deployed',
          type: 'timestamp',
          sortable: true,
        },
        tags: {
          name: 'Tags',
          type: 'tags',
          filterable: true,
          sortable: true,
        },
        kloud_compatible: {
          name: 'KLOUD Compatible',
        },
      };
      if (this.$permissions.hasPermission('manage_assets')) {
        headers = {
          ...headers,
          archive: {
            name: 'Archive',
            type: 'action',
            sortable: true,
          },
        };
      }
      return headers;
    },
    kableRows() {
      return this.lus.map(lu => ({
        name: {
          text: lu.name,
          path: {
            name: 'curriculum_asset_details',
            params: {
              assetId: lu.id,
              assetType: 'learning_unit',
            },
          },
        },
        description: lu.description,
        repository: this.getLink(lu.gitlab_repo_url),
        last_deployment: lu.last_deployment,
        tags: {
          tags: lu.tags,
          sortBy: lu.tags ? [...lu.tags].sort().join(' ') : '',
        },
        archive: {
          text: this.assetArchiveButton(lu.is_active),
        },
        id: lu.id,
        kloud_compatible: this.getKloudCompatibleIcon(lu.kloud_compatible),
        is_active: lu.is_active,
      })).filter(lu => (this.showArchived || lu.is_active));
    },
  },

  methods: {
    updateArchiveToggle(val) {
      this.showArchived = val;
    },

    getLink(url) {
      if (url) {
        return `<a href="${url}" target="_blank">${url}</a>`;
      }
      return '';
    },

    getKloudCompatibleIcon(compatible) {
      const compatibleStr = compatible ? 'Compatible' : 'Incompatible';
      return `<div class="tags"><span class="${compatibleStr.toLowerCase()}">${compatibleStr}</span></div>`;
    },

    getLearningUnits() {
      this.$logger.info('Getting learning units');
      this.lusReady = false;
      this.$http.get('/api/curriculum/admin/learning_unit?active_only=false').then(result => {
        this.$logger.info('Got learning units');
        this.lus = result.data.lus;
      }).catch(err => {
        if (err.response.status !== 404) {
          this.$logger.error('Error getting learning units', undefined, err);
          this.showError(err);
        } else {
          this.$logger.warn('No learning units found');
        }
      }).then(() => {
        this.lusReady = true;
      });
    },

    // Archiving Lus
    archiveCallback(luId) {
      this.$logger.info('Archiving lu', { luId }, true);
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/learning_unit/${luId}/archive`)
        .then(() => {
          this.$logger.info('Lu has been archived', { luId });
          this.$ktoast.success('Learning unit has been archived');
          this.getLearningUnits();
        }).catch(err => {
          this.$logger.error('Error archiving lu', { luId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    unarchiveCallback(luId) {
      this.$logger.info('Unarchiving lu', { luId }, true);
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/learning_unit/${luId}/unarchive`)
        .then(() => {
          this.$logger.info('Lu has been unarchived', { luId });
          this.$ktoast.success('Learning unit has been unarchived');
          this.getLearningUnits();
        }).catch(err => {
          this.$logger.error('Error unarchiving lu', { luId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    tableCallback(key, index, row, column) {
      if (column === 'archive') {
        this.tableArchiveCallback(row);
      }
    },
  },
};
</script>

<template>
  <div>
    <h1>Manage Programme Blueprints</h1>
    <k-nav-tabs :currentTab="currentTab" :tabs="tabs"></k-nav-tabs>
    <router-view @refresh-blueprints="getProgrammeBlueprints" v-bind="pageProps"></router-view>
  </div>
</template>

<style>
td.k-table-key-delete {
  text-align: center;
}

td.k-table-key-delete i.fas.fa-trash-alt {
  color: var(--kate-danger);
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import KNavTabs from '@base-components/k-nav-tabs.vue';
import PageReadyMixin from '@mixins/page-ready-mixin';

export default {
  components: {
    KNavTabs,
  },

  mixins: [ErrorMixin, PageReadyMixin],

  data() {
    return {
      tabs: [
        {
          name: 'Current',
          routeName: 'curriculum_blueprints_current_programmes',
        },
        {
          name: 'Create',
          routeName: 'curriculum_blueprints_create_programme',
        },
      ],

      products: [],
      productsReady: false,
      programmeBlueprints: [],
      programmeBlueprintsReady: false,
    };
  },

  beforeMount() {
    this.getProducts();
    this.getProgrammeBlueprints();
  },

  computed: {
    ready() {
      return this.productsReady && this.programmeBlueprintsReady;
    },
    currentTab() {
      return this.$route.name;
    },
    pageProps() {
      return {
        products: this.products,
        programmeBlueprints: this.programmeBlueprints,
        blueprintType: 'programme',
      };
    },
  },

  methods: {
    getProgrammeBlueprints() {
      this.$logger.info('Getting programme blueprints');
      this.programmeBlueprintsReady = false;
      return this.$http.get('/api/curriculum/blueprints/programmes').then(res => {
        this.programmeBlueprints = res.data.blueprints;
        this.$logger.info('Successfully retrieved programme blueprints');
      }).catch(err => {
        this.$logger.error('Error retrieving programmes', undefined, err);
        this.showError(err);
      }).then(() => {
        this.programmeBlueprintsReady = true;
      });
    },
    getProducts() {
      this.$logger.info('Getting Product IDs');
      this.productsReady = false;
      return this.$http.get('/api/curriculum/products').then(res => {
        this.products = res.data;
      }).catch(err => {
        this.$logger.error('Getting Product IDS for New Programme Blueprints', undefined, err);
        this.showError(err);
      }).then(() => {
        this.productsReady = true;
      });
    },
  },
};
</script>

<template>
  <div>
    <div class="programme-summary-table-container" :class="fixedHeaderClass">
      <k-table v-if="scoresRows.length"
        :max="50"
        :rows="scoresRows"
        :headers="scoresHeaders"
        :super-headers="scoresSuperHeaders"
        :downloadable="true"
        :filename="scoresTableFilename"
        :hideable="true"
        :col-filter-options="colFilterOptions"
        :show-legend="true"
      ></k-table>
    </div>
  </div>
</template>

<style>
.programme-summary-table-container .k-table-panel {
  padding: 15px;
}

.programme-summary-table-container .optional {
  color: var(--kate-button-primary);
}

.programme-summary-table-container .k-table-header-text i {
  font-size: 0.85em;
}

.programme-summary-table-container .k-table-header-text i.fa-laptop-code {
  color: var(--kate-assignment);
}

.programme-summary-table-container .k-table-header-text i.fa-lightbulb {
  color: var(--kate-quiz);
}

.k-table-container th.k-table-header-latest_timestamp {
  min-width: 200px;
}

/* Make the Name column fixed */
.fixed-header .k-table-main-container {
  overflow-x: auto;
  padding-left: 0;
  padding-bottom: 0;
  margin: 0 0 15px 363px;
}

.fixed-header table {
  margin-bottom: 0;
}

/* Make sure all the cells are the proper height even if empty */
.fixed-header thead th:nth-child(-n+2) {
  height: 58px;
  background-color: var(--kate-background-body);
}

.fixed-header .k-table-row,
.fixed-header .k-table-key-learner,
.fixed-header thead tr:nth-child(-n+2) th {
  border-bottom: 0;
}

.fixed-header .k-table-row,
.fixed-header .k-table-key-learner {
  height: 40px;
}

.fixed-header thead tr th.super-header:first-child,
.fixed-header thead tr th:nth-child(-n+2),
.fixed-header tbody tr td:nth-child(-n+2) {
  position: absolute;
  left: 20px;
  z-index: 1;
  overflow-x: auto;
  overflow-y: hidden;
}

/* give the first (empty) super-header child the width of the first two columns */
.fixed-header thead tr th.super-header:first-child {
  width: 359px;
}

.fixed-header th.k-table-header-learner,
.fixed-header td.k-table-key-learner {
  width: 110px;
  min-width: 110px;
}

.fixed-header thead tr th.k-table-header-name,
.fixed-header td.k-table-key-name {
  width: 250px;
  left: 129px !important;
  border-bottom: 0;
  border-right: 1px solid var(--kate-background-body-alpha);
}

.fixed-header tbody tr:nth-child(2n) .k-table-key-learner,
.fixed-header tbody tr:nth-child(2n) .k-table-key-name {
  background-color: var(--kate-panel-alt);
}

.fixed-header tbody tr:last-child .k-table-key-learner,
.fixed-header tbody tr:last-child .k-table-key-name {
  border-bottom: 1px solid var(--kate-background-alt-alpha);
}

/* Need to scroll with the rest */
.fixed-header thead tr th.super-header {
  position: inherit;
}

@media only screen and (max-width: 900px) {
  .fixed-header .k-table-main-container {
    margin: 0;
    padding: 0;
  }

  .fixed-header thead tr th.super-header:first-child,
  .fixed-header thead tr th:nth-child(-n+2),
  .fixed-header tbody tr td:nth-child(-n+2) {
    position: inherit;
  }
}

/* Scrollbar for cell */
.fixed-header .k-table-key-name::-webkit-scrollbar-thumb {
  background-color: var(--scrollbar-thumb);
  border-radius: 0;
  box-shadow: none;
}

.fixed-header .k-table-key-name::-webkit-scrollbar {
  height: 8px;
  width: 8px;
}
</style>

<script>
import KTable from '@base-components/k-table.vue';
import formatId from '../../../modules/format-user-id';
import getOrNull from '../../../modules/get-or-null';

export default {
  components: {
    'k-table': KTable,
  },
  props: {
    scores: {
      type: Array,
      required: true,
    },
    paks: {
      type: Array,
      required: true,
      default: () => [],
    },
    quizzes: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data: () => ({
    scoresRows: [],
    colFilterOptions: [
      {
        text: 'Optional Only',
        func: col => col.options.is_mandatory === false || col.options.is_mandatory === undefined,
      },
      {
        text: 'Mandatory Only',
        func: col => col.options.is_mandatory === true || col.options.is_mandatory === undefined,
      },
      {
        text: 'Mandatory and Unset',
        func: col => col.options.is_mandatory === true || col.options.is_mandatory === undefined || col.options.is_mandatory === null,
      },
    ],
  }),

  computed: {
    fixedHeaderClass() {
      if (Object.keys(this.scoresHeaders).length > 5) {
        return 'fixed-header';
      }
      return '';
    },
    scoresHeaders() {
      const output = {
        learner: {
          name: 'User ID',
          sortable: true,
          type: 'string',
          hideable: false,
        },
        name: {
          name: 'Name',
          type: 'url',
          filterable: true,
          sortable: true,
          hideable: false,
        },
        email: {
          name: 'Email',
          type: 'string',
          filterable: true,
          sortable: true,
          hideable: true,
        },
        account_activated: {
          name: 'Account Activated',
          type: 'timestamp',
          sortable: true,
          hideable: false,
        },
        latest_timestamp: {
          name: 'Last Submission',
          sortable: true,
          type: 'timestamp',
        },
      };
      for (let i = 0; i < this.orderedPaks.length; i++) {
        output[`pak_${this.orderedPaks[i].id}`] = {
          name: this.orderedPaks[i].name,
          displayName: `<i class="fas fa-laptop-code"></i> ${this.orderedPaks[i].name}`,
          type: 'percentage',
          sortable: true,
          trim: true,
          options: {
            is_mandatory: this.orderedPaks[i].is_mandatory,
            classes: this.orderedPaks[i].is_mandatory ? '' : 'optional',
            color: true,
            format: `0.${'0'.repeat(getOrNull('additional_features.number_precision', this.orderedPaks[i]) || 2)}%`,
          },
        };
      }
      for (let i = 0; i < this.orderedQuizzes.length; i++) {
        output[`quiz_${this.quizzes[i].id}`] = {
          name: this.quizzes[i].name,
          displayName: `<i class="fas fa-lightbulb"></i> ${this.quizzes[i].name}`,
          type: 'percentage',
          sortable: true,
          trim: true,
          options: {
            is_mandatory: this.quizzes[i].is_mandatory,
            classes: this.quizzes[i].is_mandatory ? '' : 'optional',
            color: true,
          },
        };
      }
      return output;
    },

    scoresSuperHeaders() {
      const output = {
        0: {
          name: '',
          type: 'string',
          filterable: false,
          sortable: false,
          hideable: false,
          subHeaderKeys: ['learner', 'name'],
        },
        '00': {
          name: '',
          type: 'string',
          filterable: false,
          sortable: false,
          hideable: false,
          subHeaderKeys: ['email', 'latest_timestamp', 'account_activated'],
        },
      };
      // HACK
      // If the numbers are not in order, we ignore them
      const checkNumbersAreInOrder = modules => {
        // hack
        // only show numbers if all numbers are there;
        for (let i = 0; i < modules.length; i++) {
          if (modules[i].number !== i + 1) {
            return false;
          }
        }
        return true;
      };
      const inOrder = checkNumbersAreInOrder(this.pakModules);
      for (let i = 0; i < this.pakModules.length; i++) {
        output[inOrder ? this.pakModules[i].number : this.pakModules[i].id] = {
          name: inOrder ? `${this.pakModules[i].number}. ${this.pakModules[i].name}` : this.pakModules[i].name,
          sortable: false,
          type: 'string',
          trim: true,
          options: {
            color: true,
          },
          subHeaderKeys: this.paks
            .filter(x => x.module_id === this.pakModules[i].id).map(x => `pak_${x.id}`)
            .concat(this.quizzes.filter(x => x.module_id === this.pakModules[i].id).map(x => `quiz_${x.id}`)),
          colspan: this.pakModules[i].paks,
        };
      }
      // Sort by super header name - ignoring the 0 and 00 keys. This will equate to sorting by module
      // number for modules that have numbers. Modules w/o numbers will go to the end and be sorted alphabetically
      const orderedOutput = {};
      Object.keys(output).sort((a, b) => {
        if (a === 0 || a === '00') {
          return -1;
        }
        return this.sortByName(output[a], output[b]);
      }).forEach(key => {
        orderedOutput[key] = output[key];
      });
      return orderedOutput;
    },
    scoresTableFilename() {
      const d = new Date();
      return `scores_${('0' + d.getHours()).slice(-2)}${('0' + d.getMinutes()).slice(-2)}.csv`; // eslint-disable-line prefer-template
    },
    pakModules() {
      return this.paks.concat(this.quizzes).map(x => ({
        id: x.module_id,
        name: x.module_name,
        number: x.module_number,
        paks: this.paks.filter(y => y.module_id === x.module_id).length + this.quizzes.filter(y => y.module_id === x.module_id).length,
      }))
      // Remove duplicates
        .filter((v, i, a) => a.map(z => z.id).indexOf(v.id) === i)
      // Sort by module number
        .sort((a, b) => ((a.number > b.number) ? 1 : -1));
    },

    orderedPaks() {
      return this.paks.slice().sort(this.sortSubHeaders);
    },

    orderedQuizzes() {
      return this.quizzes.slice().sort(this.sortById);
    },
  },
  methods: {
    sortSubHeaders(a, b) {
      if (a.number && b.number) {
        return this.sortByNumber(a, b);
      }
      if (!a.number && !b.number) {
        return this.sortByName(a, b);
      }
      if (a.number && !b.number) {
        return -1;
      }
      if (!a.number && b.number) {
        return 1;
      }
      return 0;
    },

    sortByName(a, b) {
      const alc = a.name;
      const blc = b.name;
      if (alc > blc) {
        return 1;
      }
      if (alc < blc) {
        return -1;
      }
      return 0;
    },

    sortByNumber(a, b) {
      const numA = a.number;
      const numB = b.number;
      if (numA < numB) {
        return -1;
      }
      if (numA > numB) {
        return 1;
      }
      return 0;
    },

    sortById(a, b) {
      const numA = a.id;
      const numB = b.id;
      if (numA < numB) {
        return -1;
      }
      if (numA > numB) {
        return 1;
      }
      return 0;
    },

    constructScoresRows() {
      const output = [];
      let name;
      let email;
      let entry;
      // Add name to returned payload
      for (let i = 0; i < this.scores.length; i++) {
        entry = this.scores[i];
        name = this.scores[i].full_name;
        email = this.scores[i].email;
        entry = Object.assign(entry, {
          learner: formatId(this.scores[i].user_id),
          name: {
            text: name,
            path: { name: 'dashboard_students', params: { studentId: this.scores[i].user_id } },
          },
          email,
        });
        output.push(entry);
      }
      this.scoresRows = Object.freeze(output);
    },
  },
};
</script>

<template>
  <div class="add-badges badges">
    <button class="btn btn-primary" @click.stop.prevent="showBadgeModal = true">Add badges</button>
    <select-badge-modal
      :show="showBadgeModal"
      :specificBadges="badgeOptions"
      @close="showBadgeModal = false"
      @choose="onAddBadge"
    ></select-badge-modal>
    <div v-for="badge in badgesSortedByThreshold" :key="badge.id">
      <badge-icon
        :removable="true"
        :icon-image="badge.resource_link"
        :badge-name="badge.name"
        :threshold-score="badge.threshold_score"
        @remove="onRemove(badge.id)"
      ></badge-icon>
    </div>
  </div>
</template>
<style scoped>
.add-badges {
  margin-bottom: 10px;
}

.badges {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}
</style>
<script>

import BadgeIcon from '../../../achievements/badge-icon.vue';
import SelectBadgeModal from '../../../achievements/select-badge-modal.vue';

export default {
  components: {
    SelectBadgeModal,
    BadgeIcon,
  },
  emits: ['onAddBadge', 'remove'],
  props: {
    badgeOptions: {
      type: Array,
      default: () => [],
    },
    badgesSortedByThreshold: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      showBadgeModal: false, // by default closed
    };
  },

  methods: {
    onAddBadge(badge) {
      this.$emit('onAddBadge', badge);
    },
    onRemove(badgeId) {
      this.$emit('remove', badgeId);
    },
  },
};
</script>

<template>
  <div class="quiz-answer-list">
    <div class="answers col-md-12">
      <quiz-answer v-for="(answer, index) in answers"
        v-model="answers[index]"
        :setCorrect="setCorrect"
        @remove="removeAnswer(index)"
        :key="index">
      </quiz-answer>
    </div>
    <div class="col-md-12 row">
      <button class="new-answer btn-success" title="Add new answer" @click="addNewAnswer">
        <i class="fas fa-plus"></i>Add Answer
      </button>
    </div>
  </div>
</template>

<style scoped>
.quiz-answer-list .answers {
  padding: 0;
}

.new-answer {
  margin: 5px 0;
  padding: 5px 10px;
  font-size: 0.9em;
}

.new-answer i {
  margin-right: 8px;
}

.row {
  padding-left: 0;
  padding-right: 0;
}

.headers > span:first-child {
  padding-left: 0;
}

@media (max-width: 991px) {
  .quiz-answer-list .answers {
    padding: 0;
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}
</style>

<script>
import QuizAnswer from './quiz-answer.vue';

export default {
  components: {
    'quiz-answer': QuizAnswer,
  },
  props: {
    modelValue: {
      type: Array,
    },
    setCorrect: {
      type: Boolean,
      default: true,
    },
    defaultAnswers: {
      type: Number,
      default: 4,
    },
  },

  data() {
    return {
      answers: this.modelValue,
    };
  },

  mounted() {
    if (!this.answers) {
      this.answers = [];
    }
    if (this.answers.length < 1) {
      // Start with at least 4 answers
      for (let i = 0; i < this.defaultAnswers; i++) {
        this.addNewAnswer();
      }
    }
  },

  watch: {
    modelValue() {
      this.answers = this.modelValue;
    },
    answers: {
      handler() {
        this.$emit('update:modelValue', this.answers);
      },
      deep: true,
    },
  },

  methods: {
    addNewAnswer() {
      this.answers.push(this.setCorrect ? {
        answer: '',
        is_correct: false,
      } : '');
    },
    removeAnswer(index) {
      this.answers.splice(index, 1);
    },
  },
};
</script>

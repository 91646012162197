<template>
  <div class="add-to-gcal-controls">
    <template v-if="eventLink">
      <a class="btn add-to-gcal-button btn-outlined" :href="eventLink" target="_blank">
        <i :class="googleIconClass"></i> Calendar
      </a>
      <k-tooltip text="Sync from Google to KATE" position="left" text-align="center">
        <button class="btn sync-to-gcal-button" @click="syncGcalToKate">
          <i class="fa-solid fa-arrows-rotate" :class="syncIconClass"></i>
        </button>
      </k-tooltip>
    </template>
    <template v-else>
      <button class="btn btn-outlined add-to-gcal-button" :disabled="disabled" @click="addToCalendar">
        <i :class="googleIconClass"></i> Add to Calendar
      </button>
    </template>
  </div>
</template>

<style>
.add-to-gcal-button {
  text-align: center;
  width: 100%;
}

.add-to-gcal-controls {
  display: flex;
  gap: 5px;
}

.add-to-gcal-button:disabled {
  cursor: not-allowed;
  filter: brightness(70%);
}

.gcal-sync-option {
  margin-bottom: 1em;
}

.gcal-sync-warning {
  font-style: italic;
}

.gcal-sync-modal-loading {
  font-size: xx-large;
  text-align: center;
}

.sync-to-gcal-button {
  color: var(--kate-type-dark);
}
</style>

<script>
import KTooltip from '@base-components/k-tooltip.vue';
import GoogleOAuthMixin from '@mixins/google-oauth-login-mixin';
import ErrorMixin from '@mixins/error-mixins';

export default {
  components: {
    KTooltip,
  },
  mixins: [GoogleOAuthMixin, ErrorMixin],
  props: {
    disable: {
      type: Boolean,
      default: false,
    },
    moduleMeetingId: {
      type: Number,
      required: true,
    },
    userId: {
      type: Number,
      required: true,
    },
    eventLink: {
      type: String,
    },
  },
  data() {
    return {
      loading: false,
      showModal: false,
    };
  },
  computed: {
    disabled() {
      return this.loading || this.disable;
    },
    syncIconClass() {
      return this.loading ? 'fa-spin' : '';
    },
    googleIconClass() {
      if (this.loading) {
        return 'fa-brands fa-google fa-spin';
      }
      return 'fa-brands fa-google';
    },
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    },
    handleError(err) {
      if (err.response?.data?.err === 'invalid_oauth_creds') {
        this.goToGoogleSignIn();
      } else {
        this.showError(err);
      }
    },
    syncGcalToKate() {
      this.loading = true;
      this.$http.post(`/api/curriculum/meeting/${this.moduleMeetingId}/gcal/sync-down`, {
        user_id: this.userId,
      }).then(() => {
        this.$emit('updated');
      }).catch(this.handleError).then(() => {
        this.loading = false;
        this.closeModal();
      });
    },
    addToCalendar() {
      if (this.eventLink || this.disabled) {
        return;
      }
      this.loading = true;
      this.$http.post(`/api/curriculum/meeting/${this.moduleMeetingId}/add-to-gcal`, {
        user_id: this.userId,
      }).then(() => {
        this.$emit('updated');
      }).catch(this.handleError).then(() => {
        this.loading = false;
      });
    },
  },
};
</script>

<template>
  <div class="module-card">
    <card-header
      :module="module"
      :isModule="isModule"
      :released="released"
      :isUncommitted="isUncommitted"
      :allowEdit="allowEdit"
      :inEditMode="inEditMode"
      @toggleEditMode="toggleEditMode"
      @revertPendingChanges="revertPendingChanges"
    />

    <div class="module-card-content">
      <card-body
        v-if="!inEditMode"
        :module="module"
        :isModule="isModule"
      />

      <!-- Edit mode content -->
      <template v-else>
        <edit-blueprint
          v-if="!isModule"
          :module="module"
        />

        <edit-programme
          v-else
          :module="module"
        />
      </template>

      <content-badges
        :module="module"
        :targetModuleType="targetModuleType"
        :chaptersReady="chaptersReady"
        :tags="tags"
        :showContentLink="showContentLink"
        :assets="assets"
        :addedModule="addedModule"
        :inEditMode="inEditMode"
        :showBadges="showBadges"
      />
    </div>
  </div>
</template>

<style scoped>
.module-card {
  border-radius: 8px;
}

.module-card-content {
  padding: 5px;
}
</style>

<script>
import CardHeader from './card-header.vue';
import ContentBadges from './content-badges.vue';
import CardBody from './card-body.vue';
import EditBlueprint from './edit-blueprint.vue';
import EditProgramme from './edit-programme.vue';

export default {
  components: {
    CardHeader,
    ContentBadges,
    CardBody,
    EditBlueprint,
    EditProgramme,
  },

  props: {
    // Module data props
    module: {
      type: Object,
      required: true,
    },
    isModule: {
      type: Boolean,
      required: true,
    },
    // State flags
    released: {
      type: Boolean,
      default: false,
    },
    isUncommitted: {
      type: Boolean,
      default: false,
    },
    allowEdit: {
      type: Boolean,
      default: false,
    },
    inEditMode: {
      type: Boolean,
      required: true,
    },
    chaptersReady: {
      type: Boolean,
      default: false,
    },
    showContentLink: {
      type: Boolean,
      default: false,
    },
    addedModule: {
      type: Boolean,
      default: false,
    },
    showBadges: {
      type: Boolean,
      default: false,
    },
    targetModuleType: {
      type: String,
      required: true,
    },
    // Content related props
    assets: {
      type: Array,
      default: () => [],
    },
    tags: {
      type: Array,
      default: () => [],
    },
  },

  emits: [
    'toggleEditMode',
    'revertPendingChanges',
  ],

  methods: {
    revertPendingChanges() {
      this.$emit('revertPendingChanges', this.module.index);
    },
    toggleEditMode() {
      this.$emit('toggleEditMode', this.module.index);
    },
  },
};
</script>

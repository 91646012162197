import DashStudentPage from '../../../dashboard/student/dashboard-students.vue';
import DashFeedbackWrapper from '../../wrappers/kate_dashboard/feedback-dashboard-wrapper.vue';
import LoginPage from '../../../profile/login.vue';
import NotFound from '../../../layout/not-found.vue';
import Forbidden from '../../../layout/forbidden.vue';

import KateIDE from '../../../ide/k-ide.vue';
import FeedbackNotebook from '../../../notebook/notebook.vue';
import ProjectOverview from '../../../feedback/project-over.vue';
import MaintenancePage from '../../../layout/maintenance.vue';
import ChangePasswordPage from '../../../profile/change-password-page.vue';
import PolicyPage from '../../../policy/policy.vue';
import PolicyAcceptPage from '../../../policy/policy-acceptance.vue';

// Landing
import DashboardLanding from '../../../dashboard/dashboard-landing.vue';

// Profile
import ProfileOverview from '../../../profile/profile-overview.vue';
import ProfileLanding from '../../../profile/profile-landing.vue';
import ProfileInformation from '../../../profile/profile-information.vue';
import ProfileEmailAddress from '../../../profile/profile-email-address.vue';
import ProfilePassword from '../../../profile/profile-password.vue';
import ProfileQuestions from '../../../profile/profile-questions.vue';
import ProfileGitSettings from '../../../profile/profile-git-settings.vue';
import VerifyEmailPage from '../../../profile/verify-email.vue';
import ManageKodsPage from '../../../profile/kods.vue';
import SpinKodPage from '../../../profile/kod-loading.vue';

// ** Monitor
import ProgrammeSummary from '../../../dashboard/monitor/programme_summary/programme-summary.vue';
import LearnersProgress from '../../../dashboard/monitor/learner-progress.vue';
import EmployerLearnerSummary from '../../../dashboard/monitor/employer-learners-summary.vue';
import EngagementOverview from '../../../dashboard/monitor/engagement/engagement-overview.vue';

// ** Curriculum

// -- Blueprints
import ProgrammeBlueprints from '../../../dashboard/curriculum/blueprints/programme-blueprints-overview.vue';
import CurrentProgrammeBlueprints from '../../../dashboard/curriculum/blueprints/current-programme-blueprints.vue';
import CreateProgrammeBlueprint from '../../../dashboard/curriculum/blueprints/create-programme-blueprint.vue';

import ManageProgrammeBlueprint from '../../../dashboard/curriculum/blueprints/manage-programme-blueprint.vue';
import ManageProgrammeBlueprintContent from '../../../dashboard/curriculum/blueprints/manage-programme-blueprint-content.vue';
import AbstractManageProgrammeAchievements from '../../../dashboard/programmes/abstract-manage-programme-achievements.vue';

// -- Assets
import AssetDetails from '../../../dashboard/curriculum/assets/asset-details.vue';

// Assignments
import AssignmentOverview from '../../../dashboard/curriculum/assets/assignments/assignment-overview.vue';

// Learning Units
import LearningUnitOverview from '../../../dashboard/curriculum/assets/learning_units/learning-unit-overview.vue';

// Slides
import SlidesOverview from '../../../dashboard/curriculum/assets/slides/slides-overview.vue';

// Videos
import VideoOverview from '../../../dashboard/curriculum/assets/videos/video-overview.vue';

// Quizzes
import QuizOverview from '../../../dashboard/curriculum/assets/quizzes/quiz-overview.vue';
import QuizPage from '../../../quizzes/quiz-page.vue';
import EditQuiz from '../../../dashboard/curriculum/assets/quizzes/edit-quiz.vue';

// Questionnaires
import QuestionnaireOverview from '../../../dashboard/curriculum/assets/questionnaires/questionnaire-overview.vue';
import EditQuestionnaire from '../../../dashboard/curriculum/assets/questionnaires/edit-questionnaire.vue';
import QuestionnairePage from '../../../questionnaires/questionnaire-page.vue';

// Calender Events
import CalenderEventOverview from '../../../dashboard/curriculum/assets/calendar_events/calendar-events-overview.vue';

// Meetings
import MeetingOverview from '../../../dashboard/curriculum/assets/meetings/meeting-overview.vue';

// Markdown Pages
import MarkdownPageOverview from '../../../dashboard/curriculum/assets/markdown_pages/markdown-page-overview.vue';

//  ** Programmes
import ProgrammesOverview from '../../../dashboard/programmes/programmes-overview.vue';
import CurrentProgrammes from '../../../dashboard/programmes/current-programmes.vue';
import CreateProgramme from '../../../dashboard/programmes/create-programme.vue';
import ManageProgramme from '../../../dashboard/programmes/manage-programme.vue';
import ManageProgrammeContent from '../../../dashboard/programmes/manage-programme-content.vue';
import ManageProgrammeMembers from '../../../dashboard/programmes/manage-programme-members.vue';
import ManageProgrammeCompetencies from '../../../dashboard/programmes/manage-programme-competencies.vue';

// Programme Module Release Assets
import ManageModule from '../../../dashboard/programmes/modules/manage-module.vue';
import ModuleChapterManager from '../../../dashboard/programmes/modules/module_management/module-chapter-manager.vue';
import ModuleMetaManager from '../../../dashboard/programmes/modules/module_management/module-meta-manager.vue';
import ModuleAssetRelease from '../../../dashboard/programmes/modules/module_management/module-asset-release.vue';

// ** Users

// Users
import PrivilegedUsers from '../../../dashboard/users/privileged-users.vue';
import Mentors from '../../../dashboard/users/mentors-table.vue';

// Badges
import Achievements from '../../../dashboard/achievements/achievements.vue';
import CertificatesOverview from '../../../dashboard/achievements/certificates-overview.vue';
import BadgesOverview from '../../../dashboard/achievements/badges-overview.vue';

// ** Knowledge Base
import KnowledgeBase from '../../../knowledge_base/knowledge-base.vue';
import ThreadPage from '../../../knowledge_base/thread-page.vue';
import { ASSET_TYPES } from '../../../constants';
import LearnerSupport from '../../../dashboard/users/learner-support.vue';

const routes = [
  {
    path: '/',
    component: ProgrammeSummary,
    name: 'dashboard_home',
    meta: {
      prettyName: 'Home',
      show: true,
      title: 'KATE - Programme Summary',
    },
  },

  {
    path: '/login',
    component: LoginPage,
    name: 'login_page',
    kate_sidebar_disabled: true,
    kate_is_public: true,
    meta: {
      title: 'KATE - Login',
    },
  },
  {
    path: '/login_token',
    component: LoginPage,
    name: 'login_gl_page',
    props: {
      withGitlabToken: true,
    },
    kate_sidebar_disabled: true,
    kate_is_public: true,
    meta: {
      title: 'KATE - Login Token',
    },
  },
  {
    path: '/email_verification/:verificationType?',
    component: VerifyEmailPage,
    name: 'email_verification_page',
    kate_sidebar_disabled: true,
    kate_is_public: true,
    meta: {
      title: 'KATE - Email Verification',
    },
  },
  {
    path: '/maintenance',
    component: MaintenancePage,
    name: 'maintenance',
    kate_sidebar_disabled: true,
    kate_is_public: true,
    meta: {
      title: 'KATE - Maintenance',
    },
  },
  {
    path: '/student/:studentId',
    component: DashStudentPage,
    name: 'dashboard_students',
    meta: {
      crumbs: [
        { param: 'studentId', active: true, api: 'student_id' },
      ],
      title: 'KATE - Dashboard Students',
    },
  },
  {
    path: '/profile',
    component: ProfileOverview,
    children: [
      {
        path: '',
        component: ProfileLanding,
        name: 'profile_landing',
        meta: {
          title: 'KATE - Profile Page',
        },
      },
      {
        path: 'information',
        component: ProfileInformation,
        name: 'profile_information',
        meta: {
          title: 'KATE - Profile Information',
        },
      },
      {
        path: 'email',
        component: ProfileEmailAddress,
        name: 'profile_email_address',
        meta: {
          title: 'KATE - Profile Email Address',
        },
      },
      {
        path: 'password',
        component: ProfilePassword,
        name: 'profile_password',
        meta: {
          title: 'KATE - Profile Password',
        },
      },
      {
        path: 'knowledge',
        component: ProfileQuestions,
        name: 'profile_questions',
        meta: {
          title: 'KATE - Profile Knowledge Base',
        },
      },
      {
        path: 'knowledge/thread/:threadId',
        component: ThreadPage,
        name: 'profile_knowledge_thread',
        meta: {
          title: 'KATE - Profile Knowledge Base Thread',
        },
      },
      {
        path: 'git_settings',
        component: ProfileGitSettings,
        name: 'profile_git_settings',
        meta: {
          title: 'KATE - Profile Git Settings',
        },
      },
      {
        path: 'kods',
        component: ManageKodsPage,
        name: 'profile_manage_kods_page',
        meta: {
          title: 'KATE - Profile Manage Kods',
        },
      },
    ],
  },
  {
    path: '/learning_environments/kods/:assetType/:assetId',
    component: SpinKodPage,
    name: 'spin_kod_page',
    meta: {
      title: 'KATE - Spin Kod',
    },
  },
  {
    path: `/student/:studentId/${ASSET_TYPES.quiz.routeName}/:moduleQuizId/feedback`,
    component: QuizPage,
    name: 'dash_quiz_feedback',
    meta: {
      title: 'KATE - Quiz Feedback',
    },
  },
  {
    path: `/student/:studentId/${ASSET_TYPES.questionnaire.routeName}/:moduleQuestionnaireId/feedback`,
    component: QuestionnairePage,
    name: 'dash_questionnaire_feedback',
    meta: {
      title: 'KATE - Questionnaire Feedback',
    },
  },
  {
    path: `/student/:studentId/${ASSET_TYPES.pak.routeName}/:modulePakId/feedback`,
    component: ProjectOverview,
    // name: 'pakOverview',
    meta: {
      title: 'KATE - Project Overview',
      crumbs: [
        {
          param: 'studentId',
          api: 'student_id',
          path: {
            name: 'dashboard_students',
            params: ['studentId'],
          },
        },
        { param: 'modulePakId', active: true },
      ],
    },
    children: [
      { /* jscpd:ignore-start */
        path: 'ide',
        component: KateIDE,
        name: 'dash_pak_ov_web_ide',
        meta: {
          title: 'KATE - Web IDE',
          crumbs: [
            {
              param: 'studentId',
              api: 'student_id',
              path: {
                name: 'dashboard_students',
                params: ['studentId'],
              },
            },
            { param: 'projectName', active: true },
          ],
        },
      }, /* jscpd:ignore-end */
      {
        path: '',
        alias: 'feedback',
        component: DashFeedbackWrapper,
        name: 'dash_pak_ov_feedback',
        meta: {
          title: 'KATE - Project Feedback',
          crumbs: [
            {
              param: 'studentId',
              api: 'student_id',
              path: {
                name: 'dashboard_students',
                params: ['studentId'],
              },
            },
            { param: 'modulePakId', active: true },
          ],
        },
      },
      {
        path: 'solution',
        component: KateIDE,
        name: 'dash_pak_ov_ide_solution',
        meta: {
          title: 'KATE - Project IDE Solution',
          crumbs: [
            {
              param: 'studentId',
              api: 'student_id',
              path: {
                name: 'dashboard_students',
                params: ['studentId'],
              },
            },
            { param: 'projectName', active: true },
          ],
        },
      },
      { /* jscpd:ignore-start */
        path: 'notebook',
        component: FeedbackNotebook,
        name: 'dash_pak_ov_notebook',
        meta: {
          title: 'KATE - Project Notebook',
          crumbs: [
            {
              param: 'studentId',
              api: 'student_id',
              path: {
                name: 'dashboard_students',
                params: ['studentId'],
              },
            },
            { param: 'projectName', active: true },
          ],
        },
      }, /* jscpd:ignore-end */
      {
        path: 'notebook_solution',
        component: FeedbackNotebook,
        name: 'dash_pak_ov_notebook_solution',
        meta: {
          title: 'KATE - Project Notebook Solution',
          crumbs: [
            {
              param: 'studentId',
              api: 'student_id',
              path: {
                name: 'dashboard_students',
                params: ['studentId'],
              },
            },
            { param: 'projectName', active: true },
          ],
        },
      },
    ],
  },

  // **** MONITOR **** \\
  {
    path: '/monitor',
    component: DashboardLanding,
    name: 'monitor',
    children: [
      // Programme Summary
      {
        path: '/monitor/programmes/:programmeId?',
        component: ProgrammeSummary,
        name: 'monitor_programme_summary',
        meta: {
          title: 'KATE - Programme Summary',
          prettyName: 'Programme Summary',
          show: true,
        },
      },
      // Learners Progress
      {
        path: 'learners',
        component: LearnersProgress,
        name: 'monitor_learner_progress',
        kate_permissions: ['monitor_programmes'],
        meta: {
          title: 'KATE - Learners Progress',
        },
      },
      // Employer learner summary
      {
        path: 'employer/learners',
        component: EmployerLearnerSummary,
        name: 'employer_learner_summary',
        kate_permissions: ['view_employer_learners'],
        meta: {
          title: 'KATE - Employer Learner Summary',
        },
      },
      // Engagement
      {
        path: 'engagement/:programmeId?',
        component: EngagementOverview,
        name: 'monitor_engagement_overview',
        kate_permissions: ['monitor_programmes'],
        meta: {
          title: 'KATE - Engagement Overview',
        },
      },
    ],
  },

  // **** Curriculum **** \\
  {
    path: '/curriculum',
    component: DashboardLanding,
    name: 'curriculum',
    children: [
      // Blueprints
      {
        path: 'blueprints/programmes',
        component: ProgrammeBlueprints,
        name: 'curriculum_blueprints_programmes',
        kate_permissions: ['manage_blueprints'],
        meta: {
          title: 'KATE - Programme Blueprints',
        },
        redirect: { name: 'curriculum_blueprints_current_programmes' },
        children: [
          {
            path: 'current',
            component: CurrentProgrammeBlueprints,
            name: 'curriculum_blueprints_current_programmes',
            meta: {
              title: 'KATE - Current Programme Blueprints',
            },
          },
          {
            path: 'create',
            component: CreateProgrammeBlueprint,
            name: 'curriculum_blueprints_create_programme',
            meta: {
              title: 'KATE - Create Programme Blueprint',
            },
          },
        ],
      },
      {
        path: '/blueprints/programmes/:programmeBlueprintId',
        component: ManageProgrammeBlueprint,
        name: 'curriculum_manage_programme_blueprint',
        kate_permissions: ['manage_blueprints'],
        meta: {
          title: 'KATE - Manage Programme Blueprint',
        },
        redirect: { name: 'manage_programme_blueprint_content' },
        children: [
          {
            path: '/blueprints/programmes/:programmeBlueprintId/content',
            component: ManageProgrammeBlueprintContent,
            name: 'manage_programme_blueprint_content',
            meta: {
              title: 'KATE - Manage Programme Blueprint Content',
            },
          },
          {
            path: '/blueprints/programmes/:programmeBlueprintId/achievements',
            component: AbstractManageProgrammeAchievements,
            name: 'manage_programme_blueprint_achievements',
            meta: {
              title: 'KATE - Manage Programme Blueprint Achievements',
            },
          },
          {
            path: '/blueprints/programmes/:programmeBlueprintId/competencies',
            component: ManageProgrammeCompetencies,
            name: 'manage_programme_blueprint_competencies',
            meta: {
              title: 'KATE - Manage Programme Blueprint Competencies',
            },
          },
        ],
      },
      // Manage a module in a programme blueprint
      {
        path: '/blueprints/programmes/:programmeBlueprintId/module/:programmeBlueprintModuleId',
        component: ManageModule,
        name: 'manage_programme_blueprint_module',
        kate_permissions: ['manage_blueprints'],
        meta: {
          title: 'KATE - Manage Programme Blueprint Module',
        },
        redirect: { name: 'programme_blueprint_module_meta_manager' },
        children: [
          {
            path: '',
            component: ModuleMetaManager,
            name: 'programme_blueprint_module_meta_manager',
            meta: {
              title: 'KATE - Manage Programme Blueprint Module Meta Manager',
            },
          },
          {
            path: 'chapters',
            component: ModuleChapterManager,
            name: 'programme_blueprint_module_chapter_manager',
            meta: {
              title: 'KATE - Manage Programme Blueprint Module Chapter Manager',
            },
          },
          {
            path: 'assets/:assetType',
            component: ModuleAssetRelease,
            name: 'programme_blueprint_module_asset_release',
            meta: {
              title: 'KATE - Manage Programme Blueprint Module Asset Release',
            },
          },
        ],
      },
      // Assets
      {
        path: 'assignment',
        component: AssignmentOverview,
        name: 'curriculum_assets_pak',
        kate_permissions: ['manage_assets', 'view_assets'],
        meta: {
          title: 'KATE - Assignment Overview',
        },
      },
      {
        path: 'learning_unit',
        component: LearningUnitOverview,
        name: 'curriculum_assets_lu',
        kate_permissions: ['manage_assets', 'view_assets'],
        meta: {
          title: 'KATE - Learning Unit Overview',
        },
      },
      {
        path: 'slides',
        component: SlidesOverview,
        name: 'curriculum_assets_slides',
        kate_permissions: ['manage_assets'],
        meta: {
          title: 'KATE - Slides Overview',
        },
      },
      {
        path: 'video',
        component: VideoOverview,
        name: 'curriculum_assets_video',
        kate_permissions: ['manage_assets'],
        meta: {
          title: 'KATE - Video Overview',
        },
      },
      {
        path: 'quiz',
        component: QuizOverview,
        name: 'curriculum_assets_quiz',
        kate_permissions: ['manage_assets'],
        meta: {
          title: 'KATE - Quiz Overview',
        },
      },
      {
        path: 'questionnaire',
        component: QuestionnaireOverview,
        name: 'curriculum_assets_questionnaire',
        kate_permissions: ['manage_assets'],
        meta: {
          title: 'KATE - Questionnaire Overview',
        },
      },
      {
        path: 'calendar_events',
        component: CalenderEventOverview,
        name: 'curriculum_assets_calendar_event',
        kate_permissions: ['manage_assets'],
        meta: {
          title: 'KATE - Calendar Event Overview',
        },
      },
      {
        path: 'quiz/:quizId/edit',
        component: EditQuiz,
        name: 'edit_quiz',
        kate_permissions: ['manage_assets'],
        meta: {
          title: 'KATE - Edit Quiz',
        },
      },
      {
        path: 'questionnaire/:questionnaireId/edit',
        component: EditQuestionnaire,
        name: 'edit_questionnaire',
        kate_permissions: ['manage_assets'],
        meta: {
          title: 'KATE - Edit Questionnaire',
        },
      },
      {
        path: 'meetings',
        component: MeetingOverview,
        name: 'curriculum_assets_meeting',
        kate_permissions: ['manage_assets'],
        meta: {
          title: 'KATE - Meeting Overview',
        },
      },
      {
        path: 'markdown_pages',
        component: MarkdownPageOverview,
        name: 'curriculum_assets_markdown_page',
        kate_permissions: ['manage_assets'],
        meta: {
          title: 'KATE - Markdown Page Overview',
        },
      },
      {
        path: 'asset_details/:assetType/:assetId',
        component: AssetDetails,
        name: 'curriculum_asset_details',
        kate_permissions: ['manage_assets', 'view_assets'],
        meta: {
          title: 'KATE - Asset Details',
        },
      },
    ],
  },

  // **** Programmes **** \\
  {
    path: '/programmes',
    component: DashboardLanding,
    name: 'programmes',
    children: [
      {
        path: '/programmes',
        component: ProgrammesOverview,
        name: 'programmes_overview',
        kate_permissions: ['manage_programmes'],
        meta: {
          title: 'KATE - Programmes Overview',
        },
        redirect: { name: 'programmes_overview_current_programmes' },
        children: [
          {
            path: '/programmes/current',
            component: CurrentProgrammes,
            name: 'programmes_overview_current_programmes',
            kate_permissions: ['manage_programmes'],
            meta: {
              title: 'KATE - Current Programmes',
            },
          },
          {
            path: '/programmes/create',
            component: CreateProgramme,
            name: 'programmes_overview_create_programme',
            kate_permissions: ['manage_programmes'],
            meta: {
              title: 'KATE - Create Programme',
            },
          },
        ],
      },
      // Manage a programme
      {
        path: '/programmes/:programmeId',
        component: ManageProgramme,
        name: 'manage_programme',
        kate_permissions: ['manage_programmes'],
        redirect: { name: 'manage_programme_members' },
        children: [
          {
            path: '/programmes/:programmeId/members',
            component: ManageProgrammeMembers,
            name: 'manage_programme_members',
            kate_permissions: ['manage_programmes'],
            meta: {
              title: 'KATE - Manage Programme Members',
            },
          },
          {
            path: '/programmes/:programmeId/content',
            component: ManageProgrammeContent,
            name: 'manage_programme_content',
            kate_permissions: ['manage_programmes'],
            meta: {
              title: 'KATE - Manage Programme Content',
            },
          },
          {
            path: '/programmes/:programmeId/achievements',
            component: AbstractManageProgrammeAchievements,
            name: 'manage_programme_achievements',
            meta: {
              title: 'KATE - Manage Programme Achievements',
            },
          },
          {
            path: '/programmes/:programmeId/competencies',
            component: ManageProgrammeCompetencies,
            name: 'manage_programme_competencies',
            meta: {
              title: 'KATE - Manage Programme Competencies',
            },
          },
        ],
      },
      // Manage a module in a programme
      {
        path: '/programmes/:programmeId/module/:moduleId',
        component: ManageModule,
        name: 'manage_module',
        kate_permissions: ['manage_programmes'],
        meta: {
          title: 'KATE - Manage Module',
        },
        redirect: { name: 'module_meta_manager' },
        children: [
          {
            path: '',
            component: ModuleMetaManager,
            name: 'module_meta_manager',
            meta: {
              title: 'KATE - Module Meta Manager',
            },
          },
          {
            path: 'chapters',
            component: ModuleChapterManager,
            name: 'module_chapter_manager',
            meta: {
              title: 'KATE - Module Chapter Manager',
            },
          },
          {
            path: 'assets/:assetType',
            component: ModuleAssetRelease,
            name: 'module_asset_release',
            meta: {
              title: 'KATE - Module Asset Release',
            },
          },
        ],
      },
    ],
  },

  // **** Users **** \\
  {
    path: '/users',
    component: DashboardLanding,
    name: 'users',
    children: [
      // Users
      {
        path: 'privileged_users',
        component: PrivilegedUsers,
        name: 'users_privileged_users',
        kate_permissions: ['manage_roles'],
        meta: {
          title: 'KATE - Privileged Users',
        },
      },
      {
        path: 'trainer',
        component: Mentors,
        name: 'users_trainer',
        kate_permissions: ['manage_trainers'],
        meta: {
          title: 'KATE - Trainer',
        },
      },
      {
        path: 'learner_support',
        component: LearnerSupport,
        name: 'learner_support',
        meta: {
          title: 'KATE - Learner Support',
        },
      },
    ],
  },

  // **** Badges **** \\
  {
    path: '/badges',
    component: DashboardLanding,
    name: 'badges',
    children: [
      {
        path: 'manage_achievements',
        component: Achievements,
        name: 'manage_achievements',
        kate_permissions: ['manage_achievements'],
        meta: {
          title: 'KATE - Manage Achievements',
        },
        redirect: { name: 'badges_overview' },
        children: [
          {
            path: '/manage_achievements/badges',
            component: BadgesOverview,
            name: 'badges_overview',
            meta: {
              title: 'KATE - Badges Overview',
            },
          },
          {
            path: '/manage_achievements/certificates',
            component: CertificatesOverview,
            name: 'certificates_overview',
            meta: {
              title: 'KATE - Certificates Overview',
            },
          },
        ],
      },
    ],
  },

  // **** Knowledge Base **** \\
  {
    path: '/',
    component: DashboardLanding,
    name: 'knowledge_base',
    children: [

      {
        path: 'knowledge_base',
        component: KnowledgeBase,
        name: 'knowledge_base_overview',
        kate_permissions: ['view_knowledge_base'],
        meta: {
          title: 'KATE - Knowledge Base Overview',
        },
      },
      {
        path: 'module/:moduleId/knowledge/thread/:threadId',
        component: ThreadPage,
        name: 'knowledge_thread',
        kate_permissions: ['view_knowledge_base'],
        meta: {
          title: 'KATE - Knowledge Base Thread',
        },
      },
      {
        path: 'knowledge/thread/:threadId',
        component: ThreadPage,
        name: 'knowledge_detached_thread',
        kate_permissions: ['view_knowledge_base'],
        meta: {
          title: 'KATE - Knowledge Base Detached Thread',
        },
      },
    ],
  },
  {
    path: '/change_password',
    component: ChangePasswordPage,
    name: 'change_password_page',
    kate_sidebar_disabled: true,
    kate_is_public: true,
    meta: {
      title: 'KATE - Change Password',
    },
  },
  {
    path: '/policy',
    component: PolicyAcceptPage,
    name: 'policy_accept_page',
    kate_sidebar_disabled: true,
    meta: {
      title: 'KATE - Policy Accept',
    },
  },
  {
    path: '/policy/:policyType',
    component: PolicyPage,
    name: 'policy_page',
    props: true,
    kate_sidebar_disabled: true,
    kate_is_public: true,
    meta: {
      title: 'KATE - Policy Page',
    },
  },
  {
    path: '/404',
    component: NotFound,
    name: '404',
    kate_sidebar_disabled: true,
    kate_is_public: true,
    meta: {
      title: 'KATE - 404',
    },
  },
  {
    path: '/403',
    component: Forbidden,
    name: '403',
    kate_sidebar_disabled: true,
    meta: {
      title: 'KATE - 403',
    },
  },
  {
    path: '/:pathMatch(.*)*', // everything else
    redirect: {
      name: '404',
    },
    meta: {
      title: 'KATE - 404',
    },
  },
];

export default routes;

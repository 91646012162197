<template>
  <div id="questionnaire-creation">
    <div class="questionnaire-details-form">
      <fieldset class="detail-form form-entry form-required">
        <label for="qre-name-input">Name</label>
        <input id="qre-name-input" class="form-control form-control" placeholder="Name" v-model="name">
      </fieldset>
      <fieldset class="detail-form form-entry">
        <word-tags v-model="tags" textInputPlaceholder="Add a tag"></word-tags>
      </fieldset>
      <fieldset class="detail-form form-entry form-required description">
        <label :for="`text-${$.uid}`">Description</label>
        <k-text-editor
          :id="`text-${$.uid}`"
          v-model="description"
          placeholder="Briefly describe the questionnaire">
        </k-text-editor>
      </fieldset>
    </div>
    <questionnaire-prompt-list v-model="prompts"></questionnaire-prompt-list>
    <div class="next-button">
      <button class="btn btn-success" @click="handleSubmit"
          :aria-label="editMode ? 'Update' : 'Create'" :title="editMode ? 'Update' : 'Create'"
          :disabled="!validQuestionnaire || creationInProgress">
          <i class="fa fa-spinner fa-spin" v-if="creationInProgress"></i>
          {{ editMode ? 'Update' : 'Create' }} Questionnaire
      </button>
    </div>
  </div>
</template>

<style>
textarea {
  width: 100%;
  resize: vertical;
  min-height: 30px;
}
</style>

<style scoped>
#questionnaire-creation {
  margin-top: 25px;
}

h2 > i.fas.fa-angle-down,
h2 > i.fas.fa-angle-up {
  float: right;
}

.questionnaire-details-form {
  gap: 15px;
  display: flex;
  flex-wrap: wrap;
}

.detail-form {
  min-width: 15em;
  flex: 1 1 40%;
}

.detail-form textarea {
  padding: 10px;
  width: 50vw;
  margin: 15px 0;
  max-height: 250px;
  min-height: 45px;
}

.detail-form label {
  display: block;
}

.next-button {
  text-align: right;
  flex: 1 1 100%;
}

@media only screen and (max-width: 920px) {
  .detail-form textarea {
    width: 100%;
  }
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import TimeMixin from '@mixins/time-mixins';
import KTextEditor from '@base-components/k-text-editor.vue';
import QuestionnairePromptList from './questionnaire-prompt-list.vue';
import WordTags from '../../../../components/word-tags.vue';

export default {
  components: {
    QuestionnairePromptList,
    WordTags,
    KTextEditor,
  },

  mixins: [ErrorMixin, TimeMixin],

  props: {
    questionnaire: {
      type: Object,
      required: false,
      default: undefined,
    },
  },

  mounted() {
    // If a questionnaire is provided establish it as the initial values in the component data
    if (this.questionnaire) {
      this.name = this.questionnaire.name;
      this.description = this.questionnaire.description;
      this.prompts = this.questionnaire.prompts;
      this.tags = this.questionnaire.tags || [];
    }
  },

  beforeUnmount() {
    // Component unmounted so all staged files will be removed
    this.$emit('files-staged', false);
  },

  data() {
    return {
      name: '',
      description: '',
      tags: [],
      prompts: [],
      creationInProgress: false,
    };
  },

  watch: {
    name(name) {
      if (this.questionnaire) {
        this.emitChanges({ name });
      }
    },
    description(description) {
      if (this.questionnaire) {
        this.emitChanges({ description });
      }
    },
    prompts(prompts) {
      if (this.questionnaire) {
        this.emitChanges({ prompts });
      }
    },
    tags(tags) {
      if (this.questionnaire) {
        this.emitChanges({ tags });
      }
    },
  },

  computed: {
    editMode() {
      return Boolean(this.questionnaire && this.questionnaire.id);
    },
    validQuestionnaire() {
      return Boolean(
        this.name
        && this.description
        && this.prompts.length > 0
        && this.prompts.every(this.validatePrompt)
        && (this.tags.every(tag => tag.length >= 2) || this.tags.length === 0),
      );
    },
  },

  methods: {
    emitChanges(updates) {
      this.$emit('update:questionnaire', {
        ...this.questionnaire,
        ...updates,
      });
    },
    validatePrompt(prompt) {
      // Every prompt must have some text
      return !(!prompt.prompt || prompt.prompt.length < 1 || !prompt.reviewType || !prompt.responseType);
    },
    getPayload() {
      return {
        name: this.name,
        description: this.description,
        tags: this.tags,
        prompts: this.prompts.map((p, i) => ({
          id: p.id || null,
          prompt: p.prompt,
          response_type: p.responseType,
          review_type: p.reviewType,
          answers: p.responseType === 'MULTIPLE_CHOICE' ? p.answers : null,
          number: i + 1,
          ai_feedback_enabled: p.enableAiFeedback || false,
          ai_feedback_context_prompt: p.aiFeedbackContextPrompt || '',
        })),
      };
    },
    createQuestionnaire() {
      this.$logger.info('Creating new questionnaire', undefined, true);
      this.creationInProgress = true;
      const payload = this.getPayload();
      this.$http.post('/api/curriculum/admin/questionnaire', payload).then(res => {
        this.$ktoast.success(`Created new questionnaire: ${res.data.questionnaire_id}`);
        this.$logger.info('Successfully created new questionnaire');
        this.$emit('update');
        this.resetForm();
      }).catch(err => {
        this.$logger.error('Error creating new questionnaire', undefined, err);
        this.showError(err);
      }).then(() => {
        this.creationInProgress = false;
      });
    },
    updateQuestionnaire() {
      this.$logger.info('Updating existing questionnaire', { questionnaireId: this.questionnaire.id }, true);
      this.creationInProgress = true;
      const payload = this.getPayload();
      this.$http.put(`/api/curriculum/admin/questionnaire/${this.questionnaire.id}`, payload).then(() => {
        this.$ktoast.success(`Updated questionnaire: '${this.name}'`);
        this.$logger.info('Successfully updated existing questionnaire', { questionnaireId: this.questionnaire.id });
        this.$emit('update-questionnaire');
      }).catch(err => {
        this.$logger.error('Error updating questionnaire', { questionnaireId: this.questionnaire.id, payload }, err);
        this.showError(err);
      }).then(() => {
        this.creationInProgress = false;
      });
    },
    resetForm() {
      this.name = '';
      this.description = '';
      this.prompts = [];
      this.tags = [];
    },
    handleSubmit() {
      if (this.editMode) {
        this.updateQuestionnaire();
      } else {
        this.createQuestionnaire();
      }
    },
  },
};
</script>

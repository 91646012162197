<template>
  <div>
    <div class="module-description" v-html="module.description"></div>
    <div class="release-module-asset-counts">
      <div class="module-meta" >
        <i v-if="!chaptersReady" class="fas fa-spinner fa-spin"></i>
        <div v-if="hasTags" class="tag-container">
          <span v-for="t in tags" class="badge">{{t}}</span>
        </div>
        <div v-if="showBadges && !inEditMode" class="badges">
          <div v-for="badge in badgesToDisplay">
            <badge-icon :removable="false"
                        :icon-image="badge.resource_link"
                        :badge-name="badge.name"
                        :threshold-score="badge.threshold_score"></badge-icon>
          </div>
          <div v-if="!badgesToDisplay?.length" class="badge-empty-placeholder">No badges in this module</div>
        </div>
        <k-asset-counts
          v-if="!inEditMode && chaptersReady"
          :assets="assets"
        ></k-asset-counts>
        <div v-if="showContentLink" class="pad-below">
          <router-link v-if="!inEditMode && !addedModule" :to="manageModuleRoute">
            Manage module content<i class="fas fa-external-link-alt"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.module-description {
  margin: 15px 0;
}

.release-module-asset-counts {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 1em;
}

.release-module-asset-counts > a {
  width: 100%;
}

.create-programme-module-controls-container {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.create-programme-module-controls-container .drag {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
}

.release-module-asset-counts > a i.fas,
button > i.fas {
  padding-left: 5px;
}

.tag-container {
  padding-bottom: 20px;
}

.badges {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 10px;
  align-items: center;
}

.badge-empty-placeholder {
  font-style: italic;
}

.pad-below {
  padding-bottom: 1em;
}
</style>
<script>
import KAssetCounts from '@base-components/k-asset-counts.vue';
import useReleaseModuleStore from '@stores/release-module-store';
import BadgeIcon from '../../../achievements/badge-icon.vue';
import { MODULE_TYPES } from '../../../../constants';

export default {
  components: {
    BadgeIcon,
    KAssetCounts,
  },
  props: {
    module: {
      type: Object,
      default: () => {},
    },
    targetModuleType: {
      type: String,
      required: true,
    },
    chaptersReady: {
      type: Boolean,
      default: false,
    },
    tags: {
      type: Array,
      default: () => [],
    },
    showContentLink: {
      type: Boolean,
      default: false,
    },
    assets: {
      type: Array,
      default: () => [],
    },
    addedModule: {
      type: Boolean,
      default: false,
    },
    inEditMode: {
      type: Boolean,
      default: false,
    },
    showBadges: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      releaseModuleStore: useReleaseModuleStore(),
    };
  },
  computed: {
    hasTags() {
      return Boolean(this.tags && this.tags.length > 0);
    },
    badgesToDisplay() {
      return this.releaseModuleStore.getBadgesToDisplay(this.module.index);
    },
    manageModuleRoute() {
      const params = {};
      const moduleTypeMeta = MODULE_TYPES[this.targetModuleType];
      params[moduleTypeMeta.idField] = this.module.id;
      return {
        name: `manage_${this.targetModuleType}`,
        params,
      };
    },
  },
};
</script>

<template>
  <div class="copy-modules-container">
    <div class="header">
      <k-panel class="instructions">
        <template #header>
          <h3>Instructions</h3>
        </template>
        <template #body>
          <p v-if="ready">Select a Programme or Programme Blueprint to copy from, or create an empty module</p>
          <p v-else><i class="fas fa-spinner fa-spin"/> Fetching programme data...</p>
          <div class="programme-buttons">
            <button class="btn btn-primary" @click="openProgrammesModal('programme')" :disabled="!ready">
              Select from Programme
              <i :class="programmeTypes['programme'].icon"></i>
            </button>
            <button class="btn btn-tertiary" @click="openProgrammesModal('programme_blueprint')" :disabled="!ready">
              Select from Programme Blueprint
              <i :class="programmeTypes['programme_blueprint'].icon"></i>
            </button>
            <button class="btn btn-outlined" @click="addEmptyModule" :disabled="!ready">
              Add Empty Module <i class="fas fa-plus-circle add-icon"></i>
            </button>
          </div>
        </template>
      </k-panel>
      <k-panel v-if="selectedProgramme" class="selected-programme">
        <template #header>
          <h3>
            <i :class="programmeTypes[selectedProgrammeType].icon"></i>
            {{ programmeTypes[selectedProgrammeType].name }}
          </h3>
          <h2>{{ selectedProgramme.name }}</h2>
        </template>
        <template #body>
          <p>{{ selectedProgramme.product }}</p>
          <div v-html="selectedProgramme.description"></div>
          <word-tags-list v-model="selectedProgramme.tags" :interactive="false"></word-tags-list>
        </template>
      </k-panel>
    </div>
    <k-panel class="modules">
      <template #header>
        <h3>
          Modules
          <div class="legend">
            <div>
              <i class="fas fa-duotone fa-graduation-cap"></i> Curriculum
            </div>
            <div>
              <i class="fas fa-duotone fa-flag-swallowtail"></i> Milestone
            </div>
          </div>
        </h3>
        <p>
          Once a programme or blueprint has been selected, select modules from the left panel and add them
          to the curriculum or milestone tracks
        </p>
      </template>
      <template #body>
        <splitpanes class="custom-theme modal-theme">
          <pane min-size="10" max-size="100" :size="20" class="left-pane">
            <k-text-search class="filter-input" v-model="modFilterText"></k-text-search>
            <ul v-if="modulesReady">
              <li class="list-items" v-for="mod in filteredAndSortedModules" :key="mod.id">
                <item-selection-button
                  :name="mod.name"
                  :tags="mod.tags"
                  :custom-icon="mod.track === 'curriculum' ? 'fa-graduation-cap' : 'fa-flag-swallowtail'"
                  :cssClass="mod.track"
                  @click="addNewModule(mod)">
                ></item-selection-button>
              </li>
              <li class="scroll-buffer"></li>
            </ul>
            <div v-else>
              <i class="fas fa-spinner fa-spin"/>
            </div>
          </pane>
          <pane min-size="50" max-size="90" :size="80" class="right-pane">
            <k-editable-table v-model="modulesToAdd"
              :headers="moduleHeaders"
              :row-class-func="getRowClass"
              :max="6"
            ></k-editable-table>
            <button class="btn btn-success add-modules"
              :disabled="disableAddModulesButton"
              @click="addModules">
              Add Modules
            </button>
          </pane>
        </splitpanes>
      </template>
    </k-panel>
    <k-modal :show="showProgrammesModal" @close="closeProgrammesModal">
      <template #header>
        <h3>Select a programme to copy modules from</h3>
      </template>
      <template #body>
        <k-table
          :headers="programmeHeaders"
          :rows="programmeRows"
          :hideable="false"
          :panel="false"
          @clicked="programmeTableCallback"
        ></k-table>
      </template>
      <template #footer>
        <button class="btn btn-default cancel-btn" @click="closeProgrammesModal">Close</button>
      </template>
    </k-modal>
  </div>
</template>

<style>
.copy-modules-container .splitpanes.custom-theme .splitpanes__splitter {
  margin: 0 10px;
  height: unset;
}

.copy-modules-container li.list-items .item {
  width: 100%;
  padding: 5px;
  display: block;
  margin: 8px auto 0;
  border-radius: 3px;
  background-color: var(--kate-primary-dark);
}

.copy-modules-container li.list-items .add-icon {
  color: var(--kate-success) !important;
}

.copy-modules-container li.list-items .item:hover {
  background-color: var(--kate-panel-alt);
}

.copy-modules-container .k-search-dropdown-menu .btn-primary {
  padding: 5px 12px;
}

.copy-modules-container .k-edit-table th:nth-child(3) {
  max-width: 45px;
}

.copy-modules-container .k-editable-table-row td:nth-child(3) {
  text-align: center;
}

.copy-modules-container .k-editable-table-row.curriculum i.fa-graduation-cap {
  color: var(--kate-success) !important;
}

.copy-modules-container .k-editable-table-row.milestone i.fa-flag-swallowtail {
  color: var(--kate-primary) !important;
}

.item.curriculum .item-button-content > div:first-child i {
  color: var(--kate-success) !important;
}

.item.milestone .item-button-content > div:first-child i {
  color: var(--kate-primary) !important;
}
</style>

<style scoped>
.programme-buttons {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.header {
  display: flex;
  flex-direction: row;
}

.header > .panel:first-child {
  width: 35%;
  margin-right: 2%;
}

.header > .panel:nth-child(2) {
  width: 63%;
}

.copy-modules-container h3 {
  margin-bottom: 8px;
}

.copy-modules-container h2 {
  margin-top: 10px;
}

.copy-modules-container ul {
  padding: 0 !important;
}

.copy-modules-container .k-text-search.filter-input {
  width: 100%;
  margin: 8px auto 0;
  margin-top: 0;
}

.copy-modules-container .k-editable-table-container {
  height: 550px;
  overflow-x: auto;
}

.left-pane ul {
  overflow-y: auto;
  height: 540px;

  --mask:
    linear-gradient(
      to bottom,
      rgb(0 0 0 / 1) 0,
      rgb(0 0 0 / 1) 80%,
      rgb(0 0 0 / 0) 98%,
      rgb(0 0 0 / 0) 0
    ) 100% 50% / 100% 100% repeat-x;

  mask: var(--mask);
}

.right-pane button {
  margin: 5px;
  float: right;
}

.badges-checkbox {
  margin: 10px;
}

.legend {
  display: inline-flex;
  float: right;
  gap: 20px;
}

.legend div:first-child > i {
  color: var(--kate-success);
}

.legend div:last-child > i {
  color: var(--kate-primary);
}

.scroll-buffer {
  height: 80px;
}
</style>

<script>
import { Splitpanes, Pane } from 'splitpanes';
import KTextSearch from '@base-components/k-text-search.vue';
import KModal from '@base-components/k-modal.vue';
import KTable from '@base-components/k-table.vue';
import KPanel from '@base-components/k-panel.vue';
import ErrorMixin from '@mixins/error-mixins';
import TimeMixin from '@mixins/time-mixins';
import ModuleMixin from '@mixins/module-mixin';
import KEditableTable from '@base-components/k-editable-table.vue';
import { sortObjectArray } from '../../../modules/sort-by-object-property';
import WordTagsList from '../../components/word-tags-list.vue';
import ItemSelectionButton from '../../components/k-item-selection-button.vue';
import { PROGRAMME_TYPES } from '../../../constants';

export default {
  name: 'abstract-copy-modules',
  components: {
    KEditableTable,
    KTable,
    KModal,
    KPanel,
    WordTagsList,
    ItemSelectionButton,
    KTextSearch,
    Splitpanes,
    Pane,
  },

  mixins: [ErrorMixin, TimeMixin, ModuleMixin],

  beforeMount() {
    this.getProgrammes();
    this.getProgrammeBlueprints();
  },

  props: {
    targetProgrammeType: {
      type: String,
      required: true,
    },
    programmeStartDate: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      programmesReady: false,
      programmesBlueprintsReady: false,
      programmes: [],
      programmeBlueprints: [],
      abstractProgrammes: [],
      programmeTypes: PROGRAMME_TYPES,
      selectedProgrammeType: undefined,
      selectedProgramme: undefined,
      showProgrammesModal: false,
      modulesReady: true,
      modules: [],
      sourceModuleType: null,
      modFilterText: '',
      modulesToAdd: [],
      copyBadges: false,
    };
  },

  computed: {
    ready() {
      return this.programmesReady && this.programmesBlueprintsReady;
    },
    filteredAndSortedModules() {
      return sortObjectArray(sortObjectArray(
        this.modules.filter(mod => mod.name.toLowerCase().includes(this.modFilterText.toLowerCase())),
        'number',
      ), 'track');
    },
    moduleHeaders() {
      return [
        {
          key: 'name',
          title: 'Name',
          required: true,
          filterable: false,
          sortable: false,
          editable: this.editableCallback,
          type: 'string',
          sortBy: x => x.name,
        },
        {
          key: 'truncatedDescription',
          title: 'Description',
          required: true,
          filterable: false,
          sortable: false,
          sortBy: x => x.description,
          type: 'html',
          html: desc => desc,
        },
        {
          key: 'track',
          title: '',
          type: 'html',
          required: false,
          filterable: false,
          sortable: false,
          editable: false,
          html: this.itemDisplay,
        },
        {
          key: 'track',
          title: 'Track',
          type: 'dropdown',
          required: true,
          filterable: false,
          sortable: false,
          rowOptions: this.modulesToAdd.map(() => ['milestone', 'curriculum']),
        },
        {
          key: 'tags',
          title: 'Tags',
          type: 'html',
          required: false,
          filterable: false,
          editable: false,
          sortable: false,
          html: this.tagsDisplay,
        },
        {
          key: 'remove',
          title: 'Remove',
          type: 'action',
          editable: false,
          sortable: false,
          filterable: false,
          callback: this.handleRemove,
          html: '<button class="btn-remove" aria-label="Remove" title="Remove"><i class="fa fa-trash-alt" aria-hidden="true"></i></button>',
        },
      ];
    },
    programmeRows() {
      return this.abstractProgrammes.map((val, index) => ({
        name: {
          text: val.name,
          key: index,
        },
        product: val.product,
        tags: {
          tags: val.tags || [],
          sortBy: val.tags ? [...val.tags].sort().join(' ') : '',
        },
      }));
    },
    programmeHeaders() {
      return {
        name: {
          name: 'Name',
          filterable: true,
          type: 'action',
        },
        product: {
          name: 'Product',
          filterable: true,
        },
        tags: {
          name: 'Tags',
          type: 'tags',
          filterable: true,
        },
      };
    },
    targetModuleType() {
      return this.programmeTypes[this.targetProgrammeType].child;
    },
    disableAddModulesButton() {
      return !this.modulesToAdd.length || this.modulesToAdd.some(mod => !mod.name);
    },
  },

  methods: {
    getProgrammes() {
      this.programmesReady = false;
      this.$http.get('/api/curriculum/programmes').then(resp => {
        this.programmes = resp.data.programmes;
      }).catch(err => {
        this.showError(err);
        this.$logger.autowarn('Could not retrieve programmes', err);
      }).then(() => {
        this.programmesReady = true;
      });
    },
    getProgrammeBlueprints() {
      this.programmesBlueprintsReady = false;
      this.$http.get('/api/curriculum/blueprints/programmes').then(resp => {
        this.programmeBlueprints = resp.data.blueprints;
      }).catch(err => {
        this.showError(err);
        this.$logger.autowarn('Could not retrieve programme blueprints', err);
      }).then(() => {
        this.programmesBlueprintsReady = true;
      });
    },
    getModules(programmeId) {
      this.modulesReady = false;
      this.$http.get(`/api/curriculum/${this.sourceModuleType === 'module' ? 'admin/' : 'blueprints/'}programmes/${programmeId}/modules`).then(resp => {
        this.modules = resp.data[`${this.sourceModuleType}s`].map(mod => ({
          ...mod,
          icon: mod.track,
        }));
      }).catch(err => {
        this.showError(err);
        this.$logger.autowarn(`Could not retrieve ${this.sourceModuleType}`, err);
      }).then(() => {
        this.modulesReady = true;
      });
    },
    openProgrammesModal(selectedProgrammeType) {
      this.selectedProgrammeType = selectedProgrammeType;
      this.showProgrammesModal = true;
      if (selectedProgrammeType === 'programme') {
        this.abstractProgrammes = this.programmes;
      } else if (selectedProgrammeType === 'programme_blueprint') {
        this.abstractProgrammes = this.programmeBlueprints;
      }
    },
    closeProgrammesModal() {
      this.showProgrammesModal = false;
    },
    programmeTableCallback(key, index) {
      this.selectedProgramme = this.abstractProgrammes[index];
      this.sourceModuleType = this.programmeTypes[this.selectedProgrammeType].child;
      this.getModules(this.abstractProgrammes[index].id);
      this.closeProgrammesModal();
    },
    moduleTableCallback(key, index) {
      this.modulesToAdd.splice(index, 1);
    },
    addEmptyModule() {
      this.modulesToAdd.push({
        name: 'Empty Module',
        description: 'Module description placeholder',
        truncatedDescription: 'Module description placeholder',
        targetModuleType: this.targetModuleType,
        sourceModuleType: null,
        track: 'curriculum',
        // FEATURE-TODO: When release-module.vue is refactored this can be updated also so
        // that no distinction required for badges depending on module types
        badges_in_module: [], // module
        newBadges: [], // programme_blueprint_module
        tags: [],
      });
    },
    addNewModule(mod) {
      this.modulesToAdd.push({
        ...mod,
        ...this.getModuleDaysAndDates(mod),
        targetModuleType: this.targetModuleType,
        sourceModuleType: this.sourceModuleType,
        copyBadges: this.copyBadges,
        badgeCopies: mod.badges_in_module || [], // Need to distinguish between to copied badges and the extra one that could get added
        badges_in_module: [],
        truncatedDescription: this.getTruncatedModuleDescription(mod),
      });
    },
    addModules() {
      this.$emit('add-modules', this.modulesToAdd);
      this.$ktoast.success(`Added ${this.modulesToAdd.length} modules. Return to the track tabs to check them before saving changes`);
      this.modulesToAdd = [];
    },
    itemDisplay(icon) {
      return `<i class="fa-duotone fa-solid ${icon === 'curriculum' ? 'fa-graduation-cap' : 'fa-flag-swallowtail'}"></i>`;
    },
    tagsDisplay(tags) {
      let tagHtml = '';
      if (tags && tags.length > 0) {
        for (let i = 0; i < tags.length; i++) {
          const tagList = `<span class="badge">${tags[i]}</span>\n`;
          tagHtml += tagList;
        }
      }
      return tagHtml;
    },
    handleRemove(row) {
      this.modulesToAdd = this.modulesToAdd.filter(a => !(a.id === row.id));
    },
    getRowClass(row) {
      return row.track;
    },
    addDaysToCurrentDate(days) {
      if (!days) {
        return null;
      }
      const initialDate = this.programmeStartDate ? this.getDate(this.programmeStartDate) : new Date();
      return this.formatDate(this.addToDate(initialDate, { days }));
    },
    getModuleDaysAndDates(module) {
      if (this.targetProgrammeType === 'programme') {
        if (this.sourceModuleType === 'module') {
          return { // module copied to programme
            expected_completion_date: null,
            release_date: null,
            release_after_days: undefined,
            expected_completion_days: undefined,
          };
        }
        return { // programme blueprint module copied to programme
          expected_completion_date: this.addDaysToCurrentDate(module.release_after_days + module.expected_completion_days),
          release_date: this.addDaysToCurrentDate(module.release_after_days),
          release_after_days: undefined,
          expected_completion_days: undefined,
        };
      }
      if (this.sourceModuleType === 'module') {
        return { // module copied to programme blueprint
          expected_completion_date: undefined,
          release_date: undefined,
          release_after_days: null,
          expected_completion_days: null,
        };
      }
      return { // programme blueprint module copied to programme blueprint
        expected_completion_date: undefined,
        release_date: undefined,
        release_after_days: module.release_after_days,
        expected_completion_days: module.expected_completion_days,
      };
    },
    editableCallback(row) {
      return !row.sourceModuleType;
    },
  },
};
</script>

<template>
  <div v-if="programmeAgnosticReady" id="engagement-overview">
    <h1>Engagement Overview</h1>
    <div class="panel">
      <div class="panel-body">
        <div class="programme-header">
          <div class="programme-selection">
            <h2>{{ currentProgrammeName || 'Programme' }}</h2>
            <button class="btn btn-primary change-programme" @click="openChangeProg">Change Programme</button>
          </div>
        </div>
        <template v-if="programmeSpecificReady">
          <engagement-learners
            :key="`e-learn-${programmeIdFromRoute}`"
            :programme-id="currentProgramme"
            :learners="learners"
            :activity="learnersActivity">
          </engagement-learners>
          <engagement-content
            :key="`e-content-${programmeIdFromRoute}`"
            :programme-id="currentProgramme"
            :paks="paks"
            :lus="lus"
          ></engagement-content>
          <engagement-knowledge-base
            :key="`e-knowledge-base-${programmeIdFromRoute}`"
            :threads="programmeThread"
            :knowledge-activity="knowledgeActivity">
          </engagement-knowledge-base>
        </template>
      </div>
      <change-programme
        v-if="programmes"
        v-model="currentProgramme"
        :programmes="programmes"
        @close="closeChangeProg"
        :show="showChangeProg">
      </change-programme>
    </div>
  </div>
</template>

<style scoped>
/* Programme header */
.programme-header,
.programme-selection {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.programme-header {
  margin: 20px 0;
}

.programme-selection h2 {
  margin: 0;
}
</style>

<style>
#engagement-overview .panel-body .section-content {
  display: flex;
  flex-wrap: wrap;
  row-gap: 1rem;
}

#engagement-overview .panel-body .section-content.row {
  margin: 15px -15px;
}

#engagement-overview .section-header hr {
  border-color: var(--kate-background-body-alpha);
  margin-bottom: 15px;
}

#engagement-overview .section-item,
#engagement-overview .section-item > div {
  display: flex;
  gap: 1rem;
  flex-wrap: nowrap;
}

#engagement-overview .section-item {
  min-width: 210px;
}

#engagement-overview .section-item > div {
  flex-direction: column;
  gap: 0;
}

#engagement-overview .item-stats {
  font-size: 1.8em;
  color: var(--kate-type-light);
  font-weight: bold;
}

#engagement-overview .section-icon {
  align-self: center;
}

#engagement-overview .section-icon span {
  height: 75px;
  width: 75px;
  border-radius: 50%;
  text-align: center;
  position: relative;
  background-color: var(--kate-button-tertiary);
  color: var(--kate-type-light);
}

#engagement-overview .section-icon i {
  position: absolute;
  left: 0;
  bottom: 50%;
  right: 0;
  font-size: 25px;
  transform: translateY(50%);
}

#engagement-overview .section-icon .total-posts,
#engagement-overview .section-icon .ticket-icon,
#engagement-overview .section-icon .total-learners,
#engagement-overview .section-icon .active-learners,
#engagement-overview .section-icon .inactive-learners {
  color: var(--kate-type-light);
}

#engagement-overview .section-icon .total-learners {
  background-color: var(--kate-warning);
}

#engagement-overview .section-icon .active-learners {
  background-color: var(--kate-success);
}

#engagement-overview .section-icon .inactive-learners,
#engagement-overview .section-icon .ticket-icon {
  background-color: var(--kate-danger);
}

#engagement-overview .section-icon .video-icon {
  background-color: var(--kate-video);
}

#engagement-overview .section-icon .pak-icon {
  background-color: var(--kate-assignment);
}

#engagement-overview .section-icon .lu-icon {
  background-color: var(--kate-lu);
}

#engagement-overview .section-icon .quiz-icon {
  background-color: var(--kate-quiz);
}

#engagement-overview .section-icon .total-posts {
  background-color: var(--kate-logo-purple);
}

.section-stats button {
  margin-top: 10px;
}

.section-stats .expand-content {
  margin: 0;
  background-color: transparent;
  text-decoration: underline;
  padding: 10px 0;
  text-align: left;
  color: var(--kate-primary);
}

.section-stats .expand-content:hover.disabled,
.section-stats .expand-content.disabled {
  cursor: unset;
  color: var(--kate-type-primary);
  text-decoration: none;
}

.section-stats .expand-content:hover {
  color: var(--kate-secondary);
}

.section-stats .expand-content i {
  margin-left: 5px;
  transition: transform 0.1s ease-in-out;
}

.section-stats .expand-content.active {
  color: var(--kate-secondary);
}

.section-stats .expand-content.active i {
  transform: rotate(180deg);
}

</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import ChangeProgramme from '../../components/change-programme.vue';
import EngagementLearners from './engagement-learners.vue';
import EngagementContent from './engagement-content.vue';
import EngagementKnowledgeBase from './engagement-knowledge-base.vue';

export default {
  components: {
    ChangeProgramme,
    EngagementLearners,
    EngagementContent,
    EngagementKnowledgeBase,
  },

  mixins: [ErrorMixin],

  data() {
    return {
      currentProgramme: undefined,
      programmes: [],
      programmesReady: false,
      showChangeProg: false,
      learners: [],
      learnersReady: true,
      videos: [],
      videosReady: false,
      paks: [],
      paksReady: true,
      quizzes: [],
      quizzesReady: false,
      lus: [],
      lusReady: true,
      learnersActivity: [],
      learnersActivityReady: true,
      threads: [],
      threadsReady: false,
      knowledgeActivity: [],
      knowledgeActivityReady: true,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getProgrammeAgnosticData();
    if (!this.programmeIdFromRoute) {
      this.showChangeProg = true;
    } else {
      this.currentProgramme = this.programmeIdFromRoute;
    }
  },

  watch: {
    programmeSpecificReady() {
      if (this.programmeSpecificReady) {
        this.$Loading.finish();
      } else {
        this.$Loading.minimal();
      }
    },

    programmeAgnosticReady() {
      if (this.programmeAgnosticReady && this.programmeSpecificReady) {
        this.$Loading.finish();
      } else {
        this.$Loading.start();
      }
    },

    currentProgramme() {
      if (this.currentProgramme !== this.programmeIdFromRoute) {
        this.$router.replace({ name: 'monitor_engagement_overview', params: { programmeId: this.currentProgramme } });
      }
      this.getProgrammeSpecificData();
    },
  },

  computed: {
    programmeAgnosticReady() {
      return this.videosReady
        && this.threadsReady
        && this.quizzesReady
        && this.programmesReady;
    },

    programmeSpecificReady() {
      return this.learnersReady
        && this.paksReady
        && this.lusReady
        && this.learnersActivityReady
        && this.knowledgeActivityReady;
    },

    programmeIdFromRoute() {
      if (this.$route.params.programmeId === undefined || Number.isNaN(Number(this.$route.params.programmeId))) {
        return undefined;
      }
      return Number(this.$route.params.programmeId);
    },

    currentProgrammeName() {
      if (!this.currentProgramme || this.programmes.length < 1) {
        return undefined;
      }
      return this.programmes.find(x => x.id === this.currentProgramme).name;
    },

    programmeVideos() {
      if (!this.videos) {
        return [];
      }
      return this.videos;
    },

    programmeQuizzes() {
      if (!this.quizzes) {
        return [];
      }
      return this.quizzes;
    },

    programmeThread() {
      if (!this.threads) {
        return [];
      }
      return this.threads.filter(x => x.programme_id === this.currentProgramme);
    },
  },

  methods: {
    getProgrammeAgnosticData() {
      this.getProgrammes();
      this.getVideos();
      this.getThreads();
      this.getQuizzes();
    },

    getProgrammeSpecificData() {
      this.getLearnersData();
      this.getPaks();
      this.getLus();
      this.getLearnersActivity();
      this.getKnowledgeBaseActivity();
    },

    getProgrammes() {
      this.$logger.info('Getting all available programmes');
      this.$http.get('/api/curriculum/programmes?dashboard=true').then(res => {
        this.programmes = res.data.programmes;
        this.$logger.info('Got available programmes');
      }).catch(err => {
        if (err.response.status !== 404) {
          this.$logger.error('Error getting available programmes', { programmeId: this.currentProgramme }, err);
        } else {
          this.$logger.warn('No programmes found');
        }
      }).then(() => {
        this.programmesReady = true;
      });
    },

    getLearnersData() {
      this.learners = [];
      this.learnersReady = false;
      this.$logger.info('Getting programme learners', { programmeId: this.currentProgramme }, true);
      this.$http.get(`/api/stats/programmes/${this.currentProgramme}/scores`).then(res => {
        this.$logger.info('Got programme learners', { programmeId: this.currentProgramme });
        this.learners = res.data;
      }).catch(err => {
        if (err.response.status !== 404) {
          this.$logger.error('Error getting programme learners', { programmeId: this.currentProgramme }, err);
        } else {
          this.$logger.warn('No learners found');
        }
      }).then(() => {
        this.learnersReady = true;
      });
    },

    getPaks() {
      this.paks = [];
      this.paksReady = false;
      this.$logger.info('Getting PAKs for programme', { programmeId: this.currentProgramme }, true);
      this.$http.get(`/api/curriculum/programmes/${this.currentProgramme}/pak`).then(result => {
        this.$logger.info('Got PAKs for programme', { programmeId: this.currentProgramme });
        this.paks = result.data.paks;
      }).catch(err => {
        if (err.response.status !== 404) {
          this.$logger.error('Error getting paks', { programmeId: this.currentProgramme }, err);
        } else {
          this.$logger.warn('No paks found');
        }
      }).then(() => {
        this.paksReady = true;
      });
    },

    getLus() {
      this.lus = [];
      this.lusReady = false;
      this.$logger.info('Getting lus for programme', { programmeId: this.currentProgramme }, true);
      this.$http.get(`/api/curriculum/programmes/${this.currentProgramme}/learning_unit`).then(result => {
        this.$logger.info('Got lus for programme', { programmeId: this.currentProgramme });
        this.lus = result.data.lus;
      }).catch(err => {
        if (err.response.status !== 404) {
          this.$logger.error('Error lus for programme', { programmeId: this.currentProgramme }, err);
        } else {
          this.$logger.warn('No lus found');
        }
      }).then(() => {
        this.lusReady = true;
      });
    },

    getLearnersActivity() {
      this.learnersActivity = [];
      this.learnersActivityReady = false;
      this.$logger.info('Getting learners activity for programme', { programmeId: this.currentProgramme }, true);
      this.$http.get(`/api/stats/programmes/${this.currentProgramme}/activity`).then(result => {
        this.$logger.info('Got learners activity for programme', { programmeId: this.currentProgramme });
        this.learnersActivity = Object.freeze(result.data);
      }).catch(err => {
        if (err.response.status !== 404) {
          this.$logger.error('Error getting learners activity for programme', { programmeId: this.currentProgramme }, err);
        } else {
          this.$logger.warn('No learners activity found');
        }
      }).then(() => {
        this.learnersActivityReady = true;
      });
    },

    getThreads() {
      // Not tied to programme
      this.threadsReady = false;
      this.$http.get('/api/curriculum/knowledge/threads?is_public=false').then(result => {
        this.$logger.info('Got threads for programme', { programmeId: this.currentProgramme });
        this.threads = result.data.threads;
      }).catch(err => {
        if (err.response.status !== 404) {
          this.$logger.error('Error getting threads for programme', { programmeId: this.currentProgramme }, err);
        } else {
          this.$logger.warn('No threads found');
        }
      }).then(() => {
        this.threadsReady = true;
      });
    },

    getQuizzes() {
      // Not tied to programme
      this.quizzesReady = false;
      this.$logger.info('Getting quizzes for programme', { programmeId: this.currentProgramme }, true);
      this.$http.get('/api/curriculum/admin/quiz').then(result => {
        this.$logger.info('Got quizzes for programme', { programmeId: this.currentProgramme });
        this.quizzes = result.data.quizzes;
      }).catch(err => {
        if (err.response.status !== 404) {
          this.$logger.error('Error quizzes for programme', undefined, err);
        } else {
          this.$logger.warn('No quizzes found');
        }
      }).then(() => {
        this.quizzesReady = true;
      });
    },

    getVideos() {
      // Not tied to programme
      this.$logger.info('Getting videos for programme', { programmeId: this.currentProgramme }, true);
      this.videosReady = false;
      this.$http.get('/api/curriculum/admin/video').then(result => {
        this.$logger.info('Got videos');
        this.videos = result.data.videos;
      }).catch(err => {
        if (err.response.status !== 404) {
          this.$logger.error('Error getting videos', { programmeId: this.currentProgramme }, err);
        } else {
          this.$logger.warn('No videos found');
        }
      }).then(() => {
        this.videosReady = true;
      });
    },

    getKnowledgeBaseActivity() {
      this.knowledgeActivity = [];
      this.knowledgeActivityReady = false;
      this.$logger.info('Getting knowledge base activity for programme', { programmeId: this.currentProgramme }, true);
      this.$http.get(`/api/curriculum/programmes/${this.currentProgramme}/knowledge/activity`).then(result => {
        this.$logger.info('Got knowledge base activity for programme', { programmeId: this.currentProgramme });
        this.knowledgeActivity = result.data.activity;
      }).catch(err => {
        if (err.response.status !== 404) {
          this.$logger.error('Error getting knowledge base activity for programme', { programmeId: this.currentProgramme }, err);
        } else {
          this.$logger.warn('No knowledge base activity found');
        }
      }).then(() => {
        this.knowledgeActivityReady = true;
      });
    },

    openChangeProg() {
      this.showChangeProg = true;
    },

    closeChangeProg() {
      this.showChangeProg = false;
    },
  },
};
</script>

<template>
  <div id="add-user-form">
    <div class="add-user-details">
      <div class="add-users-individual-form col-md-6 col-xs-12">
        <fieldset class="form-programme form-entry">
          <label for="add-users-name-input">Name</label>
          <input id="add-users-name-input" type="text" class="form-control" placeholder="Type name here..." v-model="formName">
        </fieldset>
        <fieldset class="form-programme form-entry">
          <label for="add-users-email-input">Email</label>
          <input id="add-users-email-input" type="text" class="form-control" placeholder="Type email address here..." v-model="formEmail">
        </fieldset>
        <fieldset v-if="programmeHasGroups" class="form-programme form-entry">
          <label for="tag-input">Groups</label>
          <span class="question-icon">
            <k-tooltip :text="'Students will only be able to access module assets that have no groups or are in the same group(s) as the student.'">
              <sup><i class="fas fa-question-circle"></i></sup>
            </k-tooltip>
          </span>
          <div v-if="programme.programme_groups && programme.programme_groups.length > 0">
            <word-tags label='' v-model="formGroups" textInputPlaceholder="Type tag here..." :options="programme.programme_groups"></word-tags>
          </div>
        </fieldset>
        <fieldset class="form-programme form-entry">
          <label for="add-domain-input">Domain</label>
          <span class="question-icon">
            <k-tooltip
              :text="`This is the domain the students sign in link will redirect to. For example, the domain <b>'imc'</b> would point to imc.edukate.ai. The default is app.edukate.ai.`"
            >
              <sup><i class="fas fa-question-circle"></i></sup>
            </k-tooltip>
          </span>
          <k-dropdown id="add-domain-input" type="text" placeholder="Domain" :options="domainOptions" v-model="formDomain" :showSearch="false"></k-dropdown>
        </fieldset>
        <fieldset class="form-programme form-entry">
          <label for="add-company-input">Company</label>
          <span class="question-icon">
            <k-tooltip
              :text="`This is the company the student belongs to or is affiliated with.`"
            >
              <sup><i class="fas fa-question-circle"></i></sup>
            </k-tooltip>
          </span>
          <k-dropdown id="add-company-input" type="text" placeholder="Company" :options="employerOptions" v-model="formEmployer" :showSearch="false"></k-dropdown>
        </fieldset>
        <div class="form-buttons">
          <button class="btn btn-primary" id="add-user-individual-button" @click="addUser" :disabled="!validForm">
            <i class="fas fa-user-plus"></i> Add User
          </button>
          <button class="btn btn-danger" id="remove-user-remove-button" @click="openClearWarning" :disabled="users.length === 0">
            <i class="fas fa-times-circle"></i> Clear All
          </button>
        </div>
      </div>
    <div class="add-users-dropfield-container col-md-6 col-xs-12">
      <dropfield class="users-dropfield"
        v-on:file="eventGetFile"
        v-bind:customMessage="customMessage"
        url=""
        accept=".csv"
        v-bind:immediatePost="false">
      </dropfield>
    </div>
    </div>
    <div class="add-user-error" v-if="errors.length > 0">
      <div class="col-md-12 col-xs-12">
        <div class="k-alert-danger">
          <strong> Errors found: </strong>
          <ul>
            <li v-for="err in errors" class="error-entry-li">
              {{err}}
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div v-if="refinedUsers.length" class="add-user-table" id="k-add-user-table-container">
      <k-table :headers="kableHeaders" :rows="refinedUsers" :max="10" :hideable="false" :panel="false" @clicked="removeUser"></k-table>
      <button id="k-user-add-review-button" class="btn btn-primary" @click="goToReview" :disabled="!readyForReview">Review Users <i class="fas fa-arrow-right"></i></button>
    </div>
    <k-modal :show="showClearWarning" @close="closeClearWarning">
      <template #header>
        <h3>Clear All Users?</h3>
      </template>
      <template #body>
        This will remove all users currently loaded in the form. Are you sure you want to remove them?
      </template>
      <template #footer>
        <button class="btn btn-danger" @click="clearUsers"><i class="fas fa-times-circle"></i> Clear All Users</button>
        <button class="btn btn-primary" @click="closeClearWarning"><i class="fas fa-arrow-left"></i> Go Back</button>
      </template>
    </k-modal>
  </div>
</template>

<style>
#k-add-user-table-container .k-table-container {
  margin-bottom: 20px;
  padding-bottom: 20px;
  border-bottom: 2px solid var(--kate-background-body-alpha);
}

#add-user-form .users-dropfield {
  width: 100%;
  height: 100%;
  padding-top: 25px;
}

#add-user-form .users-dropfield > form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

#add-user-form .k-dropbox {
  flex: 1;
}
</style>

<style scoped>
.add-user-details {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 30px;
}

.add-users-individual-form {
  flex: 1;
}

.add-users-dropfield-container {
  flex: 1;
}

label {
  margin-bottom: 0;
}

#k-add-user-table-container {
  display: inline-block;
  width: 100%;
}

#k-user-add-review-button {
  float: right;
}

.form-programme {
  margin: 10px 0;
}

.form-buttons {
  display: flex;
  justify-content: space-between;
  margin-top: 25px;
}
</style>

<script>
import Dropfield from '@base-components/dropfield.vue';
import TimeMixin from '@mixins/time-mixins';
import ErrorMixin from '@mixins/error-mixins';
import CsvMixin from '@mixins/csv-mixins';
import KTable from '@base-components/k-table.vue';
import KModal from '@base-components/k-modal.vue';
import KDropdown from '@base-components/k-dropdown.vue';
import KTooltip from '@base-components/k-tooltip.vue';
import WordTags from '../../components/word-tags.vue';

const EMAIL_REGEX = /.+@.+\..+/;
const DOMAINS = [
  { name: 'Default', domain: undefined, example: 'app.edukate.ai' },
  { name: 'IMC', domain: 'imc', example: 'imc.edukate.ai' },
];

export default {
  props: {
    programme: {
      type: Object,
    },
    baseUsers: {
      type: Array,
      default: () => [],
    },
  },
  mixins: [TimeMixin, CsvMixin, ErrorMixin],
  components: {
    WordTags,
    dropfield: Dropfield,
    'k-table': KTable,
    'k-modal': KModal,
    'k-dropdown': KDropdown,
    KTooltip,
  },
  data() {
    return {
      users: [],
      showClearWarning: false,
      formEmail: '',
      formName: '',
      formGroups: [],
      formDomain: undefined,
      formEmployer: undefined,
      employers: [],
      uploadedFile: null,
      customMessage: `Drag csv file with the following format:
      <div style="text-align: left;margin-left: 0.5em">
      <br>
        <div>name, email, groups (optional), domain (optional), company (optional)</div>
        <div>John Smith, john.smith@fakemail.com, Group A</div>
        <div>Jane Doe, john.smith@fakemail.com, "Group A, Group B"</div>
        <div>Donna Noble, donna.noble@fakemail.com, ,imc</div>
      </div>
      <br>
      Note that groups are case-sensitive
      `,
      sendEmails: false,
      showChangeProgDialog: false,
      errors: [],
    };
  },
  beforeMount() {
    this.users = this.baseUsers;
    this.getEmployers();
  },
  computed: {
    readyForReview() {
      return this.users.length > 0 && this.programme !== undefined;
    },
    refinedUsers() {
      return this.users.map((val, index) => ({
        action: {
          key: index,
          text: '<div style="text-align: center; color: var(--kate-danger)"><i class="fas fa-minus-circle"></i></div>',
        },
        ...val,
        groups: {
          key: index,
          tags: val.groups,
        },
      }));
    },
    kableHeaders() {
      const headers = {
        name: {
          name: 'Name',
          filterable: true,
        },
        email: {
          name: 'Email',
          filterable: true,
        },
        groups: {
          name: 'Groups',
          filterable: true,
          type: 'tags',
        },
        domainName: {
          name: 'Domain',
          filterable: true,
        },
        employerName: {
          name: 'Company',
          filterable: true,
        },
        action: {
          name: 'Review User',
          type: 'action',
        },
      };
      if (!this.programmeHasGroups) {
        delete headers.groups;
      }
      return headers;
    },
    programmeHasGroups() {
      return Boolean(this.programme?.programme_groups && this.programme?.programme_groups.length > 0);
    },
    validEmail() {
      return this.validateEmail(this.formEmail);
    },
    validName() {
      return this.validateName(this.formName);
    },
    validForm() {
      return this.validEmail && this.validName;
    },
    domainOptions() {
      return DOMAINS.map(x => x.domain || 'default');
    },
    selectedDomain() {
      const dom = DOMAINS.find(x => x.domain === this.formDomain);
      return dom ? dom.domain : undefined;
    },
    selectedDomainName() {
      const dom = DOMAINS.find(x => x.domain === this.formDomain);
      return dom ? dom.name : 'Default';
    },
    employerOptions() {
      return this.employers.map(x => x.name);
    },
    selectedEmployerId() {
      const dom = this.employers.find(x => x.name === this.formEmployer);
      return dom ? dom.id : undefined;
    },
    selectedEmployer() {
      const dom = this.employers.find(x => x.name === this.formEmployer);
      return dom ? dom.name : undefined;
    },
  },
  methods: {
    checkEmailInUsers(email) {
      return this.users.filter(user => user.email === email).length > 0;
    },
    goToReview() {
      if (!this.readyForReview) {
        return;
      }
      this.$emit('useradded', this.users);
    },
    openClearWarning() {
      this.showClearWarning = true;
    },
    closeClearWarning() {
      this.showClearWarning = false;
    },
    removeUser(index) {
      this.users.splice(index, 1);
    },
    clearUsers() {
      this.users = [];
      this.closeClearWarning();
    },
    addUser() {
      if (!this.validForm) {
        return;
      }
      if (this.checkEmailInUsers(this.formEmail)) {
        this.showError('Email already in form');
        return;
      }
      this.users.push({
        name: this.formName.trim(),
        email: this.formEmail.trim(),
        groups: this.formGroups,
        domainName: this.selectedDomainName,
        domain: this.selectedDomain,
        employerName: this.selectedEmployer,
        employer_id: this.selectedEmployerId,
      });
      this.formName = '';
      this.formEmail = '';
      this.formGroups = [];
      this.formDomain = undefined;
      this.formEmployer = undefined;
    },
    validateRegex(name, regex) {
      return regex.test(name);
    },
    validateEmail(name) {
      return this.validateRegex(name, EMAIL_REGEX);
    },
    validateName(name) {
      return name.trim().length > 0;
    },
    validateGroups(groups) {
      let allValid = true;
      groups.forEach(group => {
        if (!this.programme?.programme_groups.map(g => g.name).includes(group)) {
          allValid = false;
        }
      });
      return allValid;
    },
    validateFields(fields) {
      const requiredFields = ['name', 'email'];
      const optionalFields = ['domain', 'company', 'groups'];
      const allRequired = requiredFields.every(x => fields.includes(x));
      const otherFields = fields.filter((x, idx, arr) => arr.indexOf(x) === idx && optionalFields.includes(x));
      return allRequired && (fields.length === (otherFields.length + requiredFields.length));
    },
    eventGetFile(file) {
      this.errors = [];
      this.uploadedFile = file;
      this.parseCsvFile(file[0], results => {
        if (results.errors.length) {
          this.errors.push('Error parsing file. Is the file a csv file?');
          return;
        }
        if (!this.validateFields(results.meta.fields)) {
          this.errors.push('Incorrect headers. Need either ("name", "email") plus any of the optional columns ("domain", "company", "groups") only');
          return;
        }
        if (!this.programme?.programme_groups && results.meta.fields.includes('groups')) {
          this.errors.push('Groups column not allowed for this programme');
          return;
        }
        const emails = [];
        let hasErrors = false;
        let datum;
        const resultDomains = results.data.map(x => DOMAINS.find(y => y.domain === x.domain));
        const resultEmployers = results.data.map(x => this.employers.find(y => y.name === x.company));
        for (let i = 0; i < results.data.length; i++) {
          datum = results.data[i];
          datum.email = datum.email.trim();
          datum.name = datum.name.trim();
          datum.groups = datum.groups && datum.groups.length > 0 ? datum.groups.split(',').map(g => g.trim()) : [];
          if (!this.validateEmail(datum.email)) {
            this.errors.push(this.formatError('Error validating email', datum.name, datum.email, i));
            hasErrors = true;
          }
          if (!this.validateName(datum.name)) {
            this.errors.push(this.formatError('Error validating name', datum.name, datum.email, i));
            hasErrors = true;
          }
          if (!this.validateGroups(datum.groups)) {
            this.errors.push(this.formatError('Group(s) not in the programme', datum.name, datum.email, i));
            hasErrors = true;
          }
          if (emails.indexOf(datum.email) !== -1 || this.checkEmailInUsers(datum.email)) {
            this.errors.push(this.formatError('Duplicate Email', datum.name, datum.email, i));
            hasErrors = true;
          }
          datum.domain = resultDomains[i] ? resultDomains[i].domain : undefined;
          datum.domainName = resultDomains[i] ? resultDomains[i].name : 'Default';
          datum.employer_id = resultEmployers[i] ? resultEmployers[i].id : undefined;
          datum.employerName = resultEmployers[i] ? resultEmployers[i].name : undefined;
          emails.push(datum.email);
        }
        if (!hasErrors) {
          this.users = this.users.concat(results.data);
        }
      });
    },
    formatError(msg, name, email, row) {
      return `Row ${row}:  ${msg} | ${name}, ${email}`;
    },
    getEmployers() {
      this.$http.get('/api/profile/employers').then(res => {
        this.employers = res.data;
      }).catch(err => {
        this.$logger.autowarn('There was an error while attempting to retrieve employers in add user', undefined, err);
        if (!this.$http.isWarning(err)) {
          this.showError(err);
        }
      });
    },
  },
};
</script>

<template>
  <div class="note-container">
    <div class="note-header">
      <div class="note-details">
        <div class="author-info">
          <h3 v-if="userLink">
            <router-link :to="{ name: 'dashboard_students', params: { studentId: note.user_id }, }">
              {{ note.name }}
            </router-link>
          </h3>
          <h3 v-else>{{ note.name }}</h3>
          <span class="note-badge success" v-if="note.is_complete">Completed</span>
          <i>{{ parseTimestamp(note.date_timestamp) }}</i>
        </div>
        <span class="module">Module: <em>{{ note.module_name }}</em></span>
      </div>
      <div class="note-actions">
        <i class="fas" :class="iconClass"></i>
      </div>
    </div>
    <div class="note-content">
      <p v-if="note.comment !== ''"  v-html="highlightedContent"></p>
      <p v-else class="no-comment"><i>No comment submitted.</i></p>
    </div>
  </div>
</template>

<style>
.notes-overview .notes-list .note-container:nth-child(odd) {
  background-color: var(--kate-note);
}

.notes-overview .notes-list .note-container:nth-child(odd)::before {
  border-right-color: var(--kate-note);
}

.notes-overview .confirm-discard-modal .modal-container,
.notes-overview .note-container .modal-container {
  min-width: unset;
}

.notes-overview .confirm-discard-modal .modal-container {
  border-left: 12px solid var(--kate-warning);
}

.notes-overview .note-container .modal-container {
  border-left: 12px solid var(--kate-danger-alt);
}

.note-container .modal-container .note-modal-body p b {
  color: var(--kate-type-light);
}

.note-container .note-content pre {
  white-space: normal;
  word-break: break-word;
}
</style>
<style scoped>
.note-container {
  background: var(--kate-note-alt);
  padding: 1.2em;
  margin: 0 15px 15px;
  border-radius: 4px;
  position: relative;
  box-shadow: var(--box-shadow);
}

.note-container:nth-child(odd) {
  background-color: var(--kate-note);
}

.note-container:nth-child(odd)::before {
  border-right-color: var(--kate-note);
}

.note-container::before {
  right: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-right-color: var(--kate-note-alt);
  border-width: 10px;
  top: 10px;
}

.note-details .author-info,
.note-actions,
.note-details,
.note-header {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
}

.note-header {
  margin-bottom: 15px;
}

.note-actions {
  justify-content: end;
  align-items: baseline;
}

.note-actions button i {
  text-shadow: 2px 2px 0 var(--kate-type-dark);
}

.note-header i.fa-thumbs-down,
.note-header .btn-trash i {
  color: var(--kate-danger-alt);
}

.note-header i.fa-thumbs-up {
  color: var(--kate-type-success);
}

.note-header .btn-trash:hover i {
  color: var(--kate-danger);
}

.note-details,
.note-details .author-info {
  row-gap: 0;
}

.note-details {
  flex: 10;
}

.note-details .author-info {
  justify-content: flex-start;
}

.note-details .author-info ~ span {
  width: 100%;
}

.note-content p {
  color: var(--kate-type-light);
}

span.note-badge.success {
  font-size: 0.85em;
  background-color: var(--kate-success);
  color: var(--kate-type-dark);
  padding: 2px 8px;
  border-radius: 50px;
}

p.no-comment {
  color: var(--kate-type-warning);
}
</style>

<script>
import TimeMixin from '@mixins/time-mixins';

export default {
  props: {
    note: {
      type: Object,
      required: true,
    },
    filterText: {
      type: String,
      default: '',
    },
    userLink: {
      type: Boolean,
      default: false,
    },
  },

  mixins: [TimeMixin],

  computed: {
    highlightedContent() {
      if (!this.filterText) {
        return this.note.comment;
      }
      return this.note.comment.replace(new RegExp(this.filterText, 'gi'), y => `<span class="highlighted-text">${y}</span>`);
    },

    iconClass() {
      if (this.note.rating === null) {
        return 'fa-thumbs-up neutral';
      }
      if (this.note.rating === false) {
        return 'fa-thumbs-down';
      }
      return 'fa-thumbs-up';
    },
  },
};
</script>

<template>
  <div class="sign-in-link">
    <k-tooltip :text="'This will generate a temporary sign-in link for learners that are not be able to access their KATE accounts'">
      <button class="btn btn-primary generate-link" @click="generateSignInLink">Sign-in link</button>
    </k-tooltip>
    <k-modal @close="close" :show="show">
      <template v-slot:header>
        <h3>Generated Sign-in Link</h3>
      </template>
      <template v-slot:body>
        <p>It is important that you <b>securely</b> share this link with only <b>{{ studentName }}</b> (ID {{ studentId }}).</p>
        <p>Either copy the link from the text area or click the "Copy to Clipboard" button below</p>
        <label for="sign-in-link-text">Sign-in link:</label>
        <textarea id="sign-in-link-text" v-model="signInLink" rows="4"></textarea>
      </template>
      <template v-slot:footer>
        <button class="btn btn-primary copy-to-clipboard" @click="copySignInLink">Copy to Clipboard</button>
        <button class="btn btn-danger close-modal" @click="close">Close</button>
      </template>
    </k-modal>
  </div>
</template>

<style scoped>
.sign-in-link p.info {
  font-size: 12px;
  font-style: italic;
  font-weight: 300;
  color: var(--kate-type-accent);
}
</style>

<script>
import KTooltip from '@base-components/k-tooltip.vue';
import KModal from '@base-components/k-modal.vue';
import ErrorMixin from '@mixins/error-mixins';
import { copyToClipboard } from '../../../modules/copy-to-clipboard';

export default {
  components: { KModal, KTooltip },
  mixins: [ErrorMixin],
  props: {
    studentId: {
      type: Number,
      required: true,
    },
    studentName: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      signInLink: null,
    };
  },
  computed: {
    show() {
      return Boolean(this.signInLink);
    },
  },
  methods: {
    generateSignInLink() {
      this.$Loading.start();
      this.$http.post(`/api/auth/user/${this.studentId}/app/login-url`).then(res => {
        this.$logger.info('Generated sign-in link', { studentId: this.studentId });
        this.signInLink = res.data.url;
      }).catch(err => {
        this.$logger.error('Error generating sign-in link', undefined, err);
        this.showError(err);
      }).then(() => {
        this.$Loading.finish();
      });
    },
    copySignInLink() {
      copyToClipboard(this.signInLink);
      this.$ktoast.success('Sign-in link copied to clipboard');
    },
    close() {
      this.signInLink = null;
    },
  },
};
</script>

<template>
  <div>
    <div class="create-programme-module-card panel">
      <div class="create-programme-module-details">
        <div class="create-programme-module-number">
          {{ number }}
        </div>
        <div class="create-programme-text-container">
          <module-card
            :module="module"
            :isModule="isModule"
            :released="released"
            :targetModuleType="targetModuleType"
            :isUncommitted="isNewlyAdded"
            :allowEdit="allowEdit"
            :inEditMode="inEditMode"
            :chaptersReady="chaptersReady"
            :showContentLink="showContentLink"
            :addedModule="addedModule"
            :tags="tags"
            :showBadges="true"
            :assets="assets"
            @toggleEditMode="toggleEditMode"
            @revertPendingChanges="revertPendingChanges"
        />
            <!-- FEATURE-TODO: turn this into a nice way of displaying the source and target module types -->
            <!-- as this is useful information when adding modules (do not display for existing modules or empty modules) -->
            <!-- <p>{{ sourceModuleType }} -> {{ targetModuleType }}</p> -->
        </div>
      </div>
      <div class="create-programme-module-controls-container">
        <div class="create-programme-module-emit-buttons">
          <k-tooltip v-if="allowRemove" :text="removeModuleTooltip">
            <button class="btn" @click="handleRemove" :disabled="!canDeleteModule" aria-label="Remove" title="Remove">
              <i class="fas fa-trash-alt"></i>
            </button>
          </k-tooltip>
          <k-tooltip v-if="showAdd" :text="addToolTipText">
            <button class="btn" @click="$emit('add')" :disabled="disableAdd" aria-label="Add" title="Add">
              <i class="fas fa-plus-circle"></i>
            </button>
          </k-tooltip>
        </div>
        <div v-if="draggable" class="drag">
          <i class="fas fa-grip-vertical"></i>
        </div>
      </div>
    </div>
    <div v-if="track" class="create-programme-module-track">
      <input type="radio" id="curriculum" value="curriculum" v-model="trackVal"/>
      <label for="curriculum">Curriculum</label>
      <input type="radio" id="milestone" value="milestone" v-model="trackVal"/>
      <label for="milestone">Milestones</label>
    </div>
  </div>
</template>

<style>
.create-programme-module-card.k-module-item.sortable-chosen.ghost .drag,
.create-programme-module-card.k-module-item.sortable-chosen.ghost {
  cursor: grabbing;
}

.create-programme-module-card.k-module-item.sortable-chosen.ghost {
  outline: 3px solid var(--kate-primary);
}
</style>

<style scoped>
.title-edit {
  resize: horizontal;
  white-space: nowrap;
  overflow: hidden;
  max-width: 450px;
  min-width: 250px;
  display: block;
}

textarea {
  border-radius: 4px;
  padding: 5px;
}

.create-programme-module-details {
  cursor: pointer;
  display: grid;
  grid-template-columns: 1fr 20fr;
  gap: 15px;
}

.create-programme-module-details.disabled {
  pointer-events: none;
}

.create-programme-module-card {
  display: grid;
  grid-template-columns: 20fr 1fr;
  background-color: var(--kate-panel-alt);
  min-height: 7em;
  border-radius: 10px 4px 4px 10px;
  transition: all 0.3s;
}

.create-programme-module-card:hover,
.create-programme-module-card:hover .create-programme-module-number {
  background-color: var(--kate-background-alt-alpha);
}

.create-programme-module-card:hover {
  box-shadow: var(--box-shadow);
}

.create-programme-module-card:active {
  background-color: var(--kate-background-alt-alpha);
  outline: 3px solid var(--kate-primary);
  box-shadow: var(--box-shadow);
}

.create-programme-module-card.disabled:hover {
  background-color: var(--kate-panel-alt);
}

.create-programme-text-container {
  flex-direction: column;
  display: flex;
  gap: 15px;
}

.create-programme-module-emit-buttons {
  display: flex;
  gap: 10px;
  padding: 6px;
  justify-content: space-between;
  align-items: center;
}

.create-programme-module-emit-buttons button {
  padding: 15px;
}

.create-programme-module-emit-buttons .btn {
  background-color: transparent;
  border: none;
}

.create-programme-module-emit-buttons .btn i {
  font-size: 1.3em;
}

.create-programme-module-emit-buttons .btn .fa-plus-circle {
  color: var(--kate-success);
}

.create-programme-module-emit-buttons .btn .fa-plus-circle:hover {
  color: var(--kate-success-light);
}

.create-programme-module-number {
  font-size: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--kate-primary-dark);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  color: var(--kate-type-light);
  width: 60px;
}

button.release,
button.unrelease,
button.withdraw {
  margin-right: 5px;
  display: inline;
  width: fit-content;
  align-self: flex-start;
}

.create-programme-module-controls-container {
  display: grid;
  grid-template-rows: 1fr 1fr 1fr;
}

.create-programme-module-controls-container .drag {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: grab;
}

.create-programme-module-track {
  margin-bottom: 2em;
}

.disabled-link {
  pointer-events: none;
  cursor: default;
  color: var(--kate-disabled);
}

@media (max-width: 767px) {
  .module-card-content {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
  }
}
</style>

<script>
import TimeMixin from '@mixins/time-mixins';
import ChaptersMixin from '@mixins/chapters-mixin';
import ModuleMixin from '@mixins/module-mixin';
import KTooltip from '@base-components/k-tooltip.vue';
import useModuleChapterStore from '@stores/module-chapter-store';
import useReleaseModuleStore from '@stores/release-module-store';
import { MODULE_TYPES } from '../../../constants';
import ModuleCard from './module_card/module-card.vue';

export default {
  components: {
    KTooltip,
    ModuleCard,
  },

  mixins: [TimeMixin, ChaptersMixin, ModuleMixin],

  props: {
    id: Number,
    module: {
      type: Object,
      default: () => ({}),
    },
    sourceModuleType: {
      type: String,
      default: 'module',
      validator: value => Object.keys(MODULE_TYPES).includes(value),
    },
    targetModuleType: {
      type: String,
      default: 'module',
      validator: value => Object.keys(MODULE_TYPES).includes(value),
    },
    tags: Array,
    number: Number,
    isNewlyAdded: Boolean,
    released: Boolean,
    draggable: {
      type: Boolean,
      default: false,
    },
    allowRemove: {
      type: Boolean,
      default: false,
    },
    showAdd: {
      type: Boolean,
      default: false,
    },
    allowEdit: {
      type: Boolean,
      default: false,
    },
    disableAdd: {
      type: Boolean,
      default: false,
    },
    track: {
      type: String,
      default: undefined,
    },
    showBadges: {
      type: Boolean,
      default: false,
    },
    showContentLink: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      inEditMode: false,
      confirmRemoveModuleToast: undefined,
      chaptersReady: false,
      trackVal: this.track,
      moduleChapterStore: useModuleChapterStore(),
      releaseModuleStore: useReleaseModuleStore(),
    };
  },

  beforeMount() {
    this.getChapters();
  },

  watch: {
    module() {
      this.trackVal = this.module.trackVal;
    },
    trackVal() {
      this.$emit('update-track', this.trackVal);
    },
  },

  computed: {
    isModule() {
      return this.targetModuleType === 'module';
    },
    chapters() {
      return this.moduleChapterStore.getChaptersByModule(this.id);
    },
    addedModule() {
      return Boolean(this.module.addedModule);
    },
    addToolTipText() {
      if (this.disableAdd) {
        return 'This module is already used this programme blueprint';
      }
      return 'Add module blueprint';
    },
    removeModuleTooltip() {
      if (!this.isModule) {
        return 'Remove module blueprint';
      }
      if (this.canDeleteModule) {
        return 'Remove this module from the programme';
      }
      return 'This module cannot be removed due to the risk of data loss - it has associated saves / submissions';
    },
    canDeleteModule() {
      if (!this.isModule) {
        return true;
      }
      return !this.chapters.flatMap(c => c.assets.map(a => (a.asset_type === 'learning_unit' ? a.has_saves : a.has_submissions))).includes(true);
    },
  },

  methods: {
    revertPendingChanges(moduleIndex) {
      this.releaseModuleStore.resetModuleChanges(moduleIndex);
      this.$emit('editMode', false);
      this.inEditMode = false;
    },
    toggleEditMode(moduleIndex) {
      if (!this.allowEdit) {
        return;
      }
      if (this.inEditMode && this.releaseModuleStore.hasModuleChanges(moduleIndex)) {
        this.$emit('update', this.releaseModuleStore.activeModules[moduleIndex].updates);
      }

      this.$emit('editMode', !this.inEditMode);
      this.inEditMode = !this.inEditMode;
    },
    cancelUpdate() {
      this.$emit('cancel');
    },
    async getChapters() {
      if (this.id) {
        this.$logger.info('Getting chapters');
        this.chaptersReady = false;
        await this.moduleChapterStore.fetchChapters(this.id, this.sourceModuleType);
        this.chaptersReady = true;
      } else {
        this.$logger.info('Module ID not provided');
        this.chaptersReady = true;
      }
    },
    handleRemove() {
      // change to isModule
      if (this.moduleType === 'programme_blueprint_module') {
        this.$emit('remove');
      } else {
        this.confirmModuleRemoval();
      }
    },
    confirmCallback() {
      this.$emit('remove');
    },
    confirmModuleRemoval() {
      this.$ktoast.confirmToast(
        'This module has no associated student saves or submissions and can be removed.',
        'warning',
        () => this.confirmCallback(),
      );
    },
  },
};
</script>

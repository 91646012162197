<template>
  <div class="filters-container">
    <button aria-label="Filters toggle" title="Filters toggle" @click="toggleFilter" class="filters-toggle" :class="showFilters ? '' : 'toggled-filter'">
      <i class="fas fa-angle-double-left"></i>
    </button>
    <div class="panel filters" :class="showFilters ? '' : 'toggled'">
      <k-text-search v-if="showSearch" v-model="filterText"></k-text-search>
      <ul>
        <!-- n starts at 1  -->
        <li v-for="(n, index) in refinedFiltersToShow" :key="filteredOptions[index].id">
          <label class="k-custom-checkbox">
            <input type="checkbox" id="roles" class="hidden-checkbox" :value="filteredOptions[index].name" v-model="selectedFilters">
            <span class="k-custom-checkbox-box light"></span>
            <span class="k-custom-checkbox-text">{{ filteredOptions[index].name }}</span>
          </label>
        </li>
      </ul>
      <div class="showmore-container">
        <button class="showmore-btn" aria-label="Show less" title="Show less" v-if="refinedFiltersToShow > 10" @click="decrementFiltersToShow">
          Show less
        </button>
        <button class="showmore-btn" aria-label="Show more" title="Show more" v-if="refinedFiltersToShow < filteredOptions.length"
          @click="incrementFiltersToShow">
          Show more
        </button>
      </div>
    </div>

  </div>
</template>

<style scoped>
.filters-container {
  display: flex;
  gap: 25px;
  position: relative;
}

.filters-toggle {
  cursor: pointer;
  display: flex;
  position: absolute;
  left: 235px;
  top: 0;
  z-index: 1;
  padding: 5px;
  color: var(--kate-type-dark);
  background-color: var(--kate-primary);
  border-radius: 4px;
  transform: translateX(0);
}

.filters-toggle i {
  transform: rotate(0);
}

.filters-toggle.toggled-filter {
  transform: translateX(-240px);
}

.filters-toggle.toggled-filter i {
  transform: rotate(180deg);
}

.k-search {
  color: var(--kate-type-dark);
}

.filters {
  background-color: var(--kate-panel-alt);
  min-width: 250px;
  width: 250px;
  max-width: 30vw;
  height: fit-content;
  padding: 15px;
  z-index: 0;
  opacity: 1;
  transition: width 0.2s ease-in-out, opacity 0.2s ease-in;
}

.filters.toggled {
  width: 0;
  padding: 0;
  opacity: 0;
  overflow: hidden;
  min-width: 0;
  white-space: nowrap;
  transition: width 0.2s ease-in-out, opacity 0.2s ease-in;
}

.filters ul {
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.filters ul li {
  color: var(--kate-type-light);
}

.k-custom-checkbox {
  padding: 5px;
}

input.hidden-checkbox:checked ~ .k-custom-checkbox-text {
  color: var(--kate-secondary);
}

.showmore-container {
  display: flex;
  justify-content: space-between;
}

.showmore-btn {
  display: block;
  text-align: center;
  text-decoration: underline;
  background-color: transparent;
  margin: 10px auto 0;
  padding: 5px;
  color: var(--kate-primary);
}

.showmore-btn:hover {
  color: var(--kate-secondary);
}
</style>

<script>
import KTextSearch from '@base-components/k-text-search.vue';
import stringMatch from '../../modules/string-match';

export default {
  components: {
    KTextSearch,
  },

  props: {
    modelValue: {
      type: Array,
      default: () => [],
    },
    selectedData: Array,
    showSearch: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      filtersToShow: 10,
      showFilters: true,
      selectedFilters: this.modelValue,
      filterText: '',
    };
  },

  watch: {
    modelValue() {
      this.selectedFilters = this.modelValue;
    },
    selectedFilters() {
      this.$emit('update:modelValue', this.selectedFilters);
    },
  },

  computed: {

    refinedFiltersToShow() {
      return Math.min(this.filtersToShow, this.filteredOptions.length);
    },

    filteredOptions() {
      if (!this.filterText) {
        return this.selectedData;
      }
      return this.selectedData.filter(x => stringMatch(x.name, this.filterText) || stringMatch(x.key, this.filterText));
    },
  },

  methods: {
    incrementFiltersToShow() {
      this.filtersToShow = Math.min(this.selectedData.length, this.filtersToShow + 5);
    },

    decrementFiltersToShow() {
      this.filtersToShow = Math.max(10, this.filtersToShow - 5);
    },

    toggleFilter() {
      this.showFilters = !this.showFilters;
    },
  },
};
</script>

<template>
  <k-panel :hasContentToggle="false">
    <template #body>
      <div class="manage-module-blueprint">
        <manage-programme-blueprint-modules
          v-model="programmeBlueprintModules"
          module-type="programme_blueprint_module"
          :is-blueprint="true"
          :draggable="true"
          :disable-commit="!validName"
          @reset="reset"
          @save-changes="updateProgrammeBlueprintModules"
          @remove="removeProgrammeBlueprintModule"
          @add="addProgrammeBlueprintModule"
          @release-update="makeProgrammeBlueprintModuleUpdate"
          @cancel="cancelProgrammeBlueprintModuleUpdate"
        >
          <template #instructions>
            <p>
              Below is a list of modules for the <b>{{ programmeBlueprintName }}</b> programme blueprint.
              Drag the modules to re-order them and click on a module to edit its details.
            </p>
            <p>Note that no changes (reordering, removing) will be reflected on <b>EDUKATE</b> until you save your changes via the <i>Save Changes</i> button when you're ready to update the blueprint.</p>
          </template>
        </manage-programme-blueprint-modules>
      </div>
    </template>
  </k-panel>
</template>

<style scoped>
.manage-module-blueprint {
  padding-bottom: 20px;
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import PageReadyMixin from '@mixins/page-ready-mixin';
import KPanel from '@base-components/k-panel.vue';
import useReleaseModuleStore from '@stores/release-module-store';
import AbstractManageModules from '../../programmes/modules/abstract-manage-modules.vue';
import { sortObjectArray } from '../../../modules/sort-by-object-property';
import copyObject from '../../../modules/copy-object';

export default {
  components: {
    KPanel,
    'manage-programme-blueprint-modules': AbstractManageModules,
  },

  mixins: [ErrorMixin, PageReadyMixin],

  props: {
    programmeBlueprint: {
      type: Object,
    },
    programmeBlueprintReady: {
      type: Boolean,
    },
  },

  data() {
    return {
      programmeBlueprintModules: [],
      originalProgrammeBlueprintModules: [],
      programmeBlueprintName: '',
      programmeBlueprintDescription: '',
      programmeBlueprintTags: [],
      programmeBlueprintProductId: undefined,
      programmeBlueprintIncludeInReport: false,
      productsReady: false,
      products: [],
      selectedCertificate: undefined,
      showProgrammeBlueprintModuleModal: false,
      modalOpen: false,
      updatingMeta: false,
      updatingProgrammeBlueprintModules: false,
      programmeBlueprintModuleToDelete: [],
      releaseModulesStore: useReleaseModuleStore(),
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.loadProgrammeBlueprintData();
    this.getProducts();
  },

  watch: {
    programmeBlueprint: {
      handler() {
        this.loadProgrammeBlueprintData();
      },
      deep: true,
    },
    programmeBlueprintModules: {
      handler() {
        for (let i = 0; i < this.programmeBlueprintModules.length; i += 1) {
          this.programmeBlueprintModules[i].index = i;
        }
        this.releaseModulesStore.setActiveModules(this.programmeBlueprintModules);
        this.releaseModulesStore.updateOriginalModulesOrder();
      },
      deep: true,
    },
    originalProgrammeBlueprintModules: {
      handler() {
        for (let i = 0; i < this.originalProgrammeBlueprintModules.length; i += 1) {
          this.originalProgrammeBlueprintModules[i].index = i;
        }
        this.releaseModulesStore.setOriginalModules(this.originalProgrammeBlueprintModules);
      },
      deep: true,
    },
  },

  computed: {
    updating() {
      return this.updatingMeta && this.updatingProgrammeBlueprintModules;
    },
    ready() {
      return this.programmeBlueprintReady && this.productsReady && !this.updating;
    },
    programmeBlueprintId() {
      return parseInt(this.$route.params.programmeBlueprintId, 10);
    },
    validName() {
      return Boolean(this.programmeBlueprintName.trim().length > 0 && this.programmeBlueprintName.trim().length < 256);
    },
    productName() {
      const prodIndex = this.products.findIndex(x => x.id === this.programmeBlueprintProductId);
      if (prodIndex < 0) {
        return null;
      }
      return this.products[prodIndex].name;
    },
  },

  methods: {
    loadProgrammeBlueprintData() {
      if (!this.programmeBlueprint) {
        return;
      }
      // Copies data from the blueprint to component data which can be used for update
      const val = copyObject(this.programmeBlueprint);
      this.programmeBlueprintName = val.name;
      this.programmeBlueprintDescription = val.description;
      this.programmeBlueprintTags = val.tags || [];
      this.programmeBlueprintProductId = val.product_id;
      this.programmeBlueprintIncludeInReport = val.include_in_reporting;
      this.programmeBlueprintModules = sortObjectArray(this.appendBadgesToBlueprintModules(
        val.programme_blueprint_modules.map(x => ({ programmeBlueprintBadges: val.badges, ...x })),
      ), 'number');
      this.originalProgrammeBlueprintModules = JSON.parse(
        JSON.stringify(sortObjectArray(this.appendBadgesToBlueprintModules(
          val.programme_blueprint_modules.map(x => ({ programmeBlueprintBadges: val.badges, ...x })),
        ), 'number')),
      );
      this.releaseModulesStore.setOriginalModules(this.originalProgrammeBlueprintModules);
      this.releaseModulesStore.setActiveModules(this.programmeBlueprintModules);
      this.selectedBadges = val.badges || [];
      this.selectedCertificate = val.certificate ? [val.certificate] : undefined;
    },
    reset() {
      this.releaseModulesStore.clearModules();
      this.programmeBlueprintModuleToDelete = [];
      this.programmeBlueprintModules = JSON.parse(JSON.stringify(this.originalProgrammeBlueprintModules));
      this.programmeBlueprintName = this.programmeBlueprint.name;
      this.programmeBlueprintDescription = this.programmeBlueprint.description;
      this.programmeBlueprintProductId = this.programmeBlueprint.product_id;
      this.programmeBlueprintIncludeInReport = this.programmeBlueprint.include_in_reporting;
      this.releaseModulesStore.setActiveModules(this.programmeBlueprintModules);
      this.releaseModulesStore.setOriginalModules(this.originalProgrammeBlueprintModules);
      this.editDescription = false;
    },
    appendBadgesToBlueprintModules(programmeBlueprintModules) {
      return programmeBlueprintModules.map(mod => ({
        badges_in_module: this.programmeBlueprint.badges.filter(
          b => b.programme_blueprint_modules?.map(mb => mb.id)?.includes(mod.id),
        ) || [],
        ...mod,
      }));
    },
    removeProgrammeBlueprintModule(index) {
      const removedModule = this.programmeBlueprintModules.splice(index, 1)[0];
      this.programmeBlueprintModuleToDelete.push(removedModule);
      this.releaseModulesStore.removeActiveModule(index);
      this.releaseModulesStore.removeOriginalModule(index);
    },
    addProgrammeBlueprintModule(blueprint) {
      const moduleData = {
        ...blueprint,
        programmeBlueprintBadges: this.programmeBlueprint.badges,
        addedModule: true,
      };
      this.programmeBlueprintModules.push(moduleData);
      this.releaseModulesStore.setActiveModule(moduleData);
      this.releaseModulesStore.setOriginalModule(moduleData);
      this.showProgrammeBlueprintModuleModal = false;
    },
    makeProgrammeBlueprintModuleUpdate(index, updatePayload) {
      this.programmeBlueprintModules.splice(index, 1, {
        ...this.programmeBlueprintModules[index],
        updatePending: true,
        ...{ updates: updatePayload },
      });
    },
    cancelProgrammeBlueprintModuleUpdate(index) {
      const revertedModule = JSON.parse(JSON.stringify(this.programmeBlueprintModules[index]));
      delete revertedModule.newReleaseAfterDays;
      delete revertedModule.newExpectedCompletionDays;
      delete revertedModule.newBadges;
      delete revertedModule.updatePending;
      this.programmeBlueprintModules.splice(index, 1, revertedModule);
    },
    getProducts() {
      this.productsReady = false;
      this.$logger.info('Getting products');
      return this.$http.get('/api/curriculum/products').then(res => {
        this.products = res.data;
      }).catch(err => {
        this.$logger.autowarn('Could not get products', undefined, err);
        this.showError(err);
      }).then(() => {
        this.productsReady = true;
      });
    },
    numberModules() {
      const trackCounter = { curriculum: 0, milestone: 0 };
      for (let i = 0; i < this.programmeBlueprintModules.length; i += 1) {
        trackCounter[this.programmeBlueprintModules[i].track] += 1;
        if (this.programmeBlueprintModules[i].number !== trackCounter[this.programmeBlueprintModules[i].track]) {
          this.programmeBlueprintModules[i].number = trackCounter[this.programmeBlueprintModules[i].track];
          this.programmeBlueprintModules[i].updatePending = true;
        }
      }
    },
    createModule(pbm) {
      // when a new module is created i.e. it was copied
      this.$logger.info('Adding module to programme blueprint', { progId: this.programmeBlueprintId, progBlueprintMod: pbm.id });
      const payload = {
        programme_blueprint_id: this.programmeBlueprintId,
        abstract_module_id: pbm.id,
        module_type: pbm.sourceModuleType,
        number: pbm.number,
        track: pbm.track,
        copy_badges: pbm.copyBadges,
        release_after_days: pbm?.updates?.release_after_days ?? pbm?.release_after_days ?? null,
        expected_completion_days: pbm?.updates?.expected_completion_days ?? pbm?.expected_completion_days ?? null,
        badge_ids: pbm.updates?.badges_in_module || pbm.badges_in_module || [],
      };
      payload.badge_ids = payload.badge_ids.map(b => b.id);
      return this.$http.post(`/api/curriculum/blueprints/programmes/${this.programmeBlueprintId}/copy-module`, payload);
    },
    createEmptyModule(pbm) {
      this.$logger.info('Adding module to programme blueprint', { progId: this.programmeBlueprintId, progBlueprintMod: pbm.id });
      const payload = {
        programme_blueprint_id: this.programmeBlueprintId,
        number: pbm.number,
        track: pbm.track,
        name: pbm.name,
        description: pbm.description,
        release_after_days: pbm?.updates?.release_after_days ?? pbm?.release_after_days ?? null,
        expected_completion_days: pbm?.updates?.expected_completion_days ?? pbm?.expected_completion_days ?? null,
        badge_ids: pbm.updates?.badges_in_module || pbm.badges_in_module || [],
      };
      payload.badge_ids = payload.badge_ids.map(b => b.id);
      return this.$http.post(`/api/curriculum/blueprints/programmes/${this.programmeBlueprintId}/modules`, payload);
    },
    updateExistingModule(pbm) {
      const payload = {
        number: pbm.number,
        track: pbm.track,
        release_after_days: pbm?.updates?.release_after_days ?? pbm?.release_after_days ?? null,
        expected_completion_days: pbm?.updates?.expected_completion_days ?? pbm?.expected_completion_days ?? null,
        badge_ids: pbm.updates?.badges_in_module || pbm.badges_in_module || [],
      };
      payload.badge_ids = payload.badge_ids.map(b => b.id);
      this.$logger.info('Updating released module in programme blueprint', {
        progBlueprintId: this.programmeBlueprintId, progBlueprintMod: pbm.id,
      });
      return this.$http.put(`/api/curriculum/blueprints/programmes/modules/${pbm.id}/release`, payload);
    },
    deleteModule(pbm) {
      this.$logger.info('Deleting module from programme blueprint', { progId: this.programmeBlueprintId, progBlueprintModId: pbm.id });
      return this.$http.delete(`/api/curriculum/blueprints/programmes/modules/${pbm.id}`);
    },
    updateProgrammeBlueprintModules() {
      this.updatingProgrammeBlueprintModules = true;
      this.numberModules();
      const calls = [];
      this.$logger.info('Updating modules for programme blueprint', { progId: this.programmeBlueprintId });

      this.programmeBlueprintModules.forEach(pbm => {
        if (!pbm.id) {
          calls.push(this.createEmptyModule(pbm));
        } else if (pbm.addedModule) {
          calls.push(this.createModule(pbm));
        } else if (pbm.updatePending) {
          calls.push(this.updateExistingModule(pbm));
        }
      });

      this.programmeBlueprintModuleToDelete.forEach(pbm => {
        if (!pbm.addedModule) { // No need to delete pbm that have not been added to the db yet
          calls.push(this.deleteModule(pbm));
        }
      });
      this.updatingMeta = true;
      Promise.all(calls).then(() => {
        this.$logger.info('Successfully updated modules in programme blueprint', { progId: this.programmeBlueprintId }, true);
        // A watch on programme will update the modules
        this.releaseModulesStore.clearModules();
        this.$emit('refresh');
        this.$ktoast.success('Programme blueprint modules updated');
      }).catch(err => {
        this.$logger.error('Error updating modules in programme blueprint', { progId: this.programmeBlueprintId });
        this.showError(err);
      }).then(() => {
        this.programmeBlueprintModuleToDelete = [];
        this.updatingProgrammeBlueprintModules = false;
        this.updatingMeta = false;
      });
    },
  },
};
</script>

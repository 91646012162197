<template>
  <div>
    <h1>Programmes</h1>
    <k-nav-tabs :currentTab="currentTab" :tabs="tabs"></k-nav-tabs>
    <router-view v-bind="pageProps"></router-view>
  </div>
</template>

<script>
import ErrorMixin from '@mixins/error-mixins';
import KNavTabs from '@base-components/k-nav-tabs.vue';

export default {
  components: {
    KNavTabs,
  },

  mixins: [ErrorMixin],

  data() {
    return {
      tabs: [
        {
          name: 'Current',
          routeName: 'programmes_overview_current_programmes',
        },
        {
          name: 'Create',
          routeName: 'programmes_overview_create_programme',
        },
      ],
      products: [],
      productsReady: false,
    };
  },

  beforeMount() {
    this.getProducts();
  },

  computed: {
    currentTab() {
      return this.$route.name;
    },
    pageProps() {
      return { products: this.products };
    },
  },

  methods: {
    getProducts() {
      this.$logger.info('Getting KATE programme products');
      this.productsReady = false;
      return this.$http.get('/api/curriculum/products').then(res => {
        this.$logger.info('Got KATE programme products');
        this.products = res.data;
      }).catch(err => {
        this.$logger.error('Could not get KATE programme products', undefined, err);
        this.showError(err);
      }).then(() => {
        this.productsReady = true;
      });
    },
  },
};
</script>

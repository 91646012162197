<template>
  <k-panel title="Manage Modules" :hasContentToggle="false">
    <template #body>
      <div v-if="!modulesReady">
        <i class="fas fa-spinner fa-spin"></i>
      </div>
      <div v-else>
        <manage-modules
          v-model="modules"
          :disableCommit="updatingProgrammeModules"
          :programme-start-date="programme.start_date"
          @reset="reset"
          @orderChanged="setModuleNumbers"
          @remove="removeModule"
          @cancel="cancelModuleUpdate"
          @editMode="toggleEditMode"
          @add="addModule"
          @saveChanges="updateProgrammeModules"
          @release-update="makeModuleUpdate"
        >
          <template #instructions>
            <p>Below is a list of modules for the <b>{{ programme.name }}</b> programme. You can change the order by dragging them.</p>
            <p>You can: schedule a module's release; withdraw a module or assign badges to a module by clicking on the module card's <i>edit</i> button</p>
            <p>Once in edit mode</p>
            <ul>
              <li>Use the date picker to set the release date. The module will become available to students at midnight (UTC) on the specified date.</li>
              <li>Click on the <i>Withdraw</i> button to withdraw the module. The module will no longer be available to students</li>
              <li>Click on the <i>Assign Badges</i> button to assign badges to the module</li>
              <li>Exit edit mode by clicking the <i>Update</i> button</li>
            </ul>
            <p>Note that no changes (removing, assigning badges, reordering, scheduling a release or withdrawing) will be reflected on <b>EDUKATE</b> until you save your changes via the <i>Save Changes</i> button.</p>
          </template>
        </manage-modules>
      </div>
    </template>
  </k-panel>
</template>

<style scoped>
.module-blueprint-controls {
  padding-bottom: 20px;
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import TimeMixin from '@mixins/time-mixins';
import KPanel from '@base-components/k-panel.vue';
import useReleaseModuleStore from '@stores/release-module-store';
import { sortObjectArray } from '../../../modules/sort-by-object-property';
import AbstractManageModules from './abstract-manage-modules.vue';

export default {
  components: {
    'manage-modules': AbstractManageModules,
    KPanel,
  },

  props: {
    programme: {
      type: Object,
    },
  },

  mixins: [ErrorMixin, TimeMixin],

  watch: {
    programme() {
      this.getModules();
    },
    hasEdits: {
      handler(edits) {
        this.$emit('changes', edits);
      },
      deep: true,
    },
    modules: {
      handler() {
        for (let i = 0; i < this.modules.length; i += 1) {
          this.modules[i].index = i;
        }
        this.releaseModulesStore.setActiveModules(this.modules);
        this.releaseModulesStore.updateOriginalModulesOrder();
        if (this.modules.length === this.originalModules.length && !this.modules.every((v, i) => v.id === this.originalModules[i].id)) {
          this.$emit('changes', true);
        }
      },
      deep: true,
    },
    originalModules() {
      for (let i = 0; i < this.originalModules.length; i += 1) {
        this.originalModules[i].index = i;
      }
      this.releaseModulesStore.setOriginalModules(this.originalModules);
    },
  },

  data() {
    return {
      modules: [],
      originalModules: [],
      modulesReady: false,
      updatingProgrammeModules: false,
      hasModulesRemoved: false,
      removedModules: [],
      indicesInEditMode: [],
      releaseModulesStore: useReleaseModuleStore(),
    };
  },

  beforeMount() {
    this.getModules();
  },

  computed: {
    hasModuleUpdatesPending() {
      return Boolean(this.modules.find(x => x.updates || x.addedModule));
    },
    hasEdits() {
      return this.hasModuleUpdatesPending || this.hasModulesRemoved;
    },
  },

  methods: {
    toggleEditMode(i, editMode) {
      if (editMode) {
        this.indicesInEditMode.push(i);
      } else if (this.indicesInEditMode.indexOf(i) >= 0) {
        this.indicesInEditMode = this.indicesInEditMode.filter(item => item !== i);
      }
    },
    reset() {
      this.releaseModulesStore.clearModules();
      this.modules = JSON.parse(JSON.stringify(this.originalModules));
      this.releaseModulesStore.setActiveModules(this.modules);
      this.releaseModulesStore.setOriginalModules(this.originalModules);
      this.hasModulesRemoved = false;
      this.removedModules = [];
    },
    removeModule(index) {
      let deletedModule;
      if (this.modules[index].addedModule) {
        // Removing a module that is not yet committed to the DB and part of the programme, so we just remove it from the list
        deletedModule = this.modules.splice(index, 1);
      } else {
        // Removing a module that has been committed to the DB and part of the programme, so we add it to the list of removed modules
        // so it can be deleted from the DB via the deleteModule API call
        deletedModule = this.modules.splice(index, 1);
        this.removedModules = this.removedModules.concat(deletedModule);
        this.hasModulesRemoved = true;
      }
      // remove from both activeModules and originalModules
      this.releaseModulesStore.removeActiveModule(index);
      this.releaseModulesStore.removeOriginalModule(index);
      this.setModuleNumbers();
    },
    makeModuleUpdate(index, updatePayload) {
      this.modules.splice(index, 1, {
        ...this.modules[index],
        ...{ updates: updatePayload },
      });
    },
    cancelModuleUpdate(index) {
      const revertedModule = JSON.parse(JSON.stringify(this.modules[index]));
      delete revertedModule.updates;
      this.modules.splice(index, 1, revertedModule);
    },
    setModuleNumbers() {
      const trackCounts = {};
      this.modules = this.modules.map(x => {
        if (!trackCounts[x.track]) {
          trackCounts[x.track] = 0;
        }
        trackCounts[x.track] += 1;
        return {
          ...x,
          number: trackCounts[x.track],
          numberUpdated: x.numberUpdated || trackCounts[x.track] !== x.number,
        };
      });
    },
    addModule(module) {
      this.$logger.info('Adding module to programme', {
        progId: this.programme.id,
      }, true);
      const moduleData = {
        ...module,
        number: this.modules.filter(x => x.track === module.track).length + 1,
        programmeBadges: this.programme.badges,
        addedModule: true,
        released: module.release_date ? this.getDate(module.release_date) <= new Date() : false,
      };
      this.modules.push(moduleData);
      // add a record in store
      this.releaseModulesStore.setActiveModule(moduleData);
      this.releaseModulesStore.setOriginalModule(moduleData);
    },
    getModules() {
      this.$logger.info('Getting modules for programme blueprint', { progId: this.programme.id }, true);
      this.modulesReady = false;
      this.modules = [];
      this.$http.get(`/api/curriculum/admin/programmes/${this.programme.id}/modules`).then(res => {
        this.modules = sortObjectArray(res.data.modules, 'number').map(x => ({ programmeBadges: this.programme.badges, ...x }));
        this.originalModules = sortObjectArray(JSON.parse(JSON.stringify(res.data.modules)), 'number');
        this.releaseModulesStore.setOriginalModules(this.originalModules);
        this.releaseModulesStore.setActiveModules(this.modules);
        this.$logger.info('Got modules for programme blueprint', { progId: this.programme.id });
      }).catch(err => {
        this.$logger.error('Error getting modules for programme blueprint', { progId: this.programme.id }, err);
        this.showError(err);
      }).then(() => {
        this.modulesReady = true;
      });
    },
    formatDateInPayload(val) {
      return val ? this.formatDate(val) : null;
    },
    createModule(module) {
      // add a unit test for this
      // when a new module is created i.e. it was copied
      this.$logger.info('Adding module to programme blueprint', { progId: this.programme.id, moduleId: module.id }, true);
      const payload = {
        programme_id: this.programme.id,
        abstract_module_id: module.id,
        module_type: module.sourceModuleType,
        number: module.number,
        track: module.track,
        copy_badges: module.copyBadges,
        expected_completion_date: module.expected_completion_date,
        release_date: module.release_date,
        ...module.updates,
      };
      payload.badge_ids = payload.badges_in_module?.map(x => x.id);
      payload.release_date = this.formatDateInPayload(payload.release_date);
      payload.expected_completion_date = this.formatDateInPayload(payload.expected_completion_date);
      return this.$http.post(`/api/curriculum/programmes/${this.programme.id}/copy-module`, payload);
    },
    createEmptyModule(m) {
      this.$logger.info('Adding module to programme blueprint', { progId: this.programme.id, progMod: m.id });
      const payload = {
        programme_id: this.programme.id,
        number: m.number,
        track: m.track,
        name: m.name,
        description: m.description,
        ...m.updates,
      };
      payload.badge_ids = payload.badges_in_module?.map(x => x.id);
      payload.release_date = this.formatDateInPayload(payload.release_date);
      payload.expected_completion_date = this.formatDateInPayload(payload.expected_completion_date);
      return this.$http.post(`/api/curriculum/admin/programmes/${this.programme.id}/modules`, payload);
    },
    deleteModule(module) {
      this.$logger.info('Deleting module from programme', { progId: this.programme.id, moduleId: module.id }, true);
      return this.$http.delete(`/api/curriculum/modules/${module.id}`);
    },
    updateExistingModule(module) {
      const payload = { number: module.number, badges_in_module: module.badges_in_module, ...module.updates };
      // Don't format release / completion dates if they're not in the payload - otherwise they
      // can be set to null and overwrite existing dates when re-ordering modules
      if ('release_date' in payload) {
        payload.release_date = this.formatDateInPayload(payload.release_date);
      }
      if ('expected_completion_date' in payload) {
        payload.expected_completion_date = this.formatDateInPayload(payload.expected_completion_date);
      }
      this.$logger.info('Updating released module in programme blueprint', { progId: this.programme.id, moduleId: module.id }, true);
      return this.$http.put(`/api/curriculum/modules/${module.id}/release`, payload);
    },
    updateProgrammeModules() {
      this.setModuleNumbers();
      this.updatingProgrammeModules = true;
      const modules = this.modules.filter(x => x.numberUpdated || x.updates || x.addedModule);
      const calls = [];
      this.$logger.info('Updating modules for programme blueprint', {
        progId: this.programme.id,
      }, true);

      for (let i = 0; i < modules.length; i++) {
        if (!modules[i].id) {
          calls.push(this.createEmptyModule(modules[i]));
        } else if (modules[i].addedModule) {
          calls.push(this.createModule(modules[i]));
        } else {
          calls.push(this.updateExistingModule(modules[i]));
        }
      }

      for (let i = 0; i < this.removedModules.length; i++) {
        calls.push(this.deleteModule(this.removedModules[i]));
      }

      Promise.all(calls).then(() => {
        this.$logger.info('Successfully updated modules in programme', { progId: this.programme.id, modules }, true);
        this.$ktoast.success('Success');
        this.releaseModulesStore.clearModules();
        this.$emit('update-programme');
      }).catch(err => {
        this.$logger.error('Error updating modules in programme', { progId: this.programme.id, modules });
        this.showError(err);
      }).then(() => {
        this.removedModules = [];
        this.updatingProgrammeModules = false;
      });
    },
  },
};
</script>

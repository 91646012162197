<template>
  <k-panel title="Current"
    icon='fa-users'
    :showContent="showContent"
    @toggle="showContent = $event"
  >
    <template #body>
      <button type="button" class="btn btn-outlined download" @click="downloadCsv">
        Download (.csv) <i class="fas fa-download"></i>
      </button>
      <div class="members-table-container">
        <k-editable-table
          v-model="memberSummary"
          :headers="headers"
          :max="20"
        >
      </k-editable-table>
      </div>
      <k-tooltip :text="hasProgrammeGroups ? '' : 'There are no groups in this programme'">
        <button v-if="members.length"
                class="btn btn-success update-groups"
                @click="updateAllMembersProgrammeGroups"
                :disabled="!hasProgrammeGroups">Update Groups</button>
      </k-tooltip>
      <k-modal :show="showModal" @close="closeModal" class="confirm-removal-modal">
        <template #header>
          <h3>Confirm removing user from programme</h3>
        </template>
        <template #body>
          <div class="confirm-removal-body">
            <p>Are you sure you want to remove <b class="emph">{{currentStudentName}}</b> from <b class="emph">{{programme.name}}</b>?</p>
            <p class="danger">The learner will be removed from the programme and lose access to their progress and assignments.</p>
            <b>No data will be deleted.</b>
            <p>Type in the name to confirm removal:<br>
              <pre class="current-student-name">{{currentStudentName}}</pre>
            </p>
            <form  @submit.prevent="removeUserFromProgramme">
              <input v-model="studentConfirm" placeholder="Type name here..." class="student-confirm-input k-text-primary">
            </form>
          </div>
        </template>
        <template #footer>
          <button class="modal-return-button btn btn-outlined" @click="closeModal">
            Cancel
          </button>
          <button class="btn btn-danger modal-delete-button " @click="removeUserFromProgramme" :disabled="!deletionConfirmMatch">
            <i class="fas fa-trash-alt"></i> Remove user
          </button>
        </template>
      </k-modal>
    </template>
  </k-panel>
</template>

<style>
.k-table-key-del {
  color: var(--kate-danger);
  text-align: center;
}

.confirm-removal-modal input.student-confirm-input {
  background-color: var(--input-background);
  border: var(--input-border);
  padding: 8px 10px;
  border-radius: 4px;
  width: 100%;
}

.btn-remove {
  background-color: transparent;
  padding: 5px 8px;
}

.btn-remove i {
  color: var(--kate-danger-alt);
}

.btn-remove:hover i {
  color: var(--kate-danger);
}

</style>

<style scoped>
.current-student-name {
  margin-top: 15px;
}

.confirm-removal-body b.emph {
  color: var(--kate-type-accent);
  letter-spacing: 0.5px;
}

.confirm-removal-body p.danger {
  color: var(--kate-danger-light);
  text-decoration: underline;
  margin-bottom: 10px;
  display: block;
}

.confirm-removal-body > b {
  margin-bottom: 10px;
  display: block;
}

.members-table-container {
  overflow-x: scroll;
}

button.update-groups {
  margin-top: 20px;
}

.download.btn-outlined {
  margin-bottom: 10px;
}

</style>

<script>
import KPanel from '@base-components/k-panel.vue';
import KModal from '@base-components/k-modal.vue';
import KEditableTable from '@base-components/k-editable-table.vue';
import KTooltip from '@base-components/k-tooltip.vue';
import CsvMixins from '@mixins/csv-mixins';
import getOrNull from '../../../modules/get-or-null';
import formatId from '../../../modules/format-user-id';

export default {
  components: {
    KPanel,
    KTooltip,
    KEditableTable,
    KModal,
  },

  mixins: [CsvMixins],

  props: {
    programme: {
      type: Object,
    },
    members: {
      type: Array,
    },
  },

  mounted() {
    this.memberSummary = this.transformMembersData();
  },

  data() {
    return {
      showModal: false,
      deletionInProgress: false,
      studentConfirm: '',
      currentStudentName: '',
      currentStudentId: null,
      showContent: true,
      memberSummary: [],
      updatingProgrammeGroups: false,
    };
  },

  watch: {
    ready(val) {
      if (val) {
        this.$Loading.finish();
      } else {
        this.$Loading.start();
      }
    },
    members() {
      this.memberSummary = this.transformMembersData();
    },
  },

  computed: {
    programmeId() {
      return this.$route.params.programmeId;
    },

    ready() {
      return !this.deletionInProgress;
    },

    hasProgrammeGroups() {
      if (!this.programme.programme_groups) {
        return false;
      }
      return this.programme.programme_groups.length > 0;
    },

    deletionConfirmMatch() {
      return this.currentStudentName === this.studentConfirm;
    },

    headers() {
      return [
        {
          key: 'formatted_id',
          title: 'Student Id',
          filterable: true,
        },
        {
          key: 'full_name',
          title: 'Name',
          type: 'html',
          filterable: true,
          sortable: true,
          hideable: false,
          html: this.studentLink,
        },
        {
          key: 'email',
          title: 'Email',
          filterable: true,
          sortable: true,
        },
        {
          key: 'programme_groups',
          title: 'Groups',
          editable: true,
          type: 'multi-input-selection',
          required: true,
          options: this.programme.programme_groups,
        },
        {
          key: 'learner_status',
          title: 'Learner Status',
          type: 'string',
          filterable: true,
          sortable: true,
          hideable: false,
        },
        {
          key: 'estimated_graduation_date',
          title: 'Estimated Graduation Date',
          type: 'date',
          sortable: true,
          hideable: false,
          minDate: new Date(),
        },
        {
          key: 'remove',
          title: 'Remove from programme',
          type: 'action',
          editable: false,
          sortable: false,
          filterable: false,
          callback: this.removeUserConfirm,
          html: '<button class="btn-remove" aria-label="Remove" title="Remove"><i class="fas fa-circle-xmark"></i></button>',
        },
      ];
    },

    csvHeaders() {
      return {
        programme_id: {
          name: 'Programme ID',
          type: 'number',
        },
        user_id: {
          name: 'User ID',
          type: 'number',
        },
        full_name: {
          name: 'Full Name',
          type: 'string',
        },
        email: {
          name: 'Email',
          type: 'string',
        },
        programme_groups: {
          name: 'Groups',
          type: 'string',
        },
        learner_status: {
          name: 'Learner Status',
          type: 'string',
        },
        estimated_graduation_date: {
          name: 'Estimated Graduation Date',
          type: 'date',
        },
      };
    },

    csvRows() {
      const transformedData = this.transformMembersData();
      return transformedData.map(m => ({
        programme_id: this.programmeId,
        user_id: m.id,
        full_name: m.full_name,
        email: m.email,
        programme_groups: m.programme_groups.join(', '),
        learner_status: m.learner_status,
        estimated_graduation_date: m.estimated_graduation_date,
      }));
    },
  },

  methods: {
    transformMembersData() {
      return this.members.map(m => {
        let learnerStatus = m.learner_status;
        if (!learnerStatus && m.aptem_learner_status) {
          learnerStatus = `${m.aptem_learner_status} (Aptem)`;
        }

        return {
          id: m.user_id,
          formatted_id: formatId(m.user_id),
          full_name: m.full_name,
          email: m.email,
          learner_status: learnerStatus,
          programme_member_id: m.programme_member_id,
          programme_groups: getOrNull('programme_groups', m, []),
          estimated_graduation_date: m.estimated_graduation_date,
        };
      });
    },

    openModal() {
      this.showModal = true;
    },

    closeModal() {
      this.showModal = false;
      this.studentConfirm = '';
      this.currentStudentId = null;
      this.currentStudentName = '';
    },

    removeUserConfirm(row) {
      this.currentStudentId = row.id;
      this.currentStudentName = row.full_name;
      this.openModal();
    },

    removeUserFromProgramme() {
      if (!this.deletionConfirmMatch) {
        return;
      }
      this.$logger.info('Removing user from programme', { programmeId: this.programmeId, userId: this.currentStudentId }, true);
      this.deletionInProgress = true;
      this.$http.delete(`/api/profile/user/${this.currentStudentId}/programmes/${this.programmeId}`).then(() => {
        this.$logger.info('Successfully removed user from programme', { programmeId: this.programmeId, userId: this.currentStudentId }, true);
        this.$ktoast.success('Successfully removed user from programme');
      }).catch(err => {
        this.$logger.error('Error removing user from programme', { programmeId: this.programmeId, userId: this.currentStudentId }, err);
        this.$ktoast.error('Error removing user');
      }).then(() => {
        this.closeModal();
        this.$emit('update');
        this.deletionInProgress = false;
      });
    },

    studentLink(entry) {
      const index = this.memberSummary.findIndex(m => m.full_name === entry);
      return `<a href="/student/${this.memberSummary[index].id}">${entry}</a>`;
    },

    updateProgrammeGroups(member) {
      const programmeGroups = member.programme_groups;
      const programmeMemberId = member.programme_member_id;
      return this.$http.put(`/api/profile/programme-member/${programmeMemberId}/groups`, {
        groups: programmeGroups,
      });
    },

    updateAllMembersProgrammeGroups() {
      this.updatingProgrammeGroups = true;
      const calls = [];
      this.$logger.info('Updating programme groups', { progId: this.programmeId }, true);
      this.memberSummary.forEach(member => {
        calls.push(this.updateProgrammeGroups(member));
      });

      Promise.all(calls).then(() => {
        this.$logger.info('Successfully updated modules in programme', { progId: this.programmeId }, true);
        this.$ktoast.success('Success');
      }).catch(err => {
        this.$logger.error('Error updating modules in programme', { progId: this.programmeId });
        this.showError(err);
      }).then(() => {
        this.updatingProgrammeGroups = false;
      });
    },

    downloadCsv() {
      this.downloadCsvFromTable(this.csvHeaders, this.csvRows, `programme-members-${this.programmeId}.csv`);
    },
  },
};
</script>

<template>
  <div v-if="ready">
    <add-competencies :programme="programme" :programme-ready="programmeReady" :is-blueprint="isBlueprint" :competencies="programmeCompetencies" @update="getCompetencies"></add-competencies>
    <get-programme-competencies :programme="programme" :programme-ready="programmeReady" :is-blueprint="isBlueprint" :competencies="programmeCompetencies" :competencies-ready="programmeCompetenciesReady" @update="getCompetencies"></get-programme-competencies>
  </div>
</template>

<style scoped>
.competency-item {
  margin-bottom: 1.5rem;
  padding: 1rem;
  border: 1px solid var(--kate-border);
  border-radius: 4px;
}

.competency-item h3 {
  margin-bottom: 0.5rem;
}

.loading {
  text-align: center;
  padding: 2rem;
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import PageReadyMixin from '@mixins/page-ready-mixin';
import { sortObjectArray } from '../../modules/sort-by-object-property';
import AddCompetencies from './competencies/add-competencies.vue';
import GetProgrammeCompetencies from './competencies/get-programme-competencies.vue';

export default {
  components: {
    AddCompetencies,
    GetProgrammeCompetencies,
  },

  mixins: [ErrorMixin, PageReadyMixin],

  props: {
    programme: {
      type: Object,
    },
    programmeReady: {
      type: Boolean,
    },
    isBlueprint: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['refresh-programme'],
  beforeMount() {
    this.$Loading.start();
    this.$emit('refresh-programme');
  },

  mounted() {
    if (this.programme) {
      this.registerCrumbs();
    }
    this.getCompetencies();
  },
  data() {
    return {
      programmeCompetencies: [],
      programmeCompetenciesReady: false,
    };
  },
  computed: {
    apiEndpoint() {
      return `/api/curriculum/${this.isBlueprint ? 'blueprints/' : ''}programmes/${this.entityId}/competencies`;
    },
    entityName() {
      return this.isBlueprint ? 'Programme blueprint' : 'Programme';
    },
    entityId() {
      return Number(this.isBlueprint ? this.$route.params.programmeBlueprintId : this.$route.params.programmeId);
    },
    ready() {
      return this.programmeReady && this.programmeCompetenciesReady;
    },
  },

  methods: {
    getCompetencies() {
      this.$logger.info(`Getting ${this.entityName} competencies`, { entityId: this.entityId }, true);
      this.$http.get(this.apiEndpoint)
        .then(res => {
          this.programmeCompetencies = sortObjectArray(res.data, 'id', true);
          this.$logger.info(`Successfully retrieved ${this.entityName} competencies`, { entityId: this.entityId });
        })
        .catch(err => {
          this.$logger.error(`Could not retrieve ${this.entityName} competencies.`, { entityId: this.entityId }, err);
          this.showError(err);
        })
        .then(() => {
          this.programmeCompetenciesReady = true;
        });
    },
    registerCrumbs() {
      this.$crumbs.register([
        {
          text: 'Manage Programmes',
          path: { name: 'programmes_overview' },
        },
        {
          text: this.programme.name || this.entityId,
          active: true,
        },
      ]);
    },
    pageReadyCallback() {
      this.registerCrumbs();
    },
  },
};
</script>

<template>
  <div v-if="videoId">
    <k-modal class="video-preview-modal" :show="showModal" @close="$emit('close');">
      <template #header>
        <h3>Preview</h3>
      </template>
      <template #body>
        <figure class="preview-video">
          <video v-if="videoPreviewSource" width="100%" height="auto" controls controlsList="nodownload">
            <source :src="videoPreviewSource" type="video/mp4">
            Your browser does not support HTML video.
          </video>
        </figure>
      </template>
    </k-modal>
  </div>
</template>

<style scoped>
.video-preview-modal .modal-container {
  max-height: 90vh;
}

.preview-video video {
  max-height: 65vh;
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import KModal from '@base-components/k-modal.vue';

export default {
  components: {
    'k-modal': KModal,
  },

  mixins: [ErrorMixin],

  props: {
    videoId: {
      type: Number,
    },
    moduleId: {
      type: Number,
    },
  },

  data() {
    return {
      showModal: false,
      videoPreviewSource: undefined,
    };
  },

  watch: {
    videoId(val) {
      if (val) {
        this.previewVideo();
      } else {
        this.showModal = false;
        this.videoPreviewSource = undefined;
      }
    },
  },

  methods: {
    previewVideo() {
      this.$logger.info('Getting video source with signed URL', { videoId: this.videoId }, true);
      this.$Loading.minimal();
      this.$http.get(`/api/curriculum/admin/video/${this.videoId}/watch`)
        .then(result => {
          this.$logger.info('Got signed URL', { videoId: this.videoId });
          this.videoPreviewSource = result.data.src;
          this.showModal = true;
        }).catch(err => {
          this.$logger.error('Error getting signed URL', { videoId: this.videoId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },
  },
};
</script>

<template>
  <section class="panel">
    <div class="panel-body">
      <div class="section-header">
        <h3>Learners</h3>
        <hr>
      </div>
      <div class="section-body">
        <label class="range-label">in the last <b>{{ pluralize('month', value + 1, true) }}</b>
          <input type="range" :min="minValue" :max="maxValue" step="1"
            v-model.number="value"
            :style="{'background-size': backgroundTrackSize}">
        </label>
        <small><i>Active learners are those who performed at least one relevant action (logging in, making a submission, etc...) in a given time period.</i></small>
        <div v-if="learners" class="bg-gradient-dark border-radius-lg py-3 pe-1 mb-3">
          <div class="chart">
            <k-chart-line :key="programmeId"
              :yaxis-max="learners.length"
              :xaxis-data="refinedMonths"
              series-one-title="Active learners"
              :series-one="seriesOneData"
            >
            </k-chart-line>
          </div>
        </div>
        <div class="section-content has-chart row">
          <div class="section-item col-md-4">
            <div class="section-item-title">
              <div class="section-icon">
                <span class="total-learners">
                  <i class="fas fa-users"></i>
                </span>
              </div>
              <h4 class="">All learners</h4>
            </div>
            <div class="chart-stats section-stats">
              <span class="item-stats">{{ learners.length}}</span>
              <div class="progress">
                <div class="progress-bar bg-gradient" role="progressbar" style="width: 100%;" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" aria-label="Progress bar" title="Progress bar"></div>
              </div>
              <button aria-label="Toggle" title="Toggle" @click="toggleAllLearners" class="expand-content" :class="[showAllLearners ? 'active' : '', learners.length <= 0 ? 'disabled' : '']">
                Expand<i class="fas fa-angle-down"></i>
              </button>
            </div>
          </div>

          <div class="section-item col-md-4">
            <div class="section-item-title">
              <div class="section-icon">
                <span class="active-learners">
                  <i class="fas fa-user-check"></i>
                </span>
              </div>
              <h4 class="">Active learners</h4>
            </div>
            <div class="chart-stats section-stats ">
              <span class="item-stats">{{ activeLearners.length }}</span>
              <div class="progress">
                <div class="progress-bar bg-gradient" role="progressbar" :style="`width: ${activeLearnersTrack}%`" aria-valuemin="0" :aria-valuemax="learners.length" aria-label="Progress bar" title="Progress bar"></div>
              </div>
              <button aria-label="Toggle" title="Toggle" @click="toggleActiveLearners" class="expand-content" :class="[showActive ? 'active' : '', activeLearnersIds.length <= 0 ? 'disabled' : '']">
                Expand<i class="fas fa-angle-down"></i> <!-- // jscpd:ignore-start -->
              </button>
            </div>
          </div>

          <div class="section-item col-md-4">
            <div class="section-item-title">
              <div class="section-icon"> <!-- // jscpd:ignore-end -->
                <span class="inactive-learners">
                  <i class="fas fa-user-times"></i>
                </span>
              </div>
              <h4 class="">Inactive learners</h4>
            </div>
            <div class="chart-stats section-stats ">
              <span class="item-stats">{{ inactiveLearners.length }}</span>
              <div class="progress">
                <div class="progress">
                  <div class="progress-bar bg-gradient" role="progressbar" :style="`width: ${inactiveLearnersTrack}%`" :aria-valuenow="inactiveLearners.length" aria-valuemin="0" :aria-valuemax="learners.length" aria-label="Progress bar" title="Progress bar"></div>
                </div>
              </div>
              <button aria-label="Toggle" title="Toggle" @click="toggleInactiveLearners" class="expand-content" :class="[showInactive ? 'active' : '', inactiveLearners.length <= 0 ? 'disabled' : '']">
                Expand<i class="fas fa-angle-down"></i>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div class="learners-activity-table collapsed-content">
        <h4 v-if="showInactive">Inactive learners in the last <b>{{ pluralize('month', value + 1, true) }}</b></h4>
        <h4 v-if="showActive">Active learners in the last <b>{{ pluralize('month', value + 1, true) }}</b></h4>
        <k-table v-if="showAllLearners || showActive || showInactive"
          :headers="kableHeaders"
          :rows="kableRows"
          :max="10"
          :hideable="false"
          :panel="false">
        </k-table>
      </div>
    </div>
  </section>
</template>

<style scoped>
.section-body {
  display: flex;
  flex-direction: column;
}

.section-body .range-label {
  margin-bottom: 0;
}

.section-body input[type="range"] {
  width: 150px;
}

.section-item-title {
  display: flex;
  gap: 10px;
}

.progress {
  height: 5px;
  font-size: 0.75rem;
  background-color: var(--kate-mono-0);
  border-radius: 4px;
  margin-bottom: 0;
}

.progress-bar {
  height: 6px;
  border-radius: 0.25rem;
}
</style>

<style>
.learners-activity-table .k-table-container .k-table-panel {
  padding-bottom: 0;
}

.learners-activity-table th.k-table-header-learner {
  max-width: 180px;
  width: 150px;
}

#engagement-overview .has-chart .section-item {
  flex-direction: column;
  gap: 5px;
}

#engagement-overview .has-chart .section-icon i {
  font-size: inherit;
}

#engagement-overview .has-chart .section-icon span {
  height: 40px;
  width: 40px;
  display: block;
}

#engagement-overview .section-item .section-item-title {
  flex-direction: row;
  gap: 10px;
}
</style>

<script>
import TimeMixin from '@mixins/time-mixins';
import PluralMixin from '@mixins/plural-mixin';
import KTable from '@base-components/k-table.vue';
import KChartLine from '@base-components/charts/k-chart-line.vue';
import formatId from '../../../modules/format-user-id';

export default {
  components: {
    KTable,
    KChartLine,
  },

  props: {
    programmeId: {
      type: Number,
    },
    learners: {
      type: Array,
    },
    activity: {
      type: Array,
    },
  },

  mixins: [TimeMixin, PluralMixin],

  data() {
    return {
      value: 11,
      minValue: 0,
      maxValue: 11,
      showActive: false,
      showInactive: false,
      showAllLearners: false,
    };
  },

  computed: {
    // Tracks and progress-bar
    backgroundTrackSize() {
      // Best calculation for all browsers to avoid either going over or short of the range thumb
      return `${Math.round((parseInt(this.value, 10) - this.minValue) * (100 / (this.maxValue - this.minValue)))}% 100%`;
    },

    inactiveLearnersTrack() {
      if (this.inactiveLearners.length < 1) {
        return 0;
      }
      return `${Math.trunc((100 * this.inactiveLearners.length) / this.learners.length)}`;
    },

    activeLearnersTrack() {
      if (this.learners.length < 1) {
        return 0;
      }
      return `${(100 * this.activeLearnersIds.length) / this.learners.length}`;
    },
    // </End of Tracks

    inactiveLearners() {
      if (this.learners.length < 1) {
        return [];
      }
      // Filter the active learners out to get the list of inactive learners
      return this.learners.filter(x => this.activeLearnersIds.indexOf(x.user_id) === -1);
    },

    activeLearners() {
      if (this.learners.length < 1) {
        return [];
      }
      // Filter the active learner's IDs to get the active learners
      return this.learners.filter(x => this.activeLearnersIds.indexOf(x.user_id) !== -1);
    },

    relevantMonths() {
      // Get the list of months based on the value
      // ex: value: 5, get the 6 months starting at today's month
      const now = new Date().getMonth();
      const relevantMonths = [];
      for (let i = 0; i <= this.value; i++) {
        relevantMonths.push((((now - i) % 12) + 12) % 12);
      }
      return relevantMonths;
    },

    activeLearnersIds() {
      const seenUserIds = [];
      let userId;

      for (let i = 0; i < this.relevantMonths.length; i++) {
        // Getting only the relevant months
        for (let j = 0; j < this.refinedActivity[this.relevantMonths[i]].length; j++) {
          userId = this.refinedActivity[this.relevantMonths[i]][j];
          if (seenUserIds.indexOf(userId) === -1) {
            seenUserIds.push(userId);
          }
        }
      }
      return seenUserIds;
    },

    refinedActivity() {
      // Returns a list of active, unique ID, learners on a per months basis
      const arr = [];
      let month;
      let userId;

      for (let i = 0; i < 12; i++) {
        arr.push([]);
      }
      for (let i = 0; i < this.activity.length; i++) {
        const date = this.activity[i].dt;
        const prettyDate = this.parseTimestamp(date);
        month = new Date(prettyDate).getMonth();
        userId = this.activity[i].user_id;
        if (arr[month].indexOf(userId) === -1) {
          arr[month].push(userId);
        }
      }
      return arr;
    },

    refinedMonths() {
      // Start from present month
      const now = new Date().getMonth();
      const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      return this.shiftArray(months, now - this.value);
    },

    // Populates the graphs
    seriesOneData() {
      const now = new Date().getMonth();
      if (this.activity.length > 0) {
        // List of number of overall number of active learners per month, starting from current month back to a year
        return this.shiftArray(this.refinedActivity.map(x => x.length), now + 1);
      }
      return [];
    },

    kableHeaders() {
      return {
        learner: {
          name: 'User ID',
          sortable: true,
          type: 'string',
        },
        name: {
          name: 'Name',
          type: 'url',
          filterable: true,
          sortable: true,
          hideable: false,
        },
      };
    },

    kableRows() {
      if (this.showInactive) {
        return this.inactiveLearners.map(val => ({
          learner: formatId(val.user_id),
          name: {
            text: val.full_name,
            path: {
              name: 'dashboard_students',
              params: {
                studentId: val.user_id,
              },
            },
          },
        }));
      }
      if (this.showActive) {
        return this.activeLearners.map(val => ({
          learner: formatId(val.user_id),
          name: {
            text: val.full_name,
            path: {
              name: 'dashboard_students',
              params: {
                studentId: val.user_id,
              },
            },
          },
        }));
      }
      return this.learners.map(val => ({
        learner: formatId(val.user_id),
        name: {
          text: val.full_name,
          path: {
            name: 'dashboard_students',
            params: {
              studentId: val.user_id,
            },
          },
        },
      }));
    },
  },

  methods: {
    shiftArray(array, target) {
      return array.slice(target).concat(array.slice(0, target));
    },

    toggleAllLearners() {
      if (this.learners.length > 0) {
        this.showAllLearners = !this.showAllLearners;
        this.showInactive = false;
        this.showActive = false;
      }
    },

    toggleActiveLearners() {
      if (this.activeLearners.length > 0) {
        this.showActive = !this.showActive;
        this.showInactive = false;
        this.showAllLearners = false;
      }
    },

    toggleInactiveLearners() {
      if (this.inactiveLearners.length > 0) {
        this.showInactive = !this.showInactive;
        this.showActive = false;
        this.showAllLearners = false;
      }
    },
  },
};
</script>

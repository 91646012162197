<template>
  <div v-if="slidesId">
    <k-modal class="slides-preview-modal" :show="showModal" @close="$emit('close');">
      <template #header>
        <h3>Preview</h3>
      </template>
      <template #body>
        <iframe :data="slidesPreviewSource" type="application/pdf" :src="slidesPreviewSource" title="Slide preview">
          <embed :src="slidesPreviewSource" type="application/pdf" />
        </iframe>
      </template>
    </k-modal>
  </div>
</template>

<style>
.slides-preview-modal .modal-container {
  max-height: 80%;
  min-width: 80%;
}

.slides-preview-modal .modal-container .modal-body,
.slides-preview-modal .modal-container .modal-body iframe {
  display: flex;
  flex: 1;
}

.slides-preview-modal .modal-container .modal-body {
  height: 60vh;
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import KModal from '@base-components/k-modal.vue';

export default {
  components: {
    'k-modal': KModal,
  },

  mixins: [ErrorMixin],

  props: {
    slidesId: {
      type: Number,
    },
  },

  data() {
    return {
      showModal: false,
      slidesPreviewSource: undefined,
    };
  },

  watch: {
    slidesId(val) {
      if (val) {
        this.previewSlides();
      } else {
        this.showModal = false;
        this.slidesPreviewSource = undefined;
      }
    },
  },

  methods: {
    previewSlides() {
      this.$logger.info('Getting slides source with signed URL', { slidesId: this.slidesId }, true);
      this.$Loading.minimal();
      this.$http.get(`/api/curriculum/admin/slide_deck/url/${this.slidesId}`)
        .then(result => {
          this.$logger.info('Got signed URL', { slidesId: this.slidesId });
          this.slidesPreviewSource = result.data.slides_url;
          this.showModal = true;
        }).catch(err => {
          if (this.$http.errIn(err, [404])) {
            this.$ktoast.warning('File not found. Please re-upload the PDF for this slide deck', { goAway: 3500 });
          } else {
            this.showError(err);
          }
          this.$logger.error('Error getting signed URL', { slidesId: this.slidesId }, err);
        }).then(() => {
          this.$Loading.finish();
        });
    },
  },
};
</script>

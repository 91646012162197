<template>
  <div>
    Email:
    <b>{{ email }}</b>
    <button v-if="canUpdate" @click="showModal=true" class="btn-icon">
      <i data-v-39e4b4f8="" class="fas fa-edit" title="Click to update email"></i>
    </button>
    <k-modal
      @close="close"
      :show="show"
    >
      <template v-slot:header>
        <h3>Learner Emails</h3>
      </template>
      <template v-slot:body>
        <div v-if="updateInProgress" class="">
          <i class="fas fa-spinner fa-spin"/>
        </div>
        <div v-else class="edit-email">
          <div>
            <p>Set new primary email</p>
            <div class="set-primary-email">
              <input type="email" v-model="newEmail" placeholder="Enter email here...." maxlength="100" class="form-control"/>
              <button class="btn btn-primary new-primary-email" @click="createNewPrimaryEmail" :disabled="!validEmail">Update</button>
            </div>
          </div>
          <div v-if="secondaryEmails && secondaryEmails.length > 0">
            <p>Or... Set primary email from existing secondary email</p>
            <div class="set-secondary-email">
              <k-dropdown :options="secondaryEmails" v-model="secondaryEmail"></k-dropdown>
              <button class="btn btn-primary secondary-email" @click="changePrimaryEmail" :disabled="!validSecondaryEmail">Update</button>
            </div>
          </div>
          <div v-else class="no-secondary-emails">
            <p>This user currently has no secondary email addresses linked to their profile</p>
          </div>
        </div>
      </template>
    </k-modal>
  </div>
</template>

<style scoped>
.edit-email .set-primary-email,
.edit-email .set-secondary-email {
  display: flex;
  flex-wrap: nowrap;
  gap: 10px;
  align-items: baseline;
}

.edit-email .set-secondary-email div {
  width: 750px;
}

.edit-email div {
  margin: 20px 0;
}
</style>

<script>
import KModal from '@base-components/k-modal.vue';
import KDropdown from '@base-components/k-dropdown.vue';
import ErrorMixin from '@mixins/error-mixins';

export default {
  name: 'student-emails.vue',
  components: { KDropdown, KModal },

  mixins: [ErrorMixin],

  props: {
    email: {
      type: String,
      required: true,
    },
    secondaryEmails: {
      type: Array,
      required: true,
    },
    studentId: {
      type: Number,
      required: true,
    },
    canUpdate: Boolean,
  },

  data() {
    return {
      showModal: false,
      newEmail: '',
      secondaryEmail: '',
      updateInProgress: false,
    };
  },

  computed: {
    show() {
      return this.showModal;
    },
    validEmail() {
      return this.newEmail && this.newEmail.length > 0 && this.newEmail.length <= 256;
    },
    validSecondaryEmail() {
      return Number.isInteger(this.secondaryEmail);
    },
  },

  methods: {
    close() {
      this.showModal = false;
      this.newEmail = null;
      this.secondaryEmail = null;
    },
    createNewPrimaryEmail() {
      this.updateInProgress = true;
      this.$logger.info('Udpdating primary email', undefined, true);
      this.$http.post('/api/profile/admin/emails/new', {
        email: this.newEmail,
        user_id: this.studentId,
      }).then(() => {
        this.$logger.info('Updated primary email');
        this.$ktoast.success('Primary email successfully updated');
        this.$emit('update-email');
        this.close();
      }).catch(err => {
        if (err.response && err.response.status === 409) {
          this.$logger.warn('Error updating primary email - Email already in use', undefined, err);
          this.$ktoast.warning('Email already in use');
        } else {
          this.$logger.autowarn('Error udpdating primary email', undefined, err);
          this.showError(err);
        }
      }).then(() => {
        this.updateInProgress = false;
      });
    },
    changePrimaryEmail() {
      if (!this.secondaryEmail) {
        this.$ktoast.warning('Please select a secondary email');
        return;
      }
      this.updateInProgress = true;
      this.$logger.info('Switching primary email', undefined, true);
      this.$http.put('/api/profile/admin/emails/primary', {
        email_id: this.secondaryEmail,
        user_id: this.studentId,
      }).then(() => {
        this.$logger.info('Primary email changed to a secondary email');
        this.$ktoast.success('Primary email successfully updated');
        this.$emit('update-email');
        this.close();
      }).catch(err => {
        this.$logger.autowarn('Error switching primary email', undefined, err);
        this.showError(err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },
  },
};
</script>

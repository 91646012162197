<template>
  <div id="learner-progress">
    <h1>Employer Summary</h1>
    <div class="panel panel-body">
      <div class="employer-summary-chart-overview panel">
        <h3>Overview</h3>
        <em>Distribution of learners based on ILR status</em>
        <div class="overview-content">
          <div class="chart-container">
            <k-chart-pie
              :seriesData="learnerStatusChartData.series"
              :seriesLabels="learnerStatusChartData.labels"
              :showLegend="true"
              :customColors="employerTablePieChartColors"
            ></k-chart-pie>
          </div>
        </div>
      </div>
      <div class="employer-summary-programme-overview panel">
        <div class="lf-header">
          <h3>Programme Overview</h3>
        </div>
        <div class="lf-content">
          <div class="lf-overview">
            <div class="progress-container">
                <k-table
                      class="learner-progress-table"
                      v-if="learnerProgress.length > 0"
                      :rows="kableRows"
                      :headers="kableHeaders"
                      :defaultHeaders="visibleHeaders"
                      :max="7"
                      :hideable="false"
                      :panel="false"
                    >
                    </k-table>
                    <empty-placeholder
                      v-else
                      title="No learners found"
                      info="No learners were found. If you feel something is missing please contact us."
                    ></empty-placeholder>
            </div>
          </div>
        </div>
      </div>
    </div>
    <employer-learners-table :panelTitle="'Active Learners'" :statusType="['Continuing Learning', 'On a Break']" :learnerData="learnerProgress"></employer-learners-table>
    <employer-learners-table
      :panelTitle="'Completed Learners'" :statusType="['Completed Learning']" :learnerData="learnerProgress">
        <template v-slot:preContent>
          <p>The table below displays both successful EPA achievers and those who have referred. Please note that some individuals listed as not having achieved may be in the process of retaking their final EPA assessment.</p>
        </template>
    </employer-learners-table>
    <employer-learners-table :panelTitle="'Withdrawn Learners'" :statusType="['Withdrawn']" :learnerData="learnerProgress"></employer-learners-table>
  </div>
</template>
<style>
#learner-progress .panel {
  box-shadow: var(--box-shadow);
}
</style>
<style scoped>
.panel-body::before,
.panel-body::after {
  content: unset;
}

.panel-body {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: stretch;
  gap: 25px;
  padding: 15px;
}

.lf-header,
.header-actions,
.overview-stats,
.chart-legend {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 15px;
}

.overview-content hr {
  width: 25%;
  border: 1px solid var(--kate-background-alt);
}

.employer-summary-chart-overview.panel,
.employer-summary-programme-overview.panel {
  margin: 0;
  flex: 1;
  border-radius: 15px;
  padding-top: 5px;
}

.employer-summary-chart-overview em {
  font-size: 12px;
}

.employer-summary-programme-overview {
  flex: 1;
}

.overview-content,
.lf-overview {
  margin-top: 20px;
}

.lf-header {
  padding-top: 5px;
  align-items: flex-start;
}

.lf-overview {
  max-height: 220px;
  overflow-y: auto;
}

.progress-container.k-table-panel {
  width: 100%;
}

.chart-legend {
  margin-top: 15px;
}

.progress-container {
  gap: 25px;
}

.content-container {
  overflow-x: auto;
  flex: 1;
}

</style>
<script>
import PageReadyMixin from '@mixins/page-ready-mixin';
import ErrorMixin from '@mixins/error-mixins';
import KTable from '@base-components/k-table.vue';
import KChartPie from '@base-components/charts/k-chart-pie.vue';
import EmptyPlaceholder from '@base-components/empty-placeholder.vue';
import EmployerLearnersTable from './employer-learners-table.vue';

export default {
  name: 'EmployerLearnerSummary',

  mixins: [ErrorMixin, PageReadyMixin],

  components: {
    EmployerLearnersTable,
    KTable,
    KChartPie,
    EmptyPlaceholder,
  },

  data() {
    return {
      learnerProgress: [],
      learnerProgressReady: false,
      employerTablePieChartColors: ['var(--kate-chart-secondary)', 'var(--kate-chart-neutral)', 'var(--kate-chart-success)'],
    };
  },

  beforeMount() {
    this.getLearnerProgress();
  },

  computed: {
    ready() {
      return this.learnerProgressReady;
    },
    kableHeaders() {
      return {
        programme: {
          name: 'Programme Name',
          type: 'string',
          filterable: false,
          sortable: true,
          hideable: false,
        },
        learner_count: {
          name: 'Learner Count',
          type: 'string',
          filterable: false,
          sortable: true,
          hideable: false,
        },
      };
    },
    visibleHeaders() {
      return ['programme', 'learner_count'];
    },
    programmeCounts() {
      const counts = {};
      this.learnerProgress.forEach(learner => {
        const programme = learner.programme;
        if (programme) { // Only consider non-null programme names
          if (!counts[programme]) {
            counts[programme] = 1;
          } else {
            counts[programme]++;
          }
        }
      });
      return counts;
    },
    kableRows() {
      return Object.keys(this.programmeCounts).map(programme => ({
        programme,
        learner_count: this.programmeCounts[programme],
      }));
    },
    learnerStatusCounts() {
      const counts = {
        Active: 0,
        Withdrawn: 0,
        Completed: 0,
      };
      this.learnerProgress.forEach(learner => {
        const status = learner.ilrs_aim_completion_status;
        if (status === 'Withdrawn') {
          counts.Withdrawn++;
        } else if (status === 'Completed Learning') {
          counts.Completed++;
        } else {
          counts.Active++;
        }
      });
      return counts;
    },
    learnerStatusChartData() {
      const counts = this.learnerStatusCounts;
      const labels = ['Active', 'Withdrawn', 'Completed'];
      const series = labels.map(label => counts[label]);
      return { labels, series };
    },
  },
  methods: {
    getLearnerProgress() {
      this.$Loading.start();
      this.learnerProgressReady = false;
      this.$logger.info('Getting learner progress for employer', { employerId: this.userId }, true);
      this.$http.get('/api/stats/employers/learners/progress')
        .then(result => {
          this.$logger.info('Retrieved learners data for employer', {
            employerId: this.userId,
            learnerCount: result.data.length,
          });
          this.learnerProgress = result.data;
          const nameCounts = {};
          this.learnerProgress.forEach(learner => {
            if (learner.user_name) {
              if (!nameCounts[learner.user_name]) {
                nameCounts[learner.user_name] = 0;
              }
              nameCounts[learner.user_name] += 1;
            }
          });
          this.learnerProgress = this.learnerProgress.map(learner => {
            const name = learner.user_name;
            let modifiedUserName = name;

            if (name && nameCounts[name] > 1) {
              modifiedUserName = `${name} <i class="fas fa-question-circle header-tooltip-icon" title="Learner in multiple programmes"></i>`;
            }

            return {
              ...learner,
              user_name: modifiedUserName,
            };
          });
        }).catch(err => {
          this.showError(err);
          this.$logger.autowarn('Error getting learners data for employer', { employerId: this.userId }, err);
        }).then(() => {
          this.learnerProgressReady = true;
          this.$Loading.finish();
        });
    },
  },
};
</script>

<template>
  <k-modal :show="show" @close="$emit('close')">
    <template #header>
      <h2>Select a Certificate</h2>
      <k-text-search class="filter-input" v-model="filterText"></k-text-search>
    </template>
    <template #body>
      <certificate-list v-if="certificatesReady"
        :achievements="sortedFilteredCertificates"
        :achievement-type="'certificate'"
        :interact="true"
        @achievement-click="choose($event)"
      ></certificate-list>
      <i v-else class="fas fa-spinner fa-spin"></i>
    </template>
  </k-modal>
</template>

<script>
import KModal from '@base-components/k-modal.vue';
import KTextSearch from '@base-components/k-text-search.vue';
import ErrorMixins from '@mixins/error-mixins';
import CertificateList from './achievement-list.vue';
import { sortObjectArray } from '../../modules/sort-by-object-property';
import stringMatch from '../../modules/string-match';

export default {
  components: {
    KTextSearch,
    KModal,
    CertificateList,
  },
  props: {
    show: Boolean,
  },

  mixins: [ErrorMixins],

  data() {
    return {
      certificates: [],
      certificatesReady: false,
      filterText: '',
    };
  },

  beforeMount() {
    this.getCertificates();
  },

  computed: {
    sortedFilteredCertificates() {
      const certificatesFiltered = this.certificates.filter(
        c => stringMatch(c.name, this.filterText, true)
          || stringMatch(c.description, this.filterText, true),
      );
      return sortObjectArray(certificatesFiltered.slice(), 'created_at', true);
    },
  },

  methods: {
    choose(certificate) {
      this.$emit('choose', certificate);
      this.$emit('close');
    },
    getCertificates() {
      this.$logger.info('Getting certificates');
      this.certificatesReady = false;
      this.$http.get('/api/curriculum/certificate')
        .then(result => {
          this.certificates = result.data.certificates;
        }).catch(err => {
          if (!this.$http.errIn(err, [404])) {
            this.showError(err);
          }
          this.$logger.autowarn('Error getting certificates', undefined, err);
          this.certificates = [];
        }).then(() => {
          this.certificatesReady = true;
        });
    },
  },
};
</script>

<template>
  <div>
    <k-panel title="Badges"
      icon="fa-certificate"
      :duotone="false"
      :showContent="showContent"
      @toggle="showContent = $event"
    >
      <template #body>
        <p>
          Badges are a way to reward learners for their achievements. They are awarded based on the average score
          achieved by a learner across all the modules the badge is linked to. Badges can be linked to multiple
          modules and a learner can achieve a badge once they have achieved an average score that exceeds the
          threshold for that badge across all the modules the badge is linked to.
        </p>
        <badge-list v-if="selectedBadges.length > 0"
          :achievements="selectedBadges"
          achievement-type="badge"
          :editable="true"
          @remove-achievement="removeBadges($event)"
          @threshold-score="setScoreThreshold($event)"
        ></badge-list>
        <empty-placeholder v-else
          title="No badges in this programme"
          info="Click 'Add Badge' button below to include badges in this programme"
          fa-icon="fas fa-square-dashed-circle-plus"
        ></empty-placeholder>
        <div class="badge-controls">
          <button class="btn btn-primary" @click="showBadgeModal = true">Add Badge</button>
          <button class="btn btn-success save-badges" :disabled="!badgesUpdated" @click="updateBadges">Confirm</button>
        </div>
        <select-badge-modal v-if="badgesReady"
          :show="showBadgeModal"
          :specific-badges="specificBadges"
          @close="showBadgeModal = false"
          @choose="addBadge"
        ></select-badge-modal>
      </template>
    </k-panel>
  </div>
</template>

<style scoped>
.badge-controls {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}

.panel-content > p {
  margin-bottom: 30px;
}
</style>

<script>
import KPanel from '@base-components/k-panel.vue';
import PageReadyMixin from '@mixins/page-ready-mixin';
import BadgesMixins from '@mixins/badges-mixins';
import EmptyPlaceholder from '@base-components/empty-placeholder.vue';
import AchievementList from '../achievements/achievement-list.vue';
import SelectBadgeModal from '../achievements/select-badge-modal.vue';
import copyObject from '../../modules/copy-object';

export default {
  components: {
    KPanel,
    'badge-list': AchievementList,
    SelectBadgeModal,
    EmptyPlaceholder,
  },

  mixins: [PageReadyMixin, BadgesMixins],

  props: {
    programme: {
      type: Object,
    },
    programmeReady: {
      type: Boolean,
    },
    isBlueprint: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      showBadgeModal: false,
      mounted: false,
      showContent: true,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getBadges();
  },

  mounted() {
    this.mounted = true;
    this.loadBadgesFromProgramme();
  },

  watch: {
    programme() {
      if (this.programme) {
        this.loadBadgesFromProgramme();
      }
    },
    unsavedChanges() {
      this.$emit('unsaved-changes', this.unsavedChanges);
    },
  },

  computed: {
    apiEndpoint() {
      return `/api/curriculum/${this.isBlueprint ? 'blueprints/' : ''}programmes/${this.entityId}/badges`;
    },
    entityId() {
      return Number(this.isBlueprint ? this.$route.params.programmeBlueprintId : this.$route.params.programmeId);
    },
    ready() {
      return this.programmeReady && this.mounted && this.badgesReady;
    },
    specificBadges() {
      return this.filterExcludedBadges(this.selectedBadges);
    },
    unsavedChanges() {
      return this.badgesUpdated;
    },
  },

  methods: {
    loadBadgesFromProgramme() {
      this.selectedBadges = copyObject(this.programme || {})?.badges || [];
    },
    addOrUpdateBadge(badge) {
      this.$logger.info('Adding badge to programme', { progId: this.entityId, badgeId: badge.id }, true);
      return this.$http.put(`${this.apiEndpoint}/${badge.id}`, {
        threshold_score: badge.threshold_score,
      });
    },
    deleteBadge(badge) {
      this.$logger.info('Deleting badge from programme', { progId: this.entityId, badgeId: badge.id }, true);
      return this.$http.delete(`${this.apiEndpoint}/${badge.id}`);
    },
    updateBadges() {
      this.$Loading.start();
      const badges = this.selectedBadges;
      const calls = [];
      this.$logger.info('Updating badges for programme', { progId: this.entityId, badges }, true);
      badges.forEach(badge => {
        calls.push(this.addOrUpdateBadge(badge));
      });
      this.removedBadges.forEach(badge => {
        calls.push(this.deleteBadge(badge));
      });
      Promise.all(calls).then(() => {
        this.$logger.info('Successfully updated badges in programme', { progId: this.entityId, badges }, true);
        this.$ktoast.success('Success');
        this.$emit('refresh');
        this.badgesUpdated = false;
      }).catch(err => {
        this.$logger.error('Error updating badges in programme', { progId: this.entityId, badges });
        this.showError(err);
      }).then(() => {
        this.$Loading.finish();
      });
    },
  },
};
</script>

<template>
  <div class="release-asset-container" :id="`${assetType}-release`" :class="assetsReady ? '' : 'loading'">
    <splitpanes v-if="assetsReady" class="custom-theme modal-theme">
      <pane min-size="10" max-size="50" :size="20" class="left-pane">
        <ul>
          <k-text-search class="filter-input" v-model="assetFilterText"></k-text-search>
          <li class="list-items" v-for="asset in availableAssets" :key="asset.id">
            <k-item-selection-button
              :name="asset.name"
              :tags="asset.tags"
              :assetType="assetType"
              @click="addNewAsset(asset)"
            ></k-item-selection-button>
          </li>
        </ul>
      </pane>
      <pane min-size="50" max-size="90" :size="80">
        <div class="release-table">
          <div v-if="useTimezones" class="timezone-dropdown">
            <span>Select timezone:</span>
            <k-dropdown class="timezone-dropdown"
              :placeholder="timezone"
              searchPlaceholder="Select timezone"
              :options="timezones"
              v-model="timezone">
            </k-dropdown>
          </div>
          <div v-if="moduleReleaseDate" class="module-dates start-date">
            <span>Module release date:</span>
            <span>{{ parseTimestamp(moduleReleaseDate) }}</span>
          </div>
          <div v-if="moduleCompletionDate" class="module-dates end-date">
            <span>Expected completion date:</span>
            <span>{{ parseTimestamp(moduleCompletionDate) }}</span>
          </div>
          <br>
          <k-editable-table v-model="summary"
            :headers="headers"
            :timezone="timezone"
            :row-class-func="getRowClass"
            :max="6"
          ></k-editable-table>
          <span v-if="releaseWarningMessage" class="release-warning">
            <i class="fas fa-exclamation-triangle"></i> {{ releaseWarningMessage }}
          </span>
          <div class="table-controls">
            <button class="btn btn-success" @click="addAssets">
              Update {{ this.moduleTypeMeta.name }}
            </button>
            <k-tooltip :text="`Remove all newly added, unreleased ${assetPrettyName}s from the table`">
              <button class="btn btn-danger" @click="clearNewAssets">Clear</button>
            </k-tooltip>
          </div>
        </div>
      </pane>
    </splitpanes>
    <template v-else>
      <i class="fas fa-spinner fa-spin"></i>
    </template>
  </div>
</template>

<style>
.release-asset-container .k-search-dropdown-adv-content {
  display: block;
  min-width: 200px;
}

.release-asset-container button.k-search-dropdown-button.btn.button {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.release-asset-container .k-word-tags > form > button {
  max-height: 46px;
}
</style>

<style scoped>
.release-warning > i {
  color: var(--kate-warning);
}

.module-dates span:nth-child(2) {
  color: var(--kate-type-accent);
}
</style>

<script>
import { Splitpanes, Pane } from 'splitpanes';
import KTooltip from '@base-components/k-tooltip.vue';
import KEditableTable from '@base-components/k-editable-table.vue';
import KTextSearch from '@base-components/k-text-search.vue';
import KDropdown from '@base-components/k-dropdown.vue';
import ErrorMixin from '@mixins/error-mixins';
import TimeMixin from '@mixins/time-mixins';
import ModuleTypeMixin from '@mixins/module-type-mixin';
import TextFormattingMixin from '@mixins/text-formatting-mixins';
import PluralMixin from '@mixins/plural-mixin';
import stringMatch from '../../../../modules/string-match';
import flatten from '../../../../modules/flatten';
import KItemSelectionButton from '../../../components/k-item-selection-button.vue';
import getOrNull from '../../../../modules/get-or-null';
import { ASSET_TYPES, MODULE_TYPES } from '../../../../constants';

export default {
  components: {
    KEditableTable,
    KTooltip,
    KDropdown,
    Splitpanes,
    Pane,
    KTextSearch,
    'k-item-selection-button': KItemSelectionButton,
  },

  mixins: [TimeMixin, ErrorMixin, TextFormattingMixin, PluralMixin, ModuleTypeMixin],

  props: {
    moduleId: {
      type: Number,
    },
    moduleType: {
      type: String,
      default: 'module',
      validator: value => Object.keys(MODULE_TYPES).includes(value),
    },
    assetType: {
      type: String,
      required: true,
    },
    releasedAssets: {
      type: Array,
      default: () => [],
    },
    programmeGroups: {
      type: Array,
      default: () => [],
    },
    requireWithdrawalConfirmation: {
      type: Boolean,
      default: false,
    },
    moduleReleaseDate: {
      type: String,
    },
    moduleCompletionDate: {
      type: String,
    },
    useTimezones: {
      type: Boolean,
      default: false,
    },
    trainers: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      updating: false,
      assets: [],
      assetsReady: false,
      assetFilterText: '',
      summary: [],
      confirmationToast: undefined,
      timezone: 'Europe/London',
      timezones: Intl.supportedValuesOf('timeZone'),
    };
  },

  beforeMount() {
    this.getAssets();
  },

  mounted() {
    // Loads the releasedAssets props into the summary
    this.updateSummary();
  },

  watch: {
    assetType() {
      this.summary = [];
      this.getAssets();
    },
    releasedAssets() {
      this.updateSummary();
    },
    updating() {
      if (this.updating) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },

  computed: {
    assetPrettyName() {
      return ASSET_TYPES[this.assetType].prettyName;
    },
    riskOfDataLoss() {
      return Boolean(ASSET_TYPES[this.assetType].dataLossField);
    },
    dataLossWarningText() {
      const msg = ASSET_TYPES[this.assetType].dataLossField.replace(/_/g, ' ');
      return `This ${this.assetPrettyName} ${msg} and cannot be withdrawn due to the risk of data loss`;
    },
    withdrawalConfirmationText() {
      const msg = this.riskOfDataLoss ? ASSET_TYPES[this.assetType].dataLossField.replace(/_/g, ' no ') : 'has no risk of data loss';
      return `This ${this.assetPrettyName} ${msg} and can be removed.`;
    },
    additionalReleaseFields() {
      return ASSET_TYPES[this.assetType].additionalReleaseFields || [];
    },
    hasTrainers() {
      return Boolean(ASSET_TYPES[this.assetType].hasTrainers);
    },
    releaseWarningMessage() {
      return ASSET_TYPES[this.assetType].releaseWarningMessage;
    },
    groupsKey() {
      return this.isBlueprint ? 'groups' : 'programme_groups';
    },
    headers() {
      let header = [
        {
          key: 'asset',
          title: this.assetPrettyName,
          type: 'object',
          required: true,
          text: x => x.name,
          filterable: true,
          sortBy: x => x.name,
        },
        {
          key: this.groupsKey,
          title: 'Groups',
          editable: true,
          type: this.isBlueprint ? 'multi-input' : 'multi-input-selection',
          required: true,
          filterable: true,
          options: this.programmeGroups,
        },
        {
          key: 'tags',
          title: 'Tags',
          type: 'html',
          required: false,
          filterable: true,
          editable: false,
          sortable: false,
          html: this.tagsDisplay,
        },
        {
          key: 'is_mandatory',
          title: 'Mandatory',
          editable: true,
          type: 'boolean',
          required: true,
          sortable: false,
        },
      ];
      if (!this.isBlueprint && this.additionalReleaseFields.length > 0) {
        header = header.concat(this.additionalReleaseFields.map(f => ({
          key: f.key,
          title: f.title,
          editable: true,
          type: f.type,
        })));
      }
      if (!this.isBlueprint && this.hasTrainers) {
        header.push({
          key: 'trainers',
          title: 'Trainers',
          editable: true,
          type: 'multi-input-selection',
          options: this.trainers,
        });
        header.push({
          key: 'primary_trainer',
          title: 'Primary Trainer',
          editable: true,
          type: 'dropdown',
          rowOptions: this.primaryTrainerOptions,
        });
      }
      header.push({
        key: 'remove',
        title: '<i class="fa fa-trash-alt" aria-hidden="true"></i>',
        type: 'action',
        editable: false,
        sortable: false,
        filterable: false,
        callback: this.handleRemove,
        html: '<button class="btn-remove" aria-label="Remove" title="Remove"><i class="fas fa-minus-circle"></i></button>',
      });
      return header;
    },

    primaryTrainerOptions() {
      return this.summary.map(row => row.trainers || []);
    },

    name() {
      return this.isBlueprint ? 'module blueprint' : 'module';
    },

    logPayload() {
      return this.isBlueprint ? { moduleBlueprintId: this.moduleId } : { moduleId: this.moduleId };
    },

    addAssetsEndpoint() {
      if (this.moduleType === 'module_blueprint') {
        return `${this.moduleTypeMeta.moduleApiRoot}/${this.moduleId}/assets`;
      }
      return `${this.moduleTypeMeta.moduleApiRoot}/${this.assetType}`;
    },

    availableAssets() {
      // Filter on name and tags
      return this.assets.filter(a => stringMatch(a.name, this.assetFilterText, true)
        || (a.tags || []).find(t => stringMatch(t, this.assetFilterText, true)));
    },

    addAssetsPayload() {
      if (this.moduleType === 'module_blueprint') {
        return this.summary.map(entry => ({
          groups: getOrNull(this.groupsKey, entry, []),
          asset_type: this.assetType,
          asset_id: entry.asset.id,
          is_mandatory: entry.is_mandatory,
          id: entry.id || null,
        }));
      }
      return {
        releases: this.summary.map(entry => {
          const r = {
            is_mandatory: entry.is_mandatory,
            groups: getOrNull(this.groupsKey, entry, []),
          };
          r[`${this.moduleType}_id`] = this.moduleId;
          r[`${this.assetType}_id`] = entry.asset.id;
          r.id = entry.id || null; // For already released assets - this is the module_asset_id
          this.additionalReleaseFields.forEach(f => {
            r[f.key] = this.formatFieldForJson(entry[f.key], f.type);
          });
          if (this.hasTrainers) {
            // Convert trainer names to trainer ids (from the list of trainers)
            r.trainers = getOrNull('trainers', entry, []).map(t => (
              { ...this.trainers.find(tr => tr.name === t), is_primary_trainer: entry.primary_trainer === t }
            ));
          }
          return r;
        }),
      };
    },
  },

  methods: {
    getRowClass(row) {
      if (!row.is_released) {
        return 'unreleased';
      }
      return '';
    },

    tagsDisplay(tags) {
      let tagHtml = '';
      if (tags && tags.length > 0) {
        for (let i = 0; i < tags.length; i++) {
          const tagList = `<span class="badge">${tags[i]}</span>\n`;
          tagHtml += tagList;
        }
      }
      return tagHtml;
    },

    formatFieldForJson(val, type) {
      if (val === undefined) {
        // JSON requires null, rather than undefined - for boolean convert to false
        return type === 'boolean' ? false : null;
      }
      if (type === 'boolean' && !val) {
        // Convert nulls to false for boolean fields
        return false;
      }
      if (type === 'date' || type === 'datetime') {
        return val ? this.formatDate(val) : null;
      }
      return val;
    },

    withdrawAssetEndpoint(moduleAssetId) {
      if (this.moduleType === 'module_blueprint') {
        return `${this.moduleTypeMeta.moduleApiRoot}/${this.moduleId}/assets`;
      }
      return `${this.moduleTypeMeta.moduleApiRoot}/${this.assetType}/${moduleAssetId}`;
    },

    addNewAsset(a) {
      this.$logger.info('Adding new asset to module', {
        moduleId: this.moduleId,
        assetId: a.id,
        assetName: a.name,
        assetType: this.assetType,
        isBlueprint: this.isBlueprint,
      }, true);
      const newAsset = {
        asset: {
          id: a.id,
          name: a.name,
        },
        tags: a.tags,
        is_mandatory: true,
      };
      if (!this.isBlueprint) {
        newAsset.programme_groups = [];
        if (this.hasTrainers) {
          newAsset.trainers = [];
        }
      } else {
        newAsset.groups = [];
      }
      this.summary.unshift(newAsset);
    },

    clearNewAssets() {
      const clearedCount = this.summary.filter(a => !a.is_released).length;
      this.$logger.info('Clearing new assets from module', {
        moduleId: this.moduleId,
        clearedCount,
        isBlueprint: this.isBlueprint,
      }, true);
      this.summary = this.summary.filter(a => a.is_released);
    },

    updateSummary() {
      // Don't overwrite existing new asset in summary
      this.summary = flatten([this.summary, this.releasedAssets.map(a => {
        const summaryAsset = this.summary.find(s => s.asset.id === a[`${this.assetType}_id`]);
        if (summaryAsset) {
          // Don't overwrite an existing released asset in the summary - want to keep any changes
          return summaryAsset;
        }
        const releasedAsset = {
          id: a.id,
          asset: {
            id: a[`${this.assetType}_id`],
            name: a.name,
          },
          tags: a.tags,
          is_mandatory: a.is_mandatory,
          is_released: true,
        };
        if (!this.isBlueprint) {
          // For non-blueprints there are additional fields
          this.additionalReleaseFields.forEach(f => {
            releasedAsset[f.key] = a[f.key];
          });
          if (this.riskOfDataLoss) {
            releasedAsset[ASSET_TYPES[this.assetType].dataLossField] = a[ASSET_TYPES[this.assetType].dataLossField];
          }
          if (this.hasTrainers) {
            releasedAsset.trainers = getOrNull('teaching_team', a, []).map(t => (t.trainer_name));
            releasedAsset.primary_trainer = getOrNull('teaching_team', a, []).find(t => t.is_primary_trainer)?.trainer_name;
          }
        }
        releasedAsset[this.groupsKey] = getOrNull(this.groupsKey, a, []);
        return releasedAsset;
      })]);
    },

    withdrawalConfirmationToast(row) {
      this.confirmationToast = this.$toasted.show(
        this.$ktoast.confirmToast(this.withdrawalConfirmationText, 'warning', () => {
          this.withdrawAsset(row);
        }),
      );
    },

    handleRemove(row, index) {
      if (row.is_released) {
        if (!this.isBlueprint) {
          // Check if there is a risk of data loss when withdrawing the module asset
          if (this.riskOfDataLoss) {
            const assetHasData = row[ASSET_TYPES[this.assetType].dataLossField];
            if (assetHasData) {
              // Data would be lost - do not remove asset
              this.$ktoast.warning(this.dataLossWarningText);
              return;
            }
          }
          // Confirm withdrawal if required
          if (this.requireWithdrawalConfirmation) {
            this.withdrawalConfirmationToast(row);
            return;
          }
          // No risk of data loss and no confirmation required
          this.withdrawAsset(row);
        } else {
          // Blueprint so always safe to withdraw
          this.withdrawAsset(row);
        }
      } else {
        // Not released so just remove from the summary array
        this.summary = this.summary.filter((a, i) => !(i === index));
      }
    },

    getWithdrawAssetPayload(modAssetId, asset) {
      if (this.moduleType === 'module_blueprint') {
        return {
          data: [
            {
              // eslint-disable-next-line camelcase
              id: modAssetId,
              asset_type: this.assetType,
              asset_id: asset.id,
              chapter: null,
              number: null,
            },
          ],
        };
      }
      return undefined;
    },

    getAssets() {
      this.$logger.info(`Getting all ${this.plural(this.assetPrettyName)} available for release`);
      this.assetsReady = false;
      this.$http.get(`/api/curriculum/admin/${this.assetType}?active_only=true`).then(result => {
        this.$logger.info(`Got ${this.plural(this.assetPrettyName)}`);
        this.assets = Object.values(result.data)[0];
        this.assets?.map(a => ({ ...a, groups: [] }));
      }).catch(err => {
        if (this.$http.errIn(err, [404])) {
          this.$logger.warn(`No ${this.plural(this.assetPrettyName)} found`);
        } else {
          this.showError(err);
          this.$logger.autowarn(`Not able to get ${this.plural(this.assetPrettyName)}`, undefined, err);
        }
      }).then(() => {
        this.assetsReady = true;
      });
    },

    addAssets() {
      this.updating = true;
      this.$logger.info(`Adding ${this.plural(this.assetPrettyName)} to ${this.name}`, { ...this.logPayload, payload: this.addAssetsPayload }, true);
      this.$http.put(this.addAssetsEndpoint, this.addAssetsPayload).then(res => {
        this.$logger.info(`Successfully added ${this.plural(this.assetPrettyName)} to ${this.name}`, {
          ...this.logPayload,
          payload: this.addAssetsPayload,
          response: res.data,
        });
        this.$ktoast.info(`${this.assetPrettyName}(s) added`);
        this.triggerCallback(res);
      }).catch(err => {
        this.$logger.error(`Error adding ${this.plural(this.assetPrettyName)} to ${this.name}`, {
          ...this.logPayload,
          payload: this.addAssetsPayload,
          error: err.message,
        }, err);
        this.showError(err);
      }).then(() => {
        this.updating = false;
      });
    },

    withdrawAsset(row) {
      this.updating = true;
      this.$logger.info(`Withdrawing ${this.assetPrettyName} from ${this.name}`, {
        ...this.logPayload,
        assetId: row.asset.id,
        moduleAssetId: row.id,
        assetName: row.asset.name,
        assetType: this.assetType,
        hasData: row[ASSET_TYPES[this.assetType].dataLossField],
      }, true);
      this.$http.delete(this.withdrawAssetEndpoint(row.id), this.getWithdrawAssetPayload(row.id, row.asset)).then(res => {
        this.$logger.info(`${this.assetPrettyName} withdrawn from ${this.name}`, {
          ...this.logPayload,
          assetId: row.asset.id,
          moduleAssetId: row.id,
          response: res.data,
        });
        this.$ktoast.info(`${this.assetPrettyName} withdrawn`);
        this.triggerCallback(res);
      }).catch(err => {
        this.$logger.error(`Error withdrawing ${this.assetPrettyName} from ${this.name}`, {
          ...this.logPayload,
          assetId: row.asset.id,
          moduleAssetId: row.id,
          error: err.message,
        }, err);
        this.showError(err);
      }).then(() => {
        this.updating = false;
      });
    },

    triggerCallback(response) {
      if (!ASSET_TYPES[this.assetType].releaseCallbackData) {
        this.$emit('assets-updated');
        return;
      }
      const callbackData = response.data[ASSET_TYPES[this.assetType].releaseCallbackData];
      this.$emit('assets-updated', this.assetType, callbackData);
    },
  },
};
</script>

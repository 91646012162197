<template>
  <k-modal
    id="update-programme-modal"
    v-if="item"
    :show="show"
    @close="$emit('close')">
    <template #header>
      <h3><i class="fas fa-edit"></i>Edit - {{item.name}}</h3>
    </template>
    <template #body>
      <div class="programme-details">
        <div class="col-md-8">
          <label for="programme-title-input">Name</label>
          <input id="programme-title-input" v-model="newItemName" class="form-control">
        </div>
        <div class="col-md-4" v-if="!isProgrammeBlueprint">
          <k-date-picker label="Planned End Date" v-model="newItemPlannedEndDate" inputId="end-date-input"></k-date-picker>
        </div>
        <div class="col-md-12 programme-description">
          <label for="editTextEditor">Description</label>
          <k-text-editor id="editTextEditor"
            v-model="newItemDescription"
            :customToolbar="[['bold', 'italic', 'underline'], ['link'], [{ list: 'ordered' }, { list: 'bullet' }]]"
            :placeholder="'Briefly describe the programme...'"
          ></k-text-editor>
        </div>
      </div>
      <div class="programme-inputs">
        <div class="col-md-6" v-if="isProgrammeBlueprint">
          <k-word-tags
            label="Tags"
            v-model="newItemTags"
            textInputPlaceholder="Type tags here">
          </k-word-tags>
        </div>
        <div class="col-md-6" v-if="!isProgrammeBlueprint">
          <k-word-tags
            label="Groups"
            v-model="newItemGroups"
            textInputPlaceholder="Type groups here">
          </k-word-tags>
          <ul class="k-word-tags-list">
            <li class="k-word-tags-item badge" v-for="group in existingGroups" :key="group">
              {{group}}
            </li>
          </ul>
          <p class="info">
            <i class="fas fa-exclamation-triangle icon-warning"></i> You will not be able to change these once they are set
          </p>
        </div>
        <div class="col-md-6" v-if="products.length">
          <span>Products</span>
          <k-dropdown
            v-model="newItemProductID"
            placeholder="Select a product"
            :options="products"
            :clear-option=true
          ></k-dropdown>
        </div>
      </div>
      <div class="col-md-12 programme-checkbox">
        <span>Include in reporting:</span>
        <label class="k-custom-checkbox" for="include-in-report-checkbox">
          <input type="checkbox" class="hidden-checkbox" v-model="newItemIncludeInReport" id="include-in-report-checkbox">
          <span class="k-custom-checkbox-box"></span>
          <span class="k-custom-checkbox-text">Add learner data to the Tableau dashboards</span>
        </label>
      </div>
    </template>
    <template #footer>
      <button class="btn btn-outlined" @click="$emit('close')">Cancel</button>
      <button @click="updateProgrammeEntity" class="btn btn-success send-btn">Update</button>
    </template>
  </k-modal>
</template>

<style>
#update-programme-modal .modal-body {
  min-height: 370px;
}

#update-programme-modal .modal-container {
  max-width: 80vw;
}

#update-programme-modal .programme-inputs .k-word-tags {
  margin-bottom: 15px;
}
</style>

<style scoped>
textarea {
  max-width: 100%;
  resize: none;
}

.programme-description {
  margin: 15px 0;
}

.programme-checkbox {
  margin-top: 15px;
}

.programme-checkbox input[type="checkbox"] {
  margin: 0;
}

.k-word-tags-list {
  list-style: none;
  margin-top: 10px;
  padding-left: 0;
}

.k-word-tags-list > li {
  color: var(--kate-type-white) !important;
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import TimeMixin from '@mixins/time-mixins';
import KModal from '@base-components/k-modal.vue';
import KDatePicker from '@base-components/k-date-picker.vue';
import KDropdown from '@base-components/k-dropdown.vue';
import KTextEditor from '@base-components/k-text-editor.vue';
import KWordTags from '../components/word-tags.vue';

export default {
  components: {
    KWordTags,
    KModal,
    KDatePicker,
    KDropdown,
    KTextEditor,
  },
  mixins: [ErrorMixin, TimeMixin],
  props: {
    products: {
      type: Array,
      default: () => [],
    },
    item: {
      type: Object,
    },
    itemType: {
      type: String,
    },
  },
  data() {
    return {
      newItemName: undefined,
      newItemDescription: undefined,
      newItemProductID: undefined,
      newItemIncludeInReport: undefined,
      newItemPlannedEndDate: undefined,
      newItemGroups: [],
      newItemTags: [],
      updateInProgress: false,
    };
  },
  watch: {
    item() {
      if (this.item) {
        this.newItemName = this.item.name;
        this.newItemDescription = this.item.description || '';
        this.newItemIncludeInReport = this.item.include_in_reporting;
        this.newItemProductID = this.item.product_id;
        this.newItemPlannedEndDate = this.item.planned_end_date || undefined;
        this.newItemGroups = [];
        this.newItemTags = this.item.tags || [];
      } else {
        this.newItemName = undefined;
        this.newItemDescription = undefined;
        this.newItemIncludeInReport = undefined;
        this.newItemProductID = undefined;
        this.newItemPlannedEndDate = undefined;
        this.newItemGroups = [];
        this.newItemTags = [];
      }
    },
    updateInProgress() {
      if (this.updateInProgress) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },
  computed: {
    isProgrammeBlueprint() {
      // programme is blueprint but it is being used as place holder at lot of places
      return this.itemType === 'programme';
    },
    show() {
      return Boolean(this.item);
    },
    existingGroups() {
      if (!this.item.programme_groups) {
        return [];
      }
      return this.item.programme_groups.map(group => group.name) || [];
    },
  },

  methods: {
    updateProgrammeEntity() {
      const payload = this.buildPayload();
      const endpoint = this.isProgrammeBlueprint
        ? `/api/curriculum/blueprints/programmes/${this.item.id}`
        : `/api/curriculum/programmes/${this.item.id}`;
      const infoLogMsg = this.isProgrammeBlueprint ? 'Updating programme blueprint' : 'Updating programme';
      const toastMsg = this.isProgrammeBlueprint ? 'Programme blueprint meta updated' : 'Programme updated';
      this.$logger.info(infoLogMsg, { itemId: this.item.id, payload }, true);
      this.updateInProgress = true;
      this.$http.put(endpoint, payload).then(() => {
        this.$logger.info('Successfully updated item', { itemId: this.item.id, payload });
        this.$ktoast.success(toastMsg);
        this.$emit('item-updated');
        this.$emit('close');
      }).catch(err => {
        this.showError(err);
        this.$logger.error('Error updating item', { itemId: this.item.id, payload }, err);
      }).then(() => {
        this.updateInProgress = false;
      });
    },

    buildPayload() {
      return this.isProgrammeBlueprint
        ? {
          name: this.newItemName.trim(),
          description: this.newItemDescription || null,
          tags: this.newItemTags || [],
          product_id: this.newItemProductID || null,
          include_in_reporting: this.newItemIncludeInReport,
        }
        : {
          name: this.newItemName.trim(),
          description: this.newItemDescription,
          groups: this.newItemGroups || [],
          include_in_reporting: this.newItemIncludeInReport,
          product_id: this.newItemProductID ? this.newItemProductID : null,
          planned_end_date: this.newItemPlannedEndDate ? this.formatDate(this.newItemPlannedEndDate) : undefined,
        };
    },
  },
};
</script>

<template>
  <k-panel :title="panelTitle" :showContent="show" @toggle="(val) => show = val">
    <template #body>
      <slot name="preContent"></slot>
      <div class="panel panel-body">
        <div class="progress-container">
            <div class="content-container">
              <k-table
                class="learner-progress-table"
                v-if="learnerData.length > 0"
                :rows="kableRows"
                :headers="kableHeaders"
                :defaultHeaders="visibleHeaders"
                :max="20"
                :hideable="true"
                :panel="false"
                :downloadable="true"
                :filename="panelTitle"
              >
              </k-table>
              <empty-placeholder
                v-else
                title="No learners found"
                info="No learners were found. If you feel something is missing please contact us."
              ></empty-placeholder>
            </div>
        </div>
      </div>
    </template>
  </k-panel>
</template>

<style>
.learner-progress-table .k-table-main-container {
  overflow-x: unset;
  overflow-y: unset;
}
</style>
<style scoped>
.panel {
  margin-top: 15px;
}

.learner-progress-table {
  margin-left: 35px;
}

.progress-container {
  display: flex;
  gap: 25px;
}

.content-container {
  overflow-x: auto;
  flex: 1;
}
</style>
<script>
import KPanel from '@base-components/k-panel.vue';
import KTable from '@base-components/k-table.vue';
import ErrorMixin from '@mixins/error-mixins';
import PageReadyMixin from '@mixins/page-ready-mixin';
import PluralMixin from '@mixins/plural-mixin';
import EmptyPlaceholder from '@base-components/empty-placeholder.vue';
import formatId from '../../modules/format-user-id';

export default {
  name: 'EmployerLearnerTable',

  components: {
    KPanel,
    KTable,
    EmptyPlaceholder,

  },

  mixins: [ErrorMixin, PageReadyMixin, PluralMixin],

  props: {
    panelTitle: {
      type: String,
      required: true,
    },
    statusType: {
      type: Array,
      required: true,
    },
    learnerData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      show: true,
    };
  },

  computed: {
    kableHeaders() {
      const headers = {
        learner: {
          name: 'User ID',
          sortable: true,
        },
        name: {
          name: 'Name',
          type: 'string',
          filterable: true,
          sortable: true,
          hideable: false,
          options: {
            color: false,
            tooltip: true,
            tooltipText: 'A tooltip next to learner means they dropped out or completed another programme',
          },
        },
        programme: {
          name: 'Programmes',
          filterable: false,
          hasMultiSelectDropdown: true,
          sortable: false,
        },
        academic_year: {
          name: 'Academic Year',
          sortable: true,
          type: 'string',
          filterable: true,
        },
        learner_progress: {
          name: 'Progress',
          sortable: true,
          type: 'percentage',
          options: {
            color: false,
            tooltip: true,
            tooltipText: 'Aptem learning plan progress % of expected learning plan progress vs current learning progress',
          },
        },
        ...(this.statusType.includes('Continuing Learning') || this.statusType.includes('On a Break')
          ? {
            rag: {
              name: 'RAG Status',
              sortable: true,
              type: 'rag_status',
              hasDropdown: true,
              options: {
                color: true,
                tooltip: true,
                tooltipText: 'Current RAG status of the learner',
              },
            },
          }
          : {}),
        ilrs_grading_outcome: {
          name: 'Grading Outcome',
          sortable: true,
          type: 'string',
          filterable: true,
        },
        ilrs_aim_completion_status: {
          name: 'ILR Status',
          sortable: true,
          type: 'string',
          hasDropdown: true,
          options: {
            color: true,
            tooltip: true,
            tooltipText: 'Current learner status according to the ILR',
          },
        },
        ilrs_aim_outcome: {
          name: 'ILR Outcome',
          sortable: true,
          type: 'string',
          filterable: false,
          options: {
            color: true,
            tooltip: true,
            tooltipText: 'Learner outcome according to the ILR',
          },
        },
        ilrs_programme_start_date: {
          name: 'Programme Start Date',
          sortable: true,
          type: 'timestamp',
          filterable: false,
          options: {
            color: true,
            tooltip: true,
            tooltipText: 'Start date for the programmes',
          },
        },
        ilrs_planned_end_date: {
          name: 'Planned End Date',
          sortable: true,
          type: 'timestamp',
          filterable: false,
          options: {
            color: true,
            tooltip: true,
            tooltipText: 'This is the date the learner should enter gateway. This signals the end of the funded period for a learner and does not include the EPA period that follows',
          },
        },
        ilrs_actual_end_date: {
          name: 'Actual End Date',
          sortable: true,
          type: 'timestamp',
          filterable: false,
          options: {
            color: true,
            tooltip: true,
            tooltipText: this.getActualEndDateTooltip(),
          },
        },
      };
      return headers;
    },

    visibleHeaders() {
      return ['name', 'latest_timestamp', 'programme', 'academic_year', 'learner_progress', 'rag', 'ilrs_grading_outcome', 'ilrs_aim_completion_status', 'ilrs_aim_outcome', 'ilrs_programme_start_date', 'ilrs_planned_end_date', 'ilrs_actual_end_date'];
    },

    getFilteredLearners() {
      return this.learnerData.filter(val => this.statusType.includes(val.ilrs_aim_completion_status));
    },

    kableRows() {
      return this.getFilteredLearners.map(val => ({
        learner: formatId(val.user_id),
        name: val.user_name,
        programme: val.programme,
        learner_progress: val.learner_progress,
        ...val,
      }));
    },
  },
  methods: {
    getActualEndDateTooltip() {
      // panelTitle: Completed Learners and statusType: Completed Learning;
      // panelTitle: Withdrawn Learners and statusType: Withdrawn
      // panelTitle: Active Learners and statusType: Continuing Learning or On a Break (default)
      if (this.statusType.includes('Completed Learning')) {
        return 'The actual date the learner entered gateway';
      } if (this.statusType.includes('Withdrawn')) {
        return 'The last day of evidenced learning';
      }
      return 'The actual date the learner entered gateway or the last day of evidenced learning for those on a BiL';
    },
  },
};
</script>

<template>
  <div v-if="ready">
    <manage-programme-modules
      :programme="programme"
      @update-programme="$emit('refresh-programme')"
      @changes="changes => this.pageHasUnsavedChanges = changes"
    ></manage-programme-modules>
  </div>
</template>

<script>
import PageReadyMixin from '@mixins/page-ready-mixin';
import NavControlMixin from '@mixins/nav-control-mixin';
import ManageProgrammeModules from './modules/manage-programme-modules.vue';

export default {
  components: {
    ManageProgrammeModules,
  },

  mixins: [PageReadyMixin, NavControlMixin],

  props: {
    programme: {
      type: Object,
    },
    programmeReady: {
      type: Boolean,
    },
  },

  data() {
    return {
      mounted: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.$emit('refresh-programme');
  },

  mounted() {
    this.mounted = true;
  },

  beforeRouteLeave(to, from, next) {
    return this.beforeLeave(next);
  },

  computed: {
    programmeId() {
      return Number(this.$route.params.programmeId);
    },
    ready() {
      return this.programmeReady && this.mounted;
    },
  },

  methods: {
    registerCrumbs() {
      this.$crumbs.register([
        {
          text: 'Manage Programmes',
          path: {
            name: 'programmes_overview',
          },
        },
        {
          text: this.programme.name || this.programmeId,
          active: true,
        },
      ]);
    },
    pageReadyCallback() {
      this.registerCrumbs();
    },
  },
};
</script>

<template>
  <k-modal class="update-calendar-event-modal" v-if="calendarEvent"
    :show="show"
    :closeOnClickAway="false"
    @close="$emit('close')">
    <template #header>
      <h3><i class="fas fa-edit"></i>Edit Event - {{eventNameEditing}}</h3>
    </template>
    <template #body>
      <div class="calendar-event-form-container col-md-7 col-xs-12">
        <fieldset class="form-entry">
          <label for="cal-title-input">Title</label>
          <input id="cal-title-input" v-model="newCalendarEventName" class="form-control" placeholder="Type Event name here...">
        </fieldset>
        <fieldset class="form-group check-inputs">
          <!-- // jscpd:ignore-start -->
          <label for="live">
            <input type="checkbox" id="live" value="live" v-model="completionTypeLive">Live
          </label>
          <label for="recorded">
            <input type="checkbox" id="recorded" value="recorded" v-model="completionTypeRecorded">Recorded
          </label>
          <!-- // jscpd:ignore-end -->
        </fieldset>
        <fieldset class="form-entry">
          <label for="cal-description-input">Description</label>
          <textarea v-model="newCalendarEventDescription"
            id="cal-description-input"
            class="form-control"
            type="textarea"
            rows="3"
            placeholder="Briefly describe the Event">
          </textarea>
        </fieldset>
        <word-tags v-model="newCalendarEventTags"
          dropdownPlaceholder="Modules"
          textInputPlaceholder="Type tag here..."
          dropdownSearchPlaceholder="Search by module name or standardised code">
        </word-tags>
      </div>
    </template>
    <template #footer>
      <button @click="updateCalendarEvent" class="btn btn-success send-btn" :disabled="!validForm" aria-label="Update" title="Update">Update</button>
    </template>
  </k-modal>
</template>

<style>
.update--calendar-event-modal .modal-body {
  display: flex;
  flex-direction: row;
  gap: 15px;
}

.update-calendar-events-modal .k-word-tags > form > button {
  height: 38px;
}
</style>

<style scoped>
.calendar-event-form-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 15px;
  gap: 15px;
}

.calendar-event-form-container form {
  margin-left: -15px;
}
</style>

<style scoped>
.check-inputs {
  display: flex;
  gap: 20px;
  align-items: center;
}

.check-inputs > label {
  cursor: pointer;
}

.check-inputs > label input {
  margin-right: 5px;
}

.check-inputs > label:hover {
  text-decoration: underline;
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import KModal from '@base-components/k-modal.vue';
import WordTags from '../../../components/word-tags.vue';

export default {
  components: {
    'k-modal': KModal,
    'word-tags': WordTags,
  },
  mixins: [ErrorMixin],
  props: {
    calendarEvent: {
      type: Object,
    },
  },
  data() {
    return {
      newCalendarEventName: undefined,
      newCalendarEventDescription: undefined,
      newCalendarEventTags: [],
      completionTypeLive: false,
      completionTypeRecorded: false,
    };
  },
  watch: {
    calendarEvent() {
      this.newCalendarEventName = this.calendarEvent ? this.calendarEvent.name : undefined;
      this.newCalendarEventDescription = this.calendarEvent ? this.calendarEvent.description : undefined;
      this.newCalendarEventTags = this.calendarEvent ? this.calendarEvent.tags || [] : [];
      this.completionTypeLive = this.calendarEvent ? this.calendarEvent.completion_types.indexOf('live') >= 0 : false;
      this.completionTypeRecorded = this.calendarEvent ? this.calendarEvent.completion_types.indexOf('recorded') >= 0 : false;
    },
  },
  computed: {
    show() {
      return Boolean(this.calendarEvent);
    },
    eventNameEditing() {
      return this.newCalendarEventName ? this.newCalendarEventName : this.calendarEvent.name;
    },
    validTitle() {
      return this.newCalendarEventName ? this.newCalendarEventName.trim().length > 0 : false;
    },
    validForm() {
      return Boolean(this.validTitle && (this.completionTypeLive || this.completionTypeRecorded));
    },
  },
  methods: {
    completionTypes() {
      const cts = [];
      if (this.completionTypeRecorded) {
        cts.push('recorded');
      }
      if (this.completionTypeLive) {
        cts.push('live');
      }
      return cts;
    },

    updateCalendarEvent() {
      const updatePayload = {
        name: this.newCalendarEventName,
        description: this.newCalendarEventDescription,
        tags: this.newCalendarEventTags,
        completion_types: this.completionTypes(),
      };
      this.$logger.info('Updating calendar event', { calendarEventId: this.calendarEvent.id, updatePayload }, true);
      this.$http.put(`/api/curriculum/admin/calendar_event/${this.calendarEvent.id}`, updatePayload).then(() => {
        this.$logger.info('Successfully updated calendar event', { calendarEventId: this.calendarEvent.id });
        this.$ktoast.success('Calender Event updated');
        this.clearFields();
        this.$emit('update');
        this.$emit('close');
      }).catch(err => {
        this.showError(err);
        this.$logger.error('Error updating calendar event', { calendarEventId: this.calendarEvent.id, updatePayload }, err);
      });
    },

    clearFields() {
      this.newCalendarEventName = undefined;
      this.newCalendarEventDescription = undefined;
      this.newCalendarEventTags = [];
      this.completionTypeLive = false;
      this.completionTypeRecorded = false;
    },
  },
};
</script>

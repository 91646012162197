<template>
    <div>
        <h2>{{ title }}</h2>
        <k-dropdown
          v-if="availableOptions.length"
          :placeholder="dropdownPlaceholder"
          :options="availableOptions"
          v-model="selectedIndex"
        ></k-dropdown>
        <k-table
          v-if="kableRows.length"
          :rows="kableRows"
          :headers="kableHeaders"
          :hideable="false"
          :class-func="getRowClass"
          @clicked="tableCallback"
          :panel="false"
        ></k-table>
        <button
          v-if="pendingItems.length"
          @click="emitUpdateItems"
          class="btn btn-success send-btn"
        >{{ buttonText }}</button>
        <p class="display-message-area">{{ message }}</p>
    </div>
</template>

<style>
.display-message-area {
  padding-top: 10px;
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import KDropdown from '@base-components/k-dropdown.vue';
import KTable from '@base-components/k-table.vue';

export default {
  name: 'KUserRestrictedAccess',
  components: {
    KDropdown,
    KTable,
  },

  mixins: [ErrorMixin],

  props: {
    user: {
      type: Object,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    dropdownPlaceholder: {
      type: String,
      required: true,
    },
    buttonText: {
      type: String,
      required: true,
    },
    message: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      selectedIndex: undefined,
      pendingItems: [],
    };
  },

  computed: {
    dataProperty() {
      return this.title.toLocaleLowerCase() === 'employers' ? 'employers' : 'programmes';
    },
    kableHeaders() {
      return {
        name: {
          name: this.title,
        },
        delete: {
          name: 'Delete',
          type: 'action',
        },
      };
    },
    kableRows() {
      let existingRows = [];
      existingRows = this.user[this.dataProperty]?.map((val, index) => ({
        id: val.id,
        name: val.name,
        pending: false,
        delete: {
          key: index,
          text: '<div style="text-align: center; color: var(--kate-danger-alt)"><i class="fas fa-minus-circle"></i></div>',
        },
      })) || [];

      const pendingRows = this.pendingItems?.map((val, index) => ({
        id: val.id,
        name: val.name,
        pending: true,
        delete: {
          key: index,
          text: '<div style="text-align: center; color: var(--kate-danger-alt)"><i class="fas fa-minus-circle"></i></div>',
        },
      })) || [];
      return existingRows.concat(pendingRows);
    },
    availableOptions() {
      if (this.user) {
        const currentItemsIdx = this.user[this.dataProperty]?.map(x => x.id) || [];
        const pendingItemsIdx = this.pendingItems?.map(x => x.id) || [];
        const available = this.items.filter(x => currentItemsIdx.concat(pendingItemsIdx).indexOf(x.id) === -1);
        return available.map(x => ({
          ...x,
          name: x.name.replaceAll('-', ' '),
        }));
      }
      return [];
    },
  },

  watch: {
    selectedIndex() {
      if (this.selectedIndex) {
        const selectedItem = this.items.find(option => option.id === this.selectedIndex);
        if (selectedItem && !this.pendingItems.map(x => x.id)?.includes(this.selectedIndex)) {
          this.pendingItems.push(selectedItem);
        }
      }
    },
  },

  methods: {
    reset() {
      this.pendingItems = [];
      this.selectedIndex = undefined;
    },
    confirmMessage() {
      return `Are you sure you want to remove this user's access to this ${this.title.toLocaleLowerCase()}?`;
    },
    confirmUpdate(payload) {
      this.$ktoast.confirmToast(this.confirmMessage(), 'warning', () => this.$emit('remove-item', payload), undefined);
    },
    getRowClass(row) {
      if (row.pending) {
        return 'pending';
      }
      return 'existing';
    },
    tableCallback(key, index, row, column) {
      if (column === 'delete') {
        this.selectedIndex = undefined;
        const pendingIndex = this.pendingItems.map(x => x.name).indexOf(row.name);
        if (pendingIndex !== -1) {
          this.pendingItems.splice(pendingIndex, 1);
        } else {
          this.confirmUpdate(row.id);
        }
      }
    },
    emitUpdateItems() {
      this.$logger.info(`Emitting update for ${this.title} to user`, { privilegedUserId: this.user.id });
      const itemsToAdd = this.pendingItems.map(x => x.name);
      const itemsToAddIds = this.items.filter(x => itemsToAdd.indexOf(x.name) !== -1).map(x => x.id);
      this.$emit('update-items', itemsToAddIds);
      this.reset();
    },
  },
};
</script>

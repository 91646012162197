<template>
  <k-panel title="Add Competency"
    icon='fa-list-check'
    :showContent="showContent"
    @toggle="showContent = $event"
  >
    <template #body>
      <div class="add-competency-form">
        <div class="add-competency-details">
            <fieldset class="form-programme form-entry">
                <label for="add-competency-category-input">Category</label>
                <k-dropdown id="add-competency-category-input" type="text" placeholder="Category" :options="categoryOptions" v-model="formCategory" :showSearch="false"></k-dropdown>
            </fieldset>
            <fieldset class="form-programme form-entry">
                <label for="add-competency-label-input">Label</label><br>
                <small class="info"><i>K1, K2, S1, B1 etc...</i></small>
                <input id="add-competency-label-input" type="text" class="form-control" placeholder="Type label here..." v-model="formLabel">
                <span v-if="labelTaken" class="info form-label-warning">This label is already in use. Please choose another.</span>
              </fieldset>
            <fieldset class="form-programme form-entry">
                <label for="add-competency-description-input">Description</label>
                <textarea id="add-competency-description-input" class="form-control" placeholder="Type description here..." v-model="formDescription"></textarea>
            </fieldset>
        </div>
        <div class="add-competency-actions">
            <button class="btn btn-primary add-competency-button" @click="addCompetency" :disabled="!validForm">
                <i class="fas fa-plus"></i> Add Competency
            </button>
        </div>
      </div>
    </template>
  </k-panel>
</template>

<style scoped>
.add-competency-details {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.add-competency-actions {
  margin-top: 1rem;
}

.form-label-warning {
  color: var(--kate-warning);
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import KPanel from '@base-components/k-panel.vue';
import KDropdown from '@base-components/k-dropdown.vue';

export default {
  components: {
    KPanel,
    KDropdown,
  },

  mixins: [ErrorMixin],

  props: {
    programme: {
      type: Object,
      required: true,
    },
    isBlueprint: {
      type: Boolean,
      default: false,
    },
    competencies: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update'],
  data() {
    return {
      showContent: false,
      formCategory: 'Knowledge',
      formLabel: '',
      labelTaken: false,
      formDescription: '',
      categoryOptions: [
        { id: 'Knowledge', name: 'Knowledge' },
        { id: 'Skill', name: 'Skill' },
        { id: 'Behaviour', name: 'Behaviour' },
      ],
    };
  },
  watch: {
    formLabel(newVal) {
      this.labelTaken = this.competencies.some(record => record.label.toLowerCase() === newVal.toLowerCase());
    },
  },
  methods: {
    addCompetency() {
      this.$logger.info(`Adding ${this.entityName} competency`, {
        category: this.formCategory,
        label: this.formLabel,
        description: this.formDescription,
      });
      this.$Loading.start();
      this.$http.post(this.apiEndpoint, {
        category: this.formCategory,
        label: this.formLabel,
        description: this.formDescription,
      })
        .then(() => {
          this.$logger.info(`Successfully added ${this.entityName} competency`, {
            category: this.formCategory,
            label: this.formLabel,
            description: this.formDescription,
          });
          this.$ktoast.success(`${this.entityName} competency added`);
          this.clearFields();
          this.$emit('update');
        })
        .catch(err => {
          this.$logger.error(`Could not add ${this.entityName} competency`, err);
          this.showError(err);
        })
        .then(() => {
          this.$Loading.finish();
        });
    },
    clearFields() {
      this.formLabel = '';
      this.formDescription = '';
    },
  },
  computed: {
    apiEndpoint() {
      return `/api/curriculum/${this.isBlueprint ? 'blueprints/' : ''}programmes/${this.entityId}/competencies`;
    },
    entityId() {
      return Number(this.isBlueprint ? this.$route.params.programmeBlueprintId : this.$route.params.programmeId);
    },
    entityName() {
      return this.isBlueprint ? 'Programme blueprint' : 'Programme';
    },
    validForm() {
      return Boolean(this.formCategory && this.formLabel?.trim() && this.formDescription?.trim() && !this.labelTaken);
    },
  },
};
</script>

<template>
  <div class="achievement-item-container">
    <div class="achievement-item editable" v-if="editable">
      <div class="non-editable-content">
        <div class="achievement-image">
          <img :src="getBadgeImage(achievement)" alt="Badge">
        </div>
        <div class="achievement-meta">
          <div><h3>{{ achievement.name }}</h3></div>
          <div><p>{{ achievement.description }}</p></div>
          <div v-if="badgeNotLinkedToModule && achievementType==='badge'" class="badge-not-linked-warning">
            <i class="fas fa-exclamation-triangle"/> This badge is not yet used in any modules
          </div>
          <div v-else>
            <div class="tags" v-for="mod in achievement.modules"><span class="alt">{{ mod.name }}</span></div>
            <div class="tags" v-for="mod in achievement.programme_blueprint_modules"><span class="alt">{{ mod.name }}</span></div>
          </div>
        </div>
      </div>
      <div class="editable-content">
        <div v-if="achievementType==='badge'" class="score-change form-entry">
          <label for="achievement-score-input">Overall threshold score
            <input id="achievement-score-input" type="number" min="0" max="100" v-model="thresholdScore" @change="$emit('threshold-score', thresholdScore)"/>
            %
          </label>
        </div>
        <div class="remove-achievement">
          <k-tooltip :text="removeTooltipText">
            <button class="btn btn-danger" @click="confirmRemoval" :disabled="removeDisabled" aria-label="Remove" title="Remove">Remove</button>
          </k-tooltip>
        </div>
      </div>
    </div>
    <div class="achievement-item" v-else>
      <div class="achievement-image">
        <img :src="getBadgeImage(achievement)" alt="Badge">
      </div>
      <div class="achievement-meta">
        <h3>{{ achievement.name }}</h3>
        <p>{{ achievement.description }}</p>
      </div>
    </div>
  </div>
</template>

<style scoped>
.achievement-item-container {
  width: 100%;
}

.achievement-item.editable {
  justify-content: space-between;
}

.achievement-item,
.editable-content,
.non-editable-content {
  display: flex;
  gap: 20px;
  align-items: center;
}

.editable-content {
  display: flex;
  gap: 10px;
}

.achievement-image > img {
  height: auto;
  max-width: 60px;
}

.score-change.form-entry {
  width: 190px;
}

@media (max-width: 767px) {
  .achievement-item,
  .editable-content,
  .non-editable-content {
    flex-wrap: wrap;
  }
}

</style>

<script>
import KTooltip from '@base-components/k-tooltip.vue';
import { GOOGLE_CLOUD_STORAGE } from '../../constants';

export default {
  components: { KTooltip },
  props: {
    editable: {
      type: Boolean,
      default: false,
    },
    achievement: {
      type: Object,
    },
    achievementType: {
      type: String,
      default: 'badge',
    },
  },

  data() {
    return {
      thresholdScore: this.achievement.threshold_score || 100,
      confirmRemoveBadgeToast: undefined,
    };
  },

  computed: {
    badgeNotLinkedToModule() {
      return !this.achievement.modules?.length && !this.achievement.programme_blueprint_modules?.length;
    },

    removeTooltipText() {
      return this.removeDisabled ? 'This badge has already been awarded to learners and cannot be removed' : '';
    },

    removeDisabled() {
      return Boolean(this.achievement?.awarded_learner_count);
    },
  },

  methods: {
    getBadgeImage(achievement) {
      return `${GOOGLE_CLOUD_STORAGE}${achievement.resource_link}`;
    },

    confirmCallback() {
      this.$emit('remove-achievement');
    },

    confirmRemoval() {
      this.$ktoast.confirmToast(
        `On removing this ${this.achievementType}, it will be deleted from this programme and all modules in this programme.`,
        'warning',
        () => this.confirmCallback(),
      );
    },
  },
};
</script>

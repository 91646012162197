<template>
  <div>
    <feedback
      v-if="currentPage === 'feedback'"
      :layout="layout"
      :overall-score="overallScore"
      :current-timestamp="currentTimestamp"
      :commit="commit"
      :support-button="true"
      :sha="sha"
      :project-meta="projectMeta"
      :is-loading-feedback="!ready"
      :validation-result="validationResult"
      :evaluation-result="evaluationResult"
      :extra-point-result="extraPointResult"
      :marker-errors-result="markerErrorsResult"
      :quality-result="qualityResult"
      :informative-result="informativeResult"
      :allow-submission="false"
      :notebook="notebook"
      :percentile="percentile"
      ></feedback>
  </div>
</template>

<style>
.instruction-panel {
  padding: 30px 15px;
  background-color: var(--kate-background-alt-alpha) !important;
  text-align: center;
  color: var(--kate-type-light);
  margin-top: 30px;
}

/* ==========///// INTRO_NUMPY FIX \\\\\========== */
div[projectname] {
  width: 100%;
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import PageReadyMixin from '@mixins/page-ready-mixin';
import Feedback from '../../../feedback/feedback.vue';

export default {
  props: {
    projectMeta: Object,
    projectDetails: Object,
    notebook: Object,
  },

  components: {
    feedback: Feedback,
  },

  mixins: [ErrorMixin, PageReadyMixin],

  data() {
    return {
      currentPage: 'feedback',
      validationResult: undefined,
      evaluationResult: undefined,
      extraPointResult: undefined,
      qualityResult: undefined,
      informativeResult: undefined,
      markerErrorsResult: undefined,
      layout: undefined,
      overallScore: undefined,
      currentTimestamp: undefined,
      commit: undefined,
      ready: false,
      sha: undefined,
      percentile: undefined,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getStudentFeedback();
  },

  computed: {
    modulePakId() {
      return this.$route.params.modulePakId;
    },
    studentId() {
      return this.$route.params.studentId;
    },
    feedbackUrl() {
      if (this.projectMeta.runners && this.projectMeta.runners.jobs && this.projectMeta.runners.jobs.includes('extra_points')) {
        return `/api/stats/students/${this.studentId}/feedback/${this.modulePakId}?percentile=True`;
      }
      return `/api/stats/students/${this.studentId}/feedback/${this.modulePakId}`;
    },
  },

  methods: {
    getStudentFeedback() {
      this.$logger.info('Getting student feedback', { modulePakId: this.modulePakId, studentId: this.studentId }, true);
      this.ready = false;
      this.$http.get(this.feedbackUrl)
        .then(result => {
          this.$logger.info('Got student feedback', { modulePakId: this.modulePakId, studentId: this.studentId });
          this.layout = result.data.feedback.meta.layout;
          this.overallScore = result.data.feedback.overall_score;
          this.currentTimestamp = result.data.feedback.meta.date_timestamp;
          this.commit = result.data.feedback.meta.commit_title;
          this.sha = result.data.feedback.meta.commit_sha;
          this.validationResult = result.data.feedback.validation_result;
          this.evaluationResult = result.data.feedback.evaluation_result;
          this.extraPointResult = result.data.feedback.extra_points_result;
          this.markerErrorsResult = result.data.feedback.marker_errors;
          this.qualityResult = result.data.feedback.quality_result;
          this.informativeResult = result.data.feedback.informative_result;
          this.percentile = result.data.feedback.percentile;
        })
        .catch(err => {
          if (err.response.status !== 404) {
            this.$logger.error('Error getting student feedback', { modulePakId: this.modulePakId, studentId: this.studentId }, err);
            this.showError(err);
          } else {
            this.$logger.warn('Error getting student feedback', { modulePakId: this.modulePakId, studentId: this.studentId }, err);
          }
        })
        .then(() => {
          this.ready = true;
        });
    },
  },
};
</script>

<template>
  <div>
    <div class="questionnaire-prompt-list panel-body">
      <!-- Have to use the element in prompts for v-model directive otherwise parent elements don't watch
      prompts correctly and update in the expected way -->
      <!-- eslint-disable-next-line vue/valid-v-model -->
      <questionnaire-prompt v-for="(prompt, index) in prompts" v-model="prompts[index]"
        :number="index + 1"
        @remove="removePrompt(index)"
        @move-up="movePromptUp(index)"
        @move-down="movePromptDown(index)"
        :key="index"
      ></questionnaire-prompt>
    </div>
    <button class="btn btn-primary" @click="addNewPrompt" aria-label="Add prompt" title="Add prompt">Add Prompt</button>
  </div>
</template>

<style scoped>
.questionnaire-prompt-list {
  display: flex;
  flex-direction: column;
  padding: 0;
  gap: 15px;
}
</style>

<script>
import QuestionnairePrompt from './questionnaire-prompt.vue';

export default {
  components: {
    'questionnaire-prompt': QuestionnairePrompt,
  },
  props: {
    modelValue: {
      type: Array,
    },
  },
  data() {
    return {
      prompts: this.modelValue,
    };
  },
  watch: {
    modelValue() {
      this.prompts = this.modelValue;
    },
    prompts: {
      handler() {
        this.$emit('update:modelValue', this.prompts);
      },
      deep: true,
    },
  },
  computed: {
    orderedPrompts() {
      return this.prompts;
    },
  },
  methods: {
    addNewPrompt() {
      this.prompts.push({
        prompt: undefined,
        responseType: undefined,
        reviewType: undefined,
        enableAiFeedback: false,
        aiFeedbackContextPrompt: '',
      });
    },
    removePrompt(index) {
      this.prompts.splice(index, 1);
    },
    movePromptUp(index) {
      if (index > 0) {
        const temp = this.prompts[index - 1];
        this.prompts.splice(index - 1, 1, this.prompts[index]);
        this.prompts.splice(index, 1, temp);
      }
    },
    movePromptDown(index) {
      if (index < this.prompts.length - 1) {
        const temp = this.prompts[index + 1];
        this.prompts.splice(index + 1, 1, this.prompts[index]);
        this.prompts.splice(index, 1, temp);
      }
    },
  },
};
</script>

<template>
  <div class="questionnaire-prompt-container">
    <div class="questionnaire-prompt panel row">
      <div class="prompt-placement-buttons">
        <k-tooltip text="Move prompt up" text-align="center">
          <button @click="moveUp" title="Move prompt up" class="btn btn-outlined">
            <span class="fas fa-angle-up"></span>
          </button>
        </k-tooltip>
        <k-tooltip text="Move prompt down" text-align="center">
          <button @click="moveDown" title="Move prompt down" class="btn btn-outlined">
            <span class="fas fa-angle-down"></span>
          </button>
        </k-tooltip>
      </div>
      <div class="questionnaire-prompt-container">
        <div class="questionnaire-prompt-header">
          <h3>Question {{ number }}.</h3>
          <k-tooltip text="Remove prompt" text-align="center">
            <button title="Remove prompt" @click="removePrompt" class="btn btn-danger">
              <span class="fas fa-trash-alt"></span>
            </button>
          </k-tooltip>
        </div>
        <hr>
        <div class="detail-form col-md-6 col-xs-12">
          <label :for="`promptTextEditor-${$.uid}`">Description</label>
          <k-text-editor
            :id="`promptTextEditor-${$.uid}`"
            v-model="prompt"
          ></k-text-editor>
        </div>
        <div class="detail-form col-md-6 col-xs-12">
          <span>Response type</span>
          <k-dropdown :options="responseOptions" v-model="responseType" :showSearch="false"></k-dropdown>
        </div>
        <div class="detail-form col-md-6 col-xs-12">
          <span>Review type</span>
          <k-dropdown :options="reviewOptions" v-model="reviewType" :showSearch="false"></k-dropdown>
        </div>
        <div v-if="isText" class="detail-form col-md-6 col-xs-12 d-flex align-items-center">
          <div class="col-md-6">
            <span>Enable AI Feedback</span>
            <k-toggle v-model="enableAiFeedback" :class-id="`ai-${$.uid}`"></k-toggle>
          </div>
          <div v-if="enableAiFeedback" class="col-md-6">
            <button class="btn btn-outlined" @click="openFeedbackModal" :disabled="!testContextPrompt">
              Test Context Prompt
            </button>
          </div>
        </div>
        <div v-if="enableAiFeedback && isText" class="ai-feedback-container row">
          <div class="detail-form col-md-6 col-xs-12">
            <span>AI Feedback Context Prompt</span>
            <k-text-editor v-model="aiFeedbackContextPrompt" :class-id="`ai-context-prompt-${$.uid}`"></k-text-editor>
          </div>
          <div class="detail-form col-md-6 col-xs-12">
            <span>Test Answer</span>
            <k-text-editor v-model="currentResponse" :class-id="`ai-test-answer-${$.uid}`"></k-text-editor>
          </div>
        </div>
        <div v-if="isMultipleChoice" class="detail-form col-md-12">
          <span>Response choices</span>
          <answer-list v-model="choices" :setCorrect="false"></answer-list>
        </div>
        <div v-if="isMultipleChoice" class="detail-form col-md-12">
          <span>Choice amount
            <k-tooltip :text="tooltipText">
              <i class="fas fa-info-circle"></i>
            </k-tooltip>
          </span>
          <k-number-choice v-model="choiceAmount" :valueMin="1" :valueMax="choices?.length"></k-number-choice>
        </div>
      </div>
    </div>
      <questionnaire-feedback-modal
        :showModal="showFeedbackModal"
        :prompt="prompt"
        :aiFeedbackContextPrompt="aiFeedbackContextPrompt"
        :currentResponse="currentResponse"
        :isFeedbackTest="isFeedbackTest"
        @close="closeFeedbackModal"
      />
  </div>
</template>

<style>
.detail-form > .k-number-choice {
  display: flex;
}
</style>

<style scoped>
.questionnaire-prompt.panel {
  padding: 15px;
  display: flexbox;
  display: flex;
  flex-wrap: nowrap;
}

.questionnaire-prompt-header {
  display: flexbox;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 0 10px 15px;
}

.questionnaire-prompt-header + hr {
  margin: 15px;
  border-color: var(--kate-background-body-alpha);
}

.questionnaire-prompt-container {
  flex: 1;
}

.detail-form {
  margin: 15px 0;
}

.prompt-placement-buttons {
  display: flexbox;
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  flex-direction: column;
  gap: 15px;
  border-right: 1px solid var(--border-secondary);
  padding-right: 15px;
}

textarea {
  resize: vertical;
  min-height: 30px;
}

button i {
  margin-left: 5px;
}

.ai-feedback-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ai-feedback-container .detail-form {
  padding: 0 15px;
}

@media (max-width: 991px) {
  .questionnaire-prompt.panel {
    display: block;
  }

  .prompt-placement-buttons {
    flex-direction: row;
    padding: 0;
    border: 0;
  }

  .questionnaire-prompt-container {
    display: flex;
    flex-direction: column;
  }

  .ai-feedback-container {
    flex-direction: column;
  }
}
</style>

<script>
import KTooltip from '@base-components/k-tooltip.vue';
import KTextEditor from '@base-components/k-text-editor.vue';
import KDropdown from '@base-components/k-dropdown.vue';
import KNumberChoice from '@base-components/k-number-choice.vue';
import KToggle from '@base-components/forms/k-toggle.vue';
import AnswerList from '../../quizzes/quiz_creation/quiz-answer-list.vue';
import QuestionnaireFeedbackModal from '../../../../../feedback/components/questionnaire-feedback-modal.vue';

const RESPONSE_OPTIONS = [
  { value: 'LONG_TEXT', name: 'Long text' },
  { value: 'SHORT_TEXT', name: 'Short text' },
  { value: 'QUANTITATIVE_VALUE', name: 'Quantitative value' },
  { value: 'QUANTITATIVE_PERCENT', name: 'Quantitative percent' },
  { value: 'MULTIPLE_CHOICE', name: 'Multiple choice' },
];

const REVIEW_OPTIONS = [
  { value: 'COMMENT', name: 'Comment' },
  { value: 'SCORE', name: 'Score' },
  { value: 'APPROVAL', name: 'Approval' },
];

export default {

  components: {
    KDropdown,
    KTextEditor,
    KTooltip,
    KNumberChoice,
    AnswerList,
    KToggle,
    QuestionnaireFeedbackModal,
  },

  props: {
    modelValue: {
      type: Object,
    },
    number: {
      type: Number,
    },
  },

  data() {
    return {
      prompt: this.modelValue.prompt,
      responseType: this.getResponseTypeIndex(this.modelValue.responseType) || 0,
      reviewType: this.getReviewTypeIndex(this.modelValue.reviewType) || 0,
      choices: this.modelValue?.answers?.choices,
      choiceAmount: this.modelValue?.answers?.choice_amount || null,
      enableAiFeedback: this.modelValue?.enableAiFeedback || false,
      aiFeedbackContextPrompt: this.modelValue?.aiFeedbackContextPrompt || '',
      currentResponse: this.modelValue?.currentResponse || '',
      showFeedbackModal: false,
      isFeedbackTest: false,
    };
  },

  watch: {
    modelValue() {
      this.prompt = this.modelValue.prompt;
      this.responseType = this.getResponseTypeIndex(this.modelValue.responseType);
      this.reviewType = this.getReviewTypeIndex(this.modelValue.reviewType);
      this.choices = this.modelValue?.answers?.choices;
      this.choiceAmount = this.modelValue?.answers?.choice_amount;
      this.enableAiFeedback = this.modelValue?.enableAiFeedback;
      this.aiFeedbackContextPrompt = this.modelValue?.aiFeedbackContextPrompt;
      this.currentResponse = this.modelValue?.currentResponse;
    },
    questionnairePrompt() {
      this.$emit('update:modelValue', Object.assign(this.modelValue, this.questionnairePrompt));
    },
  },

  computed: {
    tooltipText() {
      return 'The number of choices that a learner is able to select. If not set, a learner can select as many of the choices as they want';
    },
    isMultipleChoice() {
      return this.modelValue.responseType === 'MULTIPLE_CHOICE';
    },
    isText() {
      return RESPONSE_OPTIONS[this.responseType].value === 'LONG_TEXT' || RESPONSE_OPTIONS[this.responseType].value === 'SHORT_TEXT';
    },
    testContextPrompt() {
      return this.aiFeedbackContextPrompt?.trim() && this.currentResponse?.trim() && this.prompt?.trim();
    },
    questionnairePrompt() {
      return {
        // Only these properties can be edited within the questionnaire prompt
        prompt: this.prompt,
        responseType: RESPONSE_OPTIONS[this.responseType].value,
        reviewType: REVIEW_OPTIONS[this.reviewType].value,
        answers: {
          choice_amount: this.choiceAmount,
          choices: this.choices?.map(x => String(x)),
        },
        enableAiFeedback: this.enableAiFeedback,
        aiFeedbackContextPrompt: this.aiFeedbackContextPrompt,
      };
    },
    responseOptions() {
      return RESPONSE_OPTIONS.map((option, i) => ({
        name: option.name,
        id: i,
      }));
    },
    reviewOptions() {
      return REVIEW_OPTIONS.map((option, i) => ({
        name: option.name,
        id: i,
      }));
    },
  },

  methods: {
    openFeedbackModal() {
      this.showFeedbackModal = true;
      this.isFeedbackTest = true;
    },
    closeFeedbackModal() {
      this.showFeedbackModal = false;
      this.isFeedbackTest = false;
    },
    removePrompt() {
      this.$emit('remove');
    },
    moveUp() {
      this.$emit('move-up');
    },
    moveDown() {
      this.$emit('move-down');
    },
    getResponseTypeIndex(responseType) {
      if (RESPONSE_OPTIONS.map(opt => opt.value).includes(responseType)) {
        return RESPONSE_OPTIONS.map(opt => opt.value).indexOf(responseType);
      }
      return 0;
    },
    getReviewTypeIndex(reviewType) {
      if (REVIEW_OPTIONS.map(opt => opt.value).includes(reviewType)) {
        return REVIEW_OPTIONS.map(opt => opt.value).indexOf(reviewType);
      }
      return 0;
    },
  },
};
</script>

<template>
  <div class="release-date-container">
    <h4 v-if="releaseDate && isModule" class="release-date">
      <k-tooltip text="Content in the module becomes available to students at midnight (UTC) on this date">
        Release: <i :class="dateIsPast(releaseDate) ? 'green' : 'amber'">{{ formatDateString(releaseDate) }}</i>
      </k-tooltip>
    </h4>
    <h4 v-if="expectedCompletionDate && isModule" class="release-date">
      <k-tooltip text="Content in the module is expected to have been completed by midnight (UTC) on this date">
        Completion: <i :class="dateIsPast(expectedCompletionDate) ? 'amber' : 'green'">{{ formatDateString(expectedCompletionDate) }}</i>
      </k-tooltip>
    </h4>
    <h4 v-if="releaseAfterDays && !isModule" class="release-date">
      <k-tooltip :text="`Content in the module becomes available to students ${releaseAfterDays} days after the programme starts`">
        Release after <i class="green">{{ releaseAfterDays }} days</i>
      </k-tooltip>
    </h4>
    <h4 v-if="expectedCompletionDays && !isModule" class="release-date">
      <k-tooltip :text="`Content in the module is expected to take ${expectedCompletionDays} days to complete after the module is released`">
        Completion time <i class="amber">{{ expectedCompletionDays }} days</i>
      </k-tooltip>
    </h4>
  </div>
</template>
<style scoped>
.release-date-container {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
}

.release-date-container .release-date {
  margin: 0;
}

.release-date-container .release-date:nth-child(1n) {
  border-right: var(--border-primary);
  padding-right: 15px;
}

.release-date-container .release-date:last-child {
  border-right: 0;
}
</style>
<script>
import TimeMixins from '@mixins/time-mixins';
import KTooltip from '@base-components/k-tooltip.vue';
import useReleaseModuleStore from '@stores/release-module-store';

export default {
  components: {
    KTooltip,
  },
  mixins: [TimeMixins, KTooltip],
  props: {
    module: {
      type: Object,
      required: true,
    },
    isModule: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      releaseModuleStore: useReleaseModuleStore(),
    };
  },
  computed: {
    releaseAfterDays() {
      return this.releaseModuleStore.getModuleValue(this.module.index, 'release_after_days');
    },
    expectedCompletionDays() {
      return this.releaseModuleStore.getModuleValue(this.module.index, 'expected_completion_days');
    },
    releaseDate() {
      return this.releaseModuleStore.getModuleValue(this.module.index, 'release_date');
    },
    expectedCompletionDate() {
      return this.releaseModuleStore.getModuleValue(this.module.index, 'expected_completion_date');
    },
  },
};
</script>

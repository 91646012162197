<template>
  <k-modal class="update-video-modal" v-if="video"
    :show="show"
    :closeOnClickAway="false"
    @close="$emit('close')">
    <template #header>
      <h3><i class="fas fa-edit"></i>Edit Video - {{video.id}}</h3>
    </template>
    <template #body>
      <form class="video-form-container col-md-7 col-xs-12">
        <fieldset class="form-group form-entry col-md-8">
          <label for="update-vid-name-input">Name</label>
          <input id="update-vid-name-input" v-model="newVideoName" class="form-control">
        </fieldset>
        <fieldset class="form-group form-entry col-md-4">
          <k-date-picker
            inputId="update-date-recorded-input"
            label="Date Recorded"
            v-model="newVideoDateRecorded">
          </k-date-picker>
        </fieldset>
        <fieldset class="form-group form-entry col-md-12">
          <label for="update-description-input">Description</label>
          <textarea id="update-description-input" v-model="newVideoDescription"
            class="form-control"
            type="textarea"
            rows="3"
            placeholder="Briefly describe the video">
          </textarea>
        </fieldset>
        <fieldset class="form-group form-entry col-md-4">
          <word-tags v-model="newVideoTags"
            :options="moduleTemplates"
            dropdownPlaceholder="Modules"
            dropdownSearchPlaceholder="Search by module name or standardised code">
          </word-tags>
        </fieldset>
      </form>
      <div class="col-md-5 col-xs-12">
        <h3>Update Video</h3>
        <dropfield @file="filesChange"
          ref="videoUploadField"
          :customMessage="dropfieldMessage"
          :immediatePost="false"
          :disableDropfield="updateInProgress"
          accept="video/mp4,video/x-m4v,video/*"
          url=""
          id="k-update-video-dropfield">
        </dropfield>
      </div>
    </template>
    <template #footer>
      <button @click="updateVideo" class="btn btn-success send-btn">Update</button>
    </template>
  </k-modal>
</template>

<style>
.update-video-modal .modal-container {
  width: 80%;
}

.update-video-modal .modal-body {
  overflow: visible;
}

/* So the button doesn't grow to the same height as the dropdown container when open */
.update-video-modal .k-word-tags > form > button {
  height: 38px;
}

.update-video-modal .k-search-dropdown-adv-content {
  position: unset;
}

#k-update-video-dropfield {
  padding-top: 15px;
}
</style>

<style scoped>
textarea {
  max-width: 100%;
  resize: none;
}
</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import TimeMixin from '@mixins/time-mixins';
import KModal from '@base-components/k-modal.vue';
import KDatePicker from '@base-components/k-date-picker.vue';
import Dropfield from '@base-components/dropfield.vue';
import WordTags from '../../../components/word-tags.vue';
import getOrNull from '../../../../modules/get-or-null';

export default {
  components: {
    'k-modal': KModal,
    'k-date-picker': KDatePicker,
    'word-tags': WordTags,
    dropfield: Dropfield,
  },
  mixins: [ErrorMixin, TimeMixin],
  props: {
    video: {
      type: Object,
    },
    moduleTemplates: {
      type: Array,
    },
  },
  data() {
    return {
      newVideoName: undefined,
      newVideoDescription: undefined,
      newVideoDateRecorded: undefined,
      newVideoTags: [],
      updateInProgress: false,
      dropfieldMessage: `<i class="fas fa-cloud-upload-alt"></i>
      Click or drag video file here to update.`,
      file: undefined,
    };
  },
  watch: {
    video() {
      if (this.video) {
        this.newVideoName = this.video.name;
        this.newVideoDescription = this.video.description;
        this.newVideoDateRecorded = this.getDate(this.video.date_recorded);
        this.newVideoTags = this.video.tags || [];
      } else {
        this.newVideoName = undefined;
        this.newVideoDescription = undefined;
        this.newVideoDateRecorded = undefined;
        this.newVideoTags = [];
      }
    },
    updateInProgress() {
      if (this.updateInProgress) {
        this.$Loading.minimal();
      } else {
        this.$Loading.finish();
      }
    },
  },
  computed: {
    show() {
      return Boolean(this.video);
    },
    fileName() {
      if (this.file) {
        return this.file[0].name;
      }
      return this.video.resource_link.split('/').pop();
    },
  },
  methods: {
    updateVideoMeta() {
      const updatePayload = {
        file_name: this.fileName,
        name: this.newVideoName,
        description: this.newVideoDescription,
        date_recorded: this.formatDate(this.newVideoDateRecorded),
        tags: this.newVideoTags,
      };
      this.$logger.info('Updating video metadata', {
        videoId: this.video.id,
        updatePayload,
      }, true);
      return this.$http.put(`/api/curriculum/admin/video/${this.video.id}`, updatePayload).then(() => {
        this.$logger.info('Successfully updated video metadata', {
          videoId: this.video.id,
          name: this.newVideoName,
          description: this.newVideoDescription,
          dateRecorded: this.formatDate(this.newVideoDateRecorded),
          tags: this.newVideoTags,
        });
      }).catch(err => {
        this.$logger.error('Error updating video', { videoId: this.video.id, updatePayload }, err);
        throw err;
      });
    },

    uploadVideoToUrl(videoId, url) {
      this.$logger.info('Uploading video file', {
        videoId,
        fileName: this.file[0].name,
        fileSize: this.file[0].size,
        fileType: this.file[0].type,
      }, true);
      return this.$http.put(url, this.file[0]).then(() => {
        this.$logger.info('Video file uploaded successfully', {
          videoId,
          fileName: this.file[0].name,
          fileSize: this.file[0].size,
        });
      }).catch(err => {
        this.$logger.error('Error uploading video with signed resumable upload URL', undefined, err);
        throw err;
      });
    },
    getUploadUrl(videoId) {
      const payload = {
        file_name: this.file[0].name,
        file_size: this.file[0].size,
        content_type: this.file[0].type,
      };
      this.$logger.info('Getting signed upload URL for video', {
        videoId,
        fileName: this.file[0].name,
        fileSize: this.file[0].size,
        fileType: this.file[0].type,
      }, true);
      return this.$http.post(`/api/curriculum/admin/video/${videoId}/upload-url`, payload)
        .then(response => {
          this.$logger.info('Got signed resumable upload URL', { videoId });
          return response.data.upload_url;
        }).catch(err => {
          this.$logger.error('Error getting signed resumable upload URL', undefined, err);
          throw err;
        });
    },
    startProcessing(videoId) {
      this.$logger.info('Starting video processing', {
        videoId,
        fileName: this.file[0].name,
        fileSize: this.file[0].size,
        fileType: this.file[0].type,
      }, true);
      return this.$http.post(`/api/curriculum/admin/video/${videoId}/processing/start`).then(() => {
        this.$logger.info('Successfully started video processing', {
          videoId,
          fileName: this.file[0].name,
        });
      }).catch(err => {
        this.$logger.error('Error starting video processing', { videoId, file: this.file.name }, err);
        throw err;
      });
    },
    finaliseUpdate() {
      this.$ktoast.success('Video updated');
      this.clearFields();
      this.$emit('update');
      this.$emit('close');
    },
    async updateVideo() {
      this.updateInProgress = true;
      try {
        if (this.file) {
          this.$logger.info('Getting signed resumable upload URL');
          const url = await this.getUploadUrl(this.video.id);
          await this.uploadVideoToUrl(this.video.id, url);
          await this.updateVideoMeta();
          await this.startProcessing(this.video.id);
        } else {
          await this.updateVideoMeta();
        }
        this.finaliseUpdate();
      } catch (err) {
        if (getOrNull('response.status', err) === 400) {
          this.showError(err);
        } else {
          this.showError(err, true);
        }
      }
      this.updateInProgress = false;
    },

    filesChange(file) {
      this.$logger.info('Staged file for update', { file: file.name });
      this.file = file;
    },

    clearFields() {
      this.$refs.videoUploadField.reset();
      this.newVideoName = undefined;
      this.newVideoDescription = undefined;
      this.newVideoDateRecorded = undefined;
      this.newVideoTags = [];
    },
  },
};
</script>

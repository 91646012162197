<template>
  <div>
    <k-panel :hasContentToggle="false"
      title="Create Programme"
      icon="fa-project-diagram"
    >
      <template #body>
        <div class="programme-details-form">
          <h3>{{formattedProgName}}</h3>
          <div class="detail-form">
            <span>Blueprint <i class="form-required">*</i></span>
            <k-dropdown :options="sortedBlueprints" v-model="progBlueprintId"></k-dropdown>
          </div>
          <fieldset class="detail-form form-entry">
            <label for="programme-name-input">Name <span class="form-required">*</span>
            <input id="programme-name-input" class="form-control" placeholder="Type Programme name here..." v-model="progName">
            </label>
          </fieldset>
          <div class="detail-form">
            <span>Theme</span>
            <k-dropdown :options="themes" v-model="progThemeId" :clearOption="true"></k-dropdown>
          </div>
          <div class="detail-form sub-section">
            <div class="detail-form form-entry">
              <k-date-picker
                label="Start"
                :required="true"
                inputId="prog-start-date" v-model="progStartDate">
              </k-date-picker>
            </div>
            <div class="detail-form form-entry">
              <k-date-picker label="Planned End Date" inputId="prog-end-date" v-model="progEndDate"></k-date-picker>
            </div>
          </div>
          <fieldset class="detail-form description form-entry">
            <label for="text-editor">Description</label>
            <p class="info">This will appear in the programme banner on KATE as rendered HTML</p>
            <k-text-editor id="text-editor"
            v-model="progDescription"
            :customToolbar="[['bold', 'italic', 'underline'], ['link'], [{ list: 'ordered' }, { list: 'bullet' }]]"
            :placeholder="'Briefly describe the programme...'"
          ></k-text-editor>
          </fieldset>
          <fieldset class="detail-form form-entry">
            <label for="tag-input">Groups</label>
            <small class="info">
              <i class="fas fa-exclamation-triangle icon-warning"></i> You will not be able to change these once the programme is created
            </small>
            <k-word-tags label='' v-model="progGroups" :textInputPlaceholder="'Type groups here'"></k-word-tags>
          </fieldset>
          <k-form-input
            label="Chatbot Enabled"
            inputId="programme-chatbot-input"
            type="component">
            <template #components>
              <k-toggle v-model="progEnableChatbot"></k-toggle>
            </template>
          </k-form-input>
          <fieldset class="detail-form form-entry">
            <span>Product</span>
            <k-dropdown
              v-model="progProductId"
              :options="products"
              :clearOption="true"
            ></k-dropdown>
          </fieldset>
          <fieldset class="detail-form form-entry">
            <label class="k-custom-checkbox" for="include-report-checkbox">
              <input type="checkbox" class="hidden-checkbox" v-model="progIncludeInReport" id="include-report-checkbox">
              <span class="k-custom-checkbox-box"></span>
              <span class="k-custom-checkbox-text"> Include in reporting? If programme is included in reporting, learner data will be added to the Tableau dashboards</span>
            </label>
          </fieldset>
          <fieldset class="detail-form form-entry">
            <span>Badges</span>
            <achievement-list v-if="selectedBadges.length > 0"
              :achievements="selectedBadges"
            ></achievement-list>
            <p v-else>
              There are currently no badges in this programme - click the button below to add some
            </p>
            <button class="btn btn-primary" @click="showBadgeModal = true">Add Badge</button>
            <!-- NB: Modal needs to be outside label for opening / closing to work correctly -->
            <select-badge-modal :show="showBadgeModal"
              @close="showBadgeModal = false"
              @choose="addBadge"
            ></select-badge-modal>
          </fieldset>
          <fieldset class="detail-form form-entry">
            <span>Certificates</span>
            <achievement-list v-if="certificateToBeAwarded"
                              :achievements="certificateToBeAwarded"
                              :achievement-type="'certificate'"
            ></achievement-list>
            <p v-else>
              There is currently no certificate to be awarded on completion of this programme - click the button below to select one
            </p>
            <button class="btn btn-primary" @click="showCertificateModal = true">{{ certificateBtnText }}</button>
            <!-- NB: Modal needs to be outside label for opening / closing to work correctly -->
            <select-certificate-modal
                :show="showCertificateModal"
                @close="showCertificateModal = false"
                @choose="selectCertificate"
            ></select-certificate-modal>
          </fieldset>
          <div class="create-programme-button">
            <button class="btn btn-success" @click="createProgramme"
                    :disabled="!validDetails || creationInProgress">
              <span v-if="creationInProgress"><i class="fa fa-spinner fa-spin"></i> Creating Programme</span>
              <span v-else>Create Programme</span>
            </button>
          </div>
        </div>
      </template>
    </k-panel>
    <k-panel v-if="progBlueprintId"
      :hasContentToggle="false"
      title="Modules in Blueprint"
      icon="fa-compass-drafting"
    >
      <template #body>
        <button class="btn btn-warning" @click="resetProgrammeBlueprintModules">
          Reset to default
        </button>
        <div v-if="modulesLoading">
          <i class="fas fa-spinner fa-spin"></i>
        </div>
        <div v-else class="module-blueprints">
<!--          FEATURE-BRANCH-TODO - If we add the add functionality back in, moduleType won't always be programme_blueprint_module-->
          <draggable v-model="programmeBlueprintModules" draggable=".k-module-item" ghost-class="ghost">
            <k-release-module
              v-for="mod, index in programmeBlueprintModules"
              class="k-module-item"
              :module="mod"
              :id="mod.id"
              :number="index + 1"
              :source-module-type='"programme_blueprint_module"'
              :target-module-type='"module"'
              :allow-remove="true"
              :track="mod.track"
              :show-badges="true"
              :show-content-link="false"
              @update-track="track => setTrack(index, track)"
              @remove="removeModuleBlueprint(index)"
              :key="`details-${mod.id}`">
            </k-release-module>
          </draggable>
        </div>
      </template>
    </k-panel>
  </div>
</template>

<style scoped>
.add-module-filter-form {
  margin-top: 1em;
  margin-bottom: 1em;
  text-align: right;
}

.add-module-filter-results ul {
  list-style: none;
  padding-left: 0;
}

h3:empty {
  display: none;
}

.programme-details-form {
  grid-gap: 1em;
  display: flex;
  flex-wrap: wrap;
}

.programme-details-form > h3 {
  width: 100%;
  height: 1.3em;
  margin-top: 5px;
}

.programme-details-form .sub-section {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
}

.programme-details-form .sub-section .detail-form {
  min-width: 30%;
  max-width: 250px;
  flex: 1;
  margin-bottom: 10px;
}

.detail-form {
  min-width: 15em;
  flex: 1 1 40%;
}

.detail-form .k-date-picker {
  margin-right: 10px;
}

.detail-form #text-editor {
  width: 100%;
  margin-bottom: 1rem;
  padding: 8px;
  resize: none;
}

.detail-form label {
  display: block;
}

.detail-form label.k-custom-checkbox {
  display: flex;
}

.k-custom-checkbox {
  margin: 15px 0;
}

.create-programme-button {
  text-align: right;
  flex: 1 1 100%;
}

.module-blueprints {
  margin-top: 1em;
}
</style>

<style>
.ghost .create-programme-module-card {
  border: 4px solid var(--kate-primary);
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}
</style>

<script>
import { VueDraggableNext } from 'vue-draggable-next';
import ErrorMixin from '@mixins/error-mixins';
import TimeMixin from '@mixins/time-mixins';
import PageReadyMixin from '@mixins/page-ready-mixin';
import KDropdown from '@base-components/k-dropdown.vue';
import KDatePicker from '@base-components/k-date-picker.vue';
import KPanel from '@base-components/k-panel.vue';
import KTextEditor from '@base-components/k-text-editor.vue';
import KFormInput from '@base-components/forms/k-form-input.vue';
import KToggle from '@base-components/forms/k-toggle.vue';
import KReleaseModule from './modules/release-module.vue';
import KWordTags from '../components/word-tags.vue';
import SelectBadgeModal from '../achievements/select-badge-modal.vue';
import SelectCertificateModal from '../achievements/select-certificate-modal.vue';
import AchievementList from '../achievements/achievement-list.vue';
import { sortObjectArray } from '../../modules/sort-by-object-property';

export default {
  components: {
    'k-dropdown': KDropdown,
    'k-date-picker': KDatePicker,
    'k-release-module': KReleaseModule,
    'k-word-tags': KWordTags,
    'k-panel': KPanel,
    'k-text-editor': KTextEditor,
    draggable: VueDraggableNext,
    SelectBadgeModal,
    SelectCertificateModal,
    AchievementList,
    KToggle,
    KFormInput,
  },

  mixins: [ErrorMixin, PageReadyMixin, TimeMixin],

  props: {
    products: {
      type: Array,
    },
  },

  data() {
    return {
      // form data
      progBlueprintId: undefined,
      progName: '',
      progType: undefined,
      progThemeId: undefined,
      progDescription: '',
      progProductId: undefined,
      progIncludeInReport: false,
      progStartDate: undefined,
      progEndDate: undefined,
      progEnableKloud: undefined,
      progEnableChatbot: true,
      progEnableKb: undefined,
      progGroups: [],
      blueprints: [],
      themes: [],
      originalProgrammeBlueprintModules: [],
      programmeBlueprintModules: [],
      // modals
      showAddModuleBlueprint: false,
      showBadgeModal: false,
      selectedBadges: [],
      // ready flags
      creationInProgress: false,
      modulesLoading: false,
      blueprintsReady: false,
      themesReady: false,
      maxItemsPerPage: 10,
      currentPage: 0,
      showCertificateModal: false,
      certificateToBeAwarded: undefined,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getProgrammeBlueprints();
    this.getThemes();
  },

  watch: {
    progBlueprintId() {
      const progBlueprint = this.blueprints.find(x => x.id === this.progBlueprintId);
      if (progBlueprint) {
        this.programmeBlueprintModules = sortObjectArray(progBlueprint.programme_blueprint_modules, 'number', false).map(mod => ({
          badges_in_module: progBlueprint.badges.filter(
            b => b.programme_blueprint_modules?.map(pbm => pbm.id)?.includes(mod.id),
          ) || [],
          ...mod,
        }));
        this.originalProgrammeBlueprintModules = JSON.parse(JSON.stringify(this.programmeBlueprintModules));
        this.progDescription = progBlueprint?.description || '';
        this.progProductId = progBlueprint.product_id;
        this.progIncludeInReport = progBlueprint.include_in_reporting;
        this.progName = progBlueprint.name;
        this.progEnableKloud = progBlueprint.enable_kloud;
        this.progEnableChatbot = progBlueprint.chatbot_enabled;
        this.progEnableKb = progBlueprint.enable_kb;
        this.selectedBadges = [...progBlueprint.badges];
        this.certificateToBeAwarded = progBlueprint.certificate ? [progBlueprint.certificate] : undefined;
      } else {
        // Resetting form - progBlueprintId will be undefined so no blueprint is selected
        this.programmeBlueprintModules = [];
        this.originalProgrammeBlueprintModules = [];
        this.progDescription = '';
        this.progProductId = undefined;
        this.progIncludeInReport = false;
        this.progName = '';
        this.progEnableKloud = false;
        this.progEnableKb = false;
        this.progEnableChatbot = true;
        this.selectedBadges = [];
        this.certificateToBeAwarded = undefined;
      }
    },
  },

  computed: {
    ready() {
      return this.blueprintsReady && this.themesReady;
    },
    sortedBlueprints() {
      return sortObjectArray(this.blueprints.slice(), 'name', false);
    },
    formattedProgName() {
      return this.progName.trim();
    },
    validDetails() {
      return Boolean(this.progBlueprintId && this.formattedProgName && this.progStartDate);
    },
    progTheme() {
      const comp = this.themes.find(x => x.id === this.progThemeId);
      return comp ? comp.name : comp;
    },
    certificateBtnText() {
      return this.certificateToBeAwarded ? 'Change Certificate' : 'Add Certificate';
    },
  },

  methods: {
    getCreatePayload() {
      return {
        name: this.formattedProgName,
        programme_blueprint_id: this.progBlueprintId,
        start_date: this.progStartDate ? this.formatDate(this.progStartDate) : undefined,
        planned_end_date: this.progEndDate ? this.formatDate(this.progEndDate) : undefined,
        theme_id: this.progThemeId,
        description: this.progDescription || null,
        product_id: this.progProductId || null,
        include_in_reporting: this.progIncludeInReport,
        programme_blueprint_modules: this.programmeBlueprintModules.map(x => ({ id: x.id, track: x.track })),
        enable_kloud: this.progEnableKloud,
        enable_kb: this.progEnableKb,
        chatbot_enabled: this.progEnableChatbot,
        groups: this.progGroups,
        badge_ids: this.selectedBadges.map(x => x.id),
        certificate_id: this.certificateToBeAwarded ? this.certificateToBeAwarded[0].id : null,
      };
    },
    resetForm() {
      this.progBlueprintId = undefined;
      this.progThemeId = undefined;
      this.progStartDate = undefined;
      this.progEndDate = undefined;
      this.progGroups = [];
    },
    resetProgrammeBlueprintModules() {
      this.programmeBlueprintModules = JSON.parse(JSON.stringify(this.originalProgrammeBlueprintModules));
    },
    removeModuleBlueprint(index) {
      this.programmeBlueprintModules.splice(index, 1);
    },
    setTrack(index, track) {
      this.programmeBlueprintModules[index].track = track;
    },
    createProgramme() {
      this.$logger.info('Creating new programme', undefined, true);
      this.creationInProgress = true;
      const payload = this.getCreatePayload();
      this.$http.post('/api/curriculum/programmes', payload).then(res => {
        this.$ktoast.success(`Created programme: ${res.data.programme_id}`);
        this.$logger.info('Successfully created new programme', { programmeId: res.data.programme_id }, true);
        this.$emit('programme-created');
        this.resetForm();
      }).catch(err => {
        this.$logger.error('Error creating new programme', undefined, err);
        this.showError(err);
      }).then(() => {
        this.creationInProgress = false;
      });
    },
    getProgrammeBlueprints() {
      this.$logger.info('Getting programme blueprints');
      this.blueprintsReady = false;
      return this.$http.get('/api/curriculum/blueprints/programmes').then(res => {
        this.blueprints = res.data.blueprints;
        this.$logger.info('Successfully retrieved programme blueprints');
      }).catch(err => {
        this.showError(err);
        this.$logger.error('Error retrieving programme blueprints');
      }).then(() => {
        this.blueprintsReady = true;
      });
    },
    getThemes() {
      this.$logger.info('Getting themes');
      this.themesReady = false;
      this.$http.get('/api/curriculum/themes').then(res => {
        this.themes = res.data;
        this.$logger.info('Successfully retrieved themes');
      }).catch(err => {
        this.showError(err);
        this.$logger.error('Error retrieving themes');
      }).then(() => {
        this.themesReady = true;
      });
    },
    addBadge(badge) {
      if (!this.selectedBadges.find(b => b.id === badge.id)) {
        this.selectedBadges.push(badge);
      }
    },
    selectCertificate(certificate) {
      this.certificateToBeAwarded = [certificate];
    },
  },
};
</script>

<template>
  <div>
    <h1>Questionnaire Overview</h1>
    <asset-overview v-if="questionnairesReady"
      asset-icon="fa-duotone fa-ballot-check"
      :assets="questionnaires"
      :kable-headers="kableHeaders"
      :kable-rows="kableRows"
      :show-archived-assets="showArchived"
      @tablecallback="tableCallback"
      @archive-toggle="updateArchiveToggle"
    >
      <template #new-asset>
        <questionnaire-builder @update="getQuestionnaires"></questionnaire-builder>
      </template>
    </asset-overview>
  </div>
</template>

<script>
import ErrorMixin from '@mixins/error-mixins';
import getIcon from '../../../../modules/kate-table-icons';
import AssetOverview from '../asset-overview.vue';
import QuestionnaireBuilder from './questionnaire_creation/questionnaire-builder.vue';
import ArchiveToastConfirmMixin from '../archive-toast-confirm-mixin';

export default {
  components: {
    'questionnaire-builder': QuestionnaireBuilder,
    AssetOverview,
  },

  mixins: [ErrorMixin, ArchiveToastConfirmMixin],
  data() {
    return {
      questionnairesReady: false,
      questionnaires: [],
      showArchived: false,
    };
  },

  beforeMount() {
    this.$Loading.start();
    this.getQuestionnaires();
  },

  watch: {
    questionnairesReady() {
      if (this.questionnairesReady) {
        this.$Loading.finish();
      } else {
        this.$Loading.start();
      }
    },
  },

  computed: {
    kableHeaders() {
      return {
        edit: {
          name: 'Edit',
          type: 'url',
        },
        name: {
          name: 'Title',
          filterable: true,
          type: 'url',
        },
        description: {
          name: 'Description',
          filterable: true,
        },
        tags: {
          name: 'Tags',
          type: 'tags',
          filterable: true,
        },
        archive: {
          name: 'Archive',
          type: 'action',
          sortable: true,
        },
      };
    },

    kableRows() {
      return this.questionnaires.map((questionnaire, index) => ({
        edit: {
          key: index,
          text: getIcon({ icon: 'edit' }),
          path: {
            name: 'edit_questionnaire',
            params: {
              questionnaireId: questionnaire.id,
            },
          },
        },
        name: {
          key: index,
          text: questionnaire.name,
          path: {
            name: 'curriculum_asset_details',
            params: {
              assetId: questionnaire.id,
              assetType: 'questionnaire',
            },
          },
        },
        description: questionnaire.description,
        tags: {
          tags: questionnaire.tags || [],
          sortBy: questionnaire.tags ? [...questionnaire.tags].sort().join(' ') : '',
        },
        is_active: questionnaire.is_active,
        id: questionnaire.id,
        archive: {
          text: this.assetArchiveButton(questionnaire.is_active),
        },
      })).filter(ce => (this.showArchived || ce.is_active));
    },
  },

  methods: {

    updateArchiveToggle(val) {
      this.showArchived = val;
    },

    getQuestionnaires() {
      this.$logger.info('Getting questionnaires');
      this.questionnairesReady = false;
      this.$http.get('/api/curriculum/admin/questionnaire').then(result => {
        this.$logger.info('Got questionnaires');
        this.questionnaires = result.data.questionnaires;
      }).catch(err => {
        if (err.response.status !== 404) {
          this.$logger.error('Error getting questionnaires', undefined, err);
          this.showError(err);
        } else {
          this.$logger.warn('No questionnaires found');
        }
      }).then(() => {
        this.questionnairesReady = true;
      });
    },

    tableCallback(key, index, row, header) {
      if (header === 'archive') {
        this.tableArchiveCallback(row);
      }
    },

    archiveCallback(quId) {
      this.$logger.info('Archiving questionnaire', { quId }, true);
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/questionnaire/${quId}/archive`)
        .then(() => {
          this.$logger.info('Questionnaire has been archived', { quId });
          this.$ktoast.success('Questionnaire has been archived');
          this.getQuestionnaires();
        }).catch(err => {
          this.$logger.error('Error archiving questionnaire', { quId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },

    unarchiveCallback(quId) {
      this.$logger.info('Unarchiving questionnaire', { quId }, true);
      this.$Loading.start();
      this.$http.put(`/api/curriculum/admin/questionnaire/${quId}/unarchive`)
        .then(() => {
          this.$logger.info('questionnaire has been unarchived', { quId });
          this.$ktoast.success('Questionnaire has been unarchived');
          this.getQuestionnaires();
        }).catch(err => {
          this.$logger.error('Error unarchiving questionnaire', { quId }, err);
          this.showError(err);
        }).then(() => {
          this.$Loading.finish();
        });
    },
  },
};
</script>

<template>
  <div v-if="programmeModule" class="panel module-card-container" id="dashboard-module" :class="programmeModule.released ? '' : 'disabled' ">
    <div @click="show = !show" class="panel-body clickable">
      <div class="module-name">
        <h3>{{ programmeModule.name }}</h3>
        <span v-if="programmeModule.released"><b>Released:</b> {{ parseTimestamp(programmeModule.release_date, undefined, 'MMM d, yyyy') }}</span>
        <span :class="programmeModule.release_date ? '' : 'amber'" v-else>
          Module not released. <b v-if="programmeModule.release_date" class="amber">Release due: {{ parseTimestamp(programmeModule.release_date, undefined, 'MMM d, yyyy') }}</b>
        </span>
      </div>
      <div class="module-progress">
        <k-progress
          shape="bar"
          :percentage="moduleCompletion"
          :precision="1"
          :show-bar-text="true"
          size="small"
          customText="Completed"
          ariaLabel="Overall Module Completion"
          progressTheme="darker"
        ></k-progress>
        <span class="comp-date" v-if="programmeModule.expected_completion_date">
          <b>Completion by: </b>
          <b :class="dateIsPast(programmeModule.expected_completion_date) ? 'amber' : 'green'">{{ parseTimestamp(programmeModule.expected_completion_date, undefined, 'MMM d, yyyy') }}</b>
        </span>
      </div>
      <div v-if="assets.length > 0" class="module-toggle">
        <i class="fas" :class="toggleClass"></i>
      </div>
    </div>
    <div class="module-assets">
      <k-asset-counts v-if="assets.length > 0"
        :assets="assets"
      ></k-asset-counts>
      <span v-else>No assets found for this module</span>
    </div>
    <div v-if="show && chaptersReady">
      <dashboard-chapter-panel v-for="chapter in sortedChapters" :key="chapter.name"
        :chapter="chapter"
        :user-id="userIdFromRoute"
        :programme-member-id="programmeMemberId"
        :is-learner-support="isLearnerSupport"
        @update-chapters="getChapters"
      ></dashboard-chapter-panel>
    </div>
  </div>
</template>

<style>
#dashboard-module ul.k-asset-count {
  padding: 0;
  display: flex;
  gap: 5px;
  margin: 0;
  flex-wrap: wrap;
}

#dashboard-module ul.k-asset-count.border li {
  border-right: 1px solid var(--kate-type-primary);
  margin: 0;
  padding: 0;
  padding-right: 5px;
  font-size: 0.85em;
}

#dashboard-module ul.k-asset-count.border li:last-child {
  border-right: 0;
}

#dashboard-module ul.k-asset-count.border li span {
  color: var(--kate-type-primary);
}
</style>

<style scoped>
.module-card-container {
  box-shadow: var(--box-shadow-alt);
}

.panel-body {
  display: flex;
  gap: 15px;
  align-items: center;
  position: relative;
  justify-content: space-between;
}

.panel-body::before {
  display: none;
}

.module-name {
  flex: 1;
}

.module-progress {
  min-width: 250px;
  font-size: 0.85em;
}

.module-toggle {
  font-size: 1.2em;
}

.module-assets {
  padding: 0 15px 15px;
}

img {
  height: auto;
  max-width: 45px;
}

@media (max-width: 838px) {
  .panel-body {
    padding-right: 45px;
    flex-wrap: wrap;
  }

  .module-assets {
    flex: unset;
    width: 100%;
  }

  .module-toggle {
    top: 50%;
    right: 15px;
    bottom: 0;
    display: flex;
    position: absolute;
    align-items: center;
    transform: translateY(-50%);
  }

  .module-progress {
    min-width: 150px;
    width: 100%;
    font-size: 0.85em;
  }
}

/* Disabled modules */

.disabled .module-assets,
.disabled .module-progress,
.disabled .module-name h3,
.disabled .amber {
  opacity: 0.5;
}

</style>

<script>
import ErrorMixin from '@mixins/error-mixins';
import TimeMixins from '@mixins/time-mixins';
import ChaptersMixin from '@mixins/chapters-mixin';
import KProgress from '@base-components/k-progress.vue';
import KAssetCounts from '@base-components/k-asset-counts.vue';
import DashboardChapterPanel from './dashboard-chapter-panel.vue';
import { average } from '../../../modules/module-completion-calculator';

export default {
  mixins: [ErrorMixin, ChaptersMixin, TimeMixins],

  components: {
    KAssetCounts,
    KProgress,
    DashboardChapterPanel,
  },

  props: {
    programmeModule: Object,
    programmeMemberId: Number,
    isLearnerSupport: Boolean,
  },

  data() {
    return {
      show: false,
      chapters: [],
      chaptersReady: false,
    };
  },

  beforeMount() {
    this.getChapters();
  },

  mounted() {
    this.$Loading.minimal();
  },

  watch: {
    chaptersReady() {
      if (this.chaptersReady) {
        this.$Loading.finish();
      } else {
        this.$Loading.minimal();
      }
    },
  },

  computed: {
    userIdFromRoute() {
      return Number(this.$route.params.studentId);
    },

    moduleCompletion() {
      return Math.floor(average(this.chapterCompletionScores));
    },

    toggleClass() {
      return this.show ? 'fa-angle-up' : 'fa-angle-down';
    },
  },

  methods: {
    getChapters() {
      this.$logger.info('Getting chapters');
      this.chaptersReady = false;
      this.$http.get(`/api/curriculum/admin/user/${this.userIdFromRoute}/modules/${this.programmeModule.id}/chapters`).then(res => {
        this.$logger.info('Got chapters');
        this.chapters = res.data.chapters;
      }).catch(err => {
        this.$logger.warn('Error getting chapters', { id: this.programmeModule.id }, err);
      }).then(() => {
        this.chaptersReady = true;
      });
    },
  },
};
</script>
